import {CLIENT_DELETE_ACTION} from "../../../../redux/clientsDucks"
import { useDispatch, useSelector } from "react-redux";

const DeleteModalClient = (props) => {
    const dispatch = useDispatch()
    return (
    <>
        <div className="modal fade " id={"client-" + props.client.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Eliminación del cliente {props.client.name}.</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    ¿Desea eliminar el cliente {props.client.name} de forma permanente? <br />
                    <span className="mt-2 px-5 d-block" style={{fontSize:".9rem",opacity:"0.9"}}>Si ud. borra el cliente se borraran todos sus datos. Si no quiere que se pierdan, paselo a estado No activo.</span>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" onClick={() => {dispatch(CLIENT_DELETE_ACTION(props.client.id))}} className="btn btn-danger" data-bs-dismiss="modal">Eliminar</button>
                </div>
                </div>
            </div>
        </div>
    </>)
}

export default DeleteModalClient