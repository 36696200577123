import axios from 'axios'

// Constantes
const dataInicial = {
    menu:false,
}

// Types
const SET_MENU = 'SET_MENU'

// Reducer
export default function analyticsReducer(state = dataInicial, action){
    switch(action.type){
        case SET_MENU:{ //Get actives
            return {
                ...state, menu : action.payload,
            }
        }
        default:
            return state;
    }
}
// Actions
export const SET_MENU_ACTION = (bool) => async (dispatch, getState) => {
    dispatch({type: SET_MENU,payload: bool})
}