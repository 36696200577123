import { base_url } from "./base_url";

const content = base_url ;

export const routes = {
  content: content,
  base: base_url,
  session: {
    login: base_url + "/login",
    recover: base_url + "/recover",
    recoverSend: base_url + "/recover/recover-send/:email",
    recoverChange: base_url + "/recover/recover-change",
    investors: base_url + "/:client",
  },
  myProfile: {
    myData: content + "/mi-perfil/mis-datos",
  },
  welcome:{
    welcome: content + "/bienvenido",
  },
  billing: {
    billingData: content + "/facturacion/datos-de-facturacion",
    accountState: content + "/facturacion/estado-de-cuenta",
  },
  mediaHost: {
    support: content + "/media-host/soporte",
    createTicket: content + "/media-host/soporte/crear-ticket",
    about: content + "/media-host/acerca",
  },
  mediaData: {
    analytics: content + "/media-tracker/analytics",
  },
  mediaAcademy: {
    news: content + "/media-academy/novedades",
    new: content + "/media-academy/novedades/titulo-novedad",
    about: content + "/media-academy/acerca",
  },
  mediaBlog: {
    news: content + "/media-blog/novedades",
    createNew: content + "/media-blog/novedades/crear-novedad",
    updateNew: content + "/media-blog/novedades/actualizar-novedad/:id",
    filters: content + "/media-blog/filtros",
    createFilter: content + "/media-blog/filtros/crear-filtro",
    updateFilter: content + "/media-blog/filtros/actualizar-filtro/:id",
    editors: content + "/media-blog/editores",
    createEditor: content + "/media-blog/editores/crear-editor",
    updateEditor: content + "/media-blog/editores/actualizar-editor/:id",
  },
  mediaTeam: {
    news: content + "/media-team/novedades",
    createNew: content + "/media-team/novedades/crear-novedad",
    updateNew: content + "/media-team/novedades/actualizar-novedad/:id",
    filters: content + "/media-team/filtros",
    createFilter: content + "/media-team/filtros/crear-filtro",
    updateFilter: content + "/media-team/filtros/actualizar-filtro/:id",
    editors: content + "/media-team/asesores",
    createEditor: content + "/media-team/asesores/crear-asesor",
    updateEditor: content + "/media-team/asesores/actualizar-asesor/:id",
  },
  mediaBroker: {
    events: content + "/media-broker/eventos",
    createEvent: content + "/media-broker/eventos/crear-evento",
    rooms: content + "/media-broker/salas",
    createRoom: content + "/media-broker/salas/crear-sala",
    users: content + "/media-broker/usuarios",
    createUser: content + "/media-broker/usuarios/crear-usuario",
    about: content + "/media-broker/acerca",
    
    // new mediabroker
    event: content + "/media-broker/evento",
    createNewEvent: content + "/media-broker/eventos/nuevo-evento",
    room: content + "/media-broker/sala",
    createNewRoom: content + "/media-broker/salas/nueva-sala",
    user: content + "/media-broker/usuario",
    createNewUser: content + "/media-broker/usuario/crear-usuario",
    aboutBroker: content + "/media-broker/acerca-nuestro",
  },
  mediaInvestor: {
    config: content + "/media-investor/configuracion",
    entrepreneurships: content + "/media-investor/emprendimientos",
    createEntrepreneurship: content + "/media-investor/emprendimientos/crear-emprendimiento",
    updateEntrepreneurship: content + "/media-investor/emprendimientos/actualizar-emprendimiento/:id",
    investors: content + "/media-investor/inversores",
    createInvestor: content + "/media-investor/inversores/nuevo-inversor",
    updateInvestor: content + "/media-investor/inversores/actualizar-inversor/:id",
    news: content + "/media-investor/novedades",
    createNew: content + "/media-investor/novedades/crear-novedad",
    updateNew: content + "/media-investor/novedades/actualizar-novedad/:id",
    about: content + "/media-investor/acerca",
    investor: content + "/:cliente/inversor/mis-inversiones", // MiShulbius
    investorView: content + "/:cliente/inversor/novedad/:id", // Ficha de novedades
    investorOportunity: content + "/:cliente/inversor/prelanzamientos", // Oportunidades
    InvestorNovelties: content + "/:cliente/inversor/novedades", // Shulbius News
    InvestorMyNovelties: content + "/:cliente/inversor/mis-novedades", // Shulbius News
  },
  mediaDocs: {
    entrepreneurships: content + "/media-hub/etiquetas",
    createEntrepreneurship: content + "/media-hub/etiquetas/crear-etiqueta",
    updateEntrepreneurship: content + "/media-hub/etiquetas/actualizar-etiqueta/:id",
    members: content + "/media-hub/usuarios",
    createMembers: content + "/media-hub/usuarios/nuevo-usuario",
    updateMembers: content + "/media-hub/usuarios/actualizar-usuario/:id",
    news: content + "/media-hub/recursos",
    createNew: content + "/media-hub/recursos/crear-comunicacion",
    updateNew: content + "/media-hub/recursos/actualizar-comunicacion/:id",
    memberDoc: content + "/:cliente/media-hub/mis-recursos", 
    viewDoc: content + "/:cliente/media-hub/comunicacion/:id", // Ficha de comunicacion
    generalDoc: content + "/:cliente/media-hub/recursos", // Shulbius News
    developmentDoc: content + "/:cliente/media-hub/emprendimientos", // Oportunidades
    // InvestorMyNovelties: content + "/:cliente/inversor/mis-novedades", // Shulbius News
  },
  mediaSmart: {
    config: content + "/media-smart/configuracion",
    clients: content + "/media-smart/clientes",
    createClient: content + "/media-smart/clientes/crear-cliente",
    updateClient: content + "/media-smart/clientes/actualizar-cliente/:id",
    users: content + "/media-smart/usuarios",
    createUser: content + "/media-smart/usuarios/crear-usuario",
    updateUser: content + "/media-smart/usuarios/actualizar-usuario/:id",
  },
};
