import React, {useState} from "react";
import Breadcrumb from "../../Breadcrumb";
import CardNew from "./CardNew";
import { useHistory } from "react-router";

import {POST_GET_ACTION,POSTS_GET_ACTION} from "../../../../redux/blogsDucks";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import { useEffect } from "react";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { useLocation } from "react-router";

import { Link } from "react-router-dom";

export default function SeeNew() {

  const dispatch = useDispatch(); 
  const { id } = useParams();
  const history = useHistory()

  console.log(history)
  useEffect(() => {
    window.scrollTo( 0, 0 );
    dispatch(POST_GET_ACTION(id));
  },[id])
  useEffect(() => {
    dispatch(POSTS_GET_ACTION());
  },[])

  const {post} = useSelector(state => state.posts);
  const {posts} = useSelector(state => state.posts);
  const posts_private = useSelector(state => state.posts.posts_private);
  const {user_logged} = useSelector(store => store.users)
  const location = useLocation()

    


  const getCurrentView = () => {
    // if(post?.categories?.find(element => element.name === 'do')){
    //   return 'Recursos'
    // }
    console.log(post?.categories)
    if(post?.categories?.find(element => element.name === 'docs')){
      if(post?.groups_count > 0){
        return 'Mis recursos';
      }
      if(post?.tags?.length > 0){
        return 'Emprendimientos'
      }
      else{
        return 'Recursos'
      }
    }
    return 'Section'
  }

  const breadcrumb_items = [
    {
      text: "Usuario",
      route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/media-hub/recursos",
    },
    {
      text: getCurrentView(),
      route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/media-hub/" + getCurrentView().toLowerCase().replaceAll(" ","-"),
    },
    {
      text: "Recurso",
      route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/media-hub/recursos",
    },
  ];

  const getTime_text = (time) => {
    if(time){
      const spliter = time.split('T');
      const fecha = spliter[0].split('-');
      return `${fecha[2]}/${fecha[1]}/${fecha[0]}`
    }
  }
  const getPublish_text = (time) => {
    if(time){
      const spliter = time.split(' ');
      const fecha = spliter[0].split('-');
      switch(fecha[1]){
        case '01':
          return `${fecha[2]} de Enero ${fecha[0]}`
        case '02':
          return `${fecha[2]} de Febrero ${fecha[0]}`
        case '03':
          return `${fecha[2]} de Marzo ${fecha[0]}`
        case '04':
          return `${fecha[2]} de Abril ${fecha[0]}`
        case '05':
          return `${fecha[2]} de Mayo ${fecha[0]}`
        case '06':
          return `${fecha[2]} de Junio ${fecha[0]}`
        case '07':
          return `${fecha[2]} de Julio ${fecha[0]}`
        case '08':
          return `${fecha[2]} de Agosto ${fecha[0]}`
        case '09':
          return `${fecha[2]} de Septiembre ${fecha[0]}`
        case '10':
          return `${fecha[2]} de Octubre ${fecha[0]}`
        case '11':
          return `${fecha[2]} de Noviembre ${fecha[0]}`
        case '12':
          return `${fecha[2]} de Diciembre ${fecha[0]}`
      }
    }
  }


  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    getCurrentView()
    if(post){
      if(post.content){
        const blocksFromHtml = htmlToDraft(post.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
  },[post])



  const getOtherPosts = () => {
    let response = []

    // Caso Privado
    if(posts_private.length > 0){
        posts_private?.map((item,index) => {
          if(item?.id !== post?.id && post?.groups_count > 0){
            if(item.active === true || item.active.toString() === 'true'){
            response.push(item)
            }
          }
        }   
      )
      return response;
    }

    // Caso Publico
    if(post?.categories?.find(element => element.name === 'blog')){
      posts?.map((item,index) => {
        if(item?.id !== post?.id){
          if(item?.categories?.find(element => element.name === 'blog')){
            if((item.active === true || item.active.toString() === 'true') && (item.featured == 1 || item.featured.toString() === '1')){
              response.push(item)
            }
          }      
        }
      })
      return response;
    }

    // Casos de tags
    posts?.map((item,index) => {
      if(post?.categories?.length > 0){
        if(item?.categories?.find(element => element.id === post?.categories[0]?.id)){
          // console.log(item?.categories?.find(element => element.name === 'blog'))
          if(item?.id !== post?.id && (item?.active.toString() === 'true' || item?.active === true) ){ //&& !response.find(element => element.id === item?.id)
            //Caso de Prelanzamiento
            if(item?.tags?.length === 0 && post.tags.length === 0){
              response.push(item)
            }
            //Caso de Mis inversiones
            if(post?.tags.length > 0) {
              post?.tags.map((tag,index) => {
                if(item?.tags?.find(element => element.id === tag?.id)){
                  response.push(item)
                }
              })
            }
          }
        }
      }
    })
    
    return response
  }

  return post ?  (
    <div className="content">
      <div>
        <Breadcrumb items={breadcrumb_items} />
      </div>
      <h1 id="fake-title" className="mt-lg-5 mt-3">
        {/* Recurso  */}
      </h1>
      <div className="ma-see-news mt-3 w-100 d-block">
        <div className="position-relative first-ma-see-container docs">
          <div className="ma-see-container-left ">
            <div className="post-heading row">
              <div class="col-md-9 text-left">
                <h1 class="post-title">{post?.title}</h1>
                <h2 class="post-excerpt">{(post?.excerpt)}</h2>
              </div>
              <div class="col-md-3 text-right">

                <p class="post-date">Última actualización <br />{getPublish_text(post?.publish_date)}</p>
              </div>
            </div>
            <div className="d-none post-image position-relative">
              <img
                className="new-image-banner"
                src={process.env.REACT_APP_BASE_URL + post?.image}
                alt={`banner`}
              />
            </div>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              disabled={true}
            />
            <div className={"download-section d-flex px-lg-4" + (post?.files?.length > 0 ? " mb-4" : '') } >
              <div className="bt"></div>
              <div className="d-flex mt-4">
                {post?.files?.map((element,index) => (
                  <a target="_blank" style={{textDecoration:"none",cursor:"pointer"}} href={element.uri} className=" me-3 mb-2 download-docs">
                    <span>{element.path.split(".")[element.path.split(".")?.length - 1]}</span>
                    {console.log}
                    <div className="title">{element.path.split("_")[1].split(".")[0]} </div>
                  </a>
                ))}
              </div>
            </div>
            {post?.tags?.map((item,index) => (
              <div className="span-tag m-3">{item.name}</div>
            )
            )}
          </div>

        </div>

        <div className="div-novedades d-none flex-column">
          <div className="sidebar-heading">
              <h2 className="title-otras-novedades text-uppercase mt-3">{post ? post?.categories?.find(element => element.name.toString().toLowerCase() === "blog") ? 'Novedades destacadas' : 'Otras Novedades' : ''}</h2>
          </div>
          <div className="right-cards">
            {getOtherPosts().sort((a,b) => b.position - a.position).map((item,index) => (
              <CardNew 
                key={index}
                title={item.title}
                excerpt={item.excerpt}
                image={item.image}
                publish_date={item.publish_date}
                time={getTime_text(item.created_at)}
                route={"/app/"+  user_logged?.client?.name.replaceAll(" ","-").toLowerCase() +"/inversor/novedad/" + id}
                id={item.id}
                />
            ))}
          </div>
        </div>
        <span className="mt-3 mt-lg-5 text-goBack cursor-pointer d-flex align-items-center" onClick={() => history.goBack()}> {"< "}Volver atrás</span>
      </div>
    </div>
  ):'';
}
