import React, {useEffect} from "react";
import Breadcrumb from "../../Breadcrumb";
import CardNew from "./CardNew";
import img1 from "../../../../assets/images/temporal/cafe.jpeg";
import BuildingImage from "../../../../assets/images/temporal/build.png";
import MarketingImage from "../../../../assets/images/temporal/marketing.png";
import VideoImage from "../../../../assets/images/temporal/video.png";
import SocialMediaImage from "../../../../assets/images/temporal/socialmedia.png";
import Wrapper_2 from "../../Wrapper_2";

import { routes } from "../../../../static_data/routes";

import {useDispatch,useSelector} from 'react-redux'
import { CATEGORIES_GET_ACTION} from '../../../../redux/categoryDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { POSTS_GET_ACTION,CLEAR_POST_ACTION,CLEAR_POST_PRIVATE_ACTION} from '../../../../redux/blogsDucks'

import Loading from '../../../Globals/Loading'


export default function News() {


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    dispatch(POSTS_GET_ACTION())
    dispatch(CLEAR_POST_ACTION())
    dispatch(CLEAR_POST_PRIVATE_ACTION())
  },[])

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  const tags = useSelector(store => store.tags.tags)
  const categories = useSelector(store => store.categories.categories)
  const posts = useSelector(store => store.posts.posts)
  const {user_logged} = useSelector(store => store.users)
  const loading = useSelector(store => store.posts.loading)

  const breadcrumbItems = [
    {
      text: "Usuario",
      route:routes.mediaDocs.generalDoc.replaceAll(":cliente",user_logged?.client?.name.replaceAll(" ","-").toLowerCase()),
    },
    {
      text: "Recursos generales",
      route:routes.mediaDocs.generalDoc.replaceAll(":cliente",user_logged?.client?.name.replaceAll(" ","-").toLowerCase()),
    },
  ];

  const getOportunityForTag = () => {
    return posts.filter(element => element.tags.length === 0 && element.categories.find(element => element.id === getCategory("docs")) && (element.active.toString() === "true" || element.active.toString() === "1"))
  }

  return (

    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Recursos generales" subTitle={"Exclusivos para usuarios de " + user_logged?.client.name  + "."}>
      <div className="content-mobile-padding">
        <hr className="mt-0 mb-2r"/>
        <div className="ma-news">
          {loading ? <Loading/> : 
            getOportunityForTag().length > 0 ? 
            <>
              <div className="row">
                {getOportunityForTag().map((new_, i) => (
                  new_?.categories?.find(element => element.id === getCategory("docs")) && new_.tags.length === 0 && new_.groups_count === 0  ? 
                  <div className="col-lg-3 mb-3">
                    <CardNew key={i} {...new_} isFirst={i === 0} />
                  </div> : ""
                ))}
                
              </div>
              {/* <div className="ma-news-grid">
              </div>
              <div className="ma-news-grid-second mt-2r">
                {getOportunityForTag().map((new_, i) => (
                  i >= 5 && new_?.categories?.find(element => element.id === getCategory("docs")) && new_.tags.length === 0  ? <CardNew key={i} {...new_}  /> : ""
                ))}
              </div> */}
            </>
            :
            <h3>No hay recursos disponibles.</h3>
          }

        </div>
      </div>
    </Wrapper_2>
  );
}