import React, { useEffect, useState } from "react";
import { useHistory, Link, NavLink } from "react-router-dom";
import { AccountButton } from "../Sidebar/Sidebar";

import { routes } from "../../static_data/routes";
import { useSelector,useDispatch } from "react-redux";
import {USER_LOGOUT_ACTION} from "../../redux/userDucks"
import {SET_MENU_ACTION} from "../../redux/renderDucks"

import { useLocation } from "react-router-dom"

import {Level} from '../../static_data/level'

export default function Header() {
  const dispatch = useDispatch()
  const [openSettings, setOpenSettings] = useState(false);

  const user_logged = useSelector(store => store.users.user_logged)

  const menu = useSelector(store => store.render.menu)
  const location = useLocation()
  const history = useHistory();
  
  useEffect(()=>{
    if(user_logged){
      if(user_logged.level === '10' || user_logged.level === '9' || user_logged.level === '2' || user_logged.level === '3' || user_logged.level === '4'){
        root.style.setProperty('--client-color', user_logged.client.main_color);
        root.style.setProperty('--client-bg', user_logged.client.main_color);
      }
      else if(user_logged.level == '1'){
        root.style.setProperty('--client-color', user_logged.client.investors_primary_color);
        root.style.setProperty('--client-bg', user_logged.client.investors_primary_color);
      }
      history.push(location.pathname.replaceAll(":cliente",user_logged.client.name.toLowerCase().replaceAll(" ","-")))
    }
  },[user_logged])

  const staticBody = () => {
    var body = document.body;
    body.classList.toggle("overflow-hidden-mobile");
  }

  const getLevelName = (level_id) => {
    switch(level_id.toString()){
      case "1":
        return "Inversor"
      case "2":
        return "Usuario"
      case "3":
        return "Editor"
      case "4":
        return "Asesor"
      case "9":
        return "Super Administrador"
      case "10":
        return "Adminisitrador"
    }
  }

  return user_logged ? (
    <header className="header d-flex align-items-center">
      <div
        className={`${menu ? "menu-btn menu-btn-clicked" : "menu-btn"}`}
        onClick={() => {
          dispatch(SET_MENU_ACTION(!menu)) + staticBody();
        }}
      >
        <div></div>
      </div>

      <div className="brand-wrapper">
        <NavLink className="brand text-decoration-none" to={routes.base}>
          MediaCore®
        </NavLink>
      </div>

      <div className="d-flex gap-lg-3 align-items-center right-elements">
        {/* <div className="header-btn-container me-2 p-3">
          <img src={Gear2Icon} className="icon-config " alt="configuracion" />
        </div> */}
        <div className="header-btn-container me-lg-2 p-0 p-md-3">
          {/* <img src={BellIcon}  className="icon-notif " alt="notificaciones" /> */}
          <i className="icon-bell-icon"></i>
        </div>  
        <span className="user-name">{user_logged.name ? user_logged.name : 'Cargando..'}</span>
        <span className="user-admin">{user_logged ? getLevelName(user_logged.level) : 'Cargando..'}</span>

        <div className="position-relative d-none d-lg-block">
          <div
            className="avatar-button d-flex"
            onClick={() => {
              setOpenSettings((a) => !a);
            }}
          >
            <img className="avatar rounded-circle" src={process.env.REACT_APP_BASE_URL + (user_logged.profile_image ? user_logged.profile_image : "/storage/no-profile.jpg")} alt="avatar administrador" />
            <div
              className={`arrow-avatar ${
                openSettings ? "opacity-transition-100" : "opacity-transition-0"
              } `}
            ></div>
            {/* <img src={arrowDown} className="icon-header ms-3 d-none d-md-block" alt="avatar" /> */}
            <div className="div_arrow"><i className="icon-arrow-down"></i></div>
          
            
          </div>
          <DeployableSettings
            openSettings={openSettings}
            setOpenSettings={setOpenSettings}
          />
        </div>
      </div>
    </header>
  ):'';
}

export function DeployableSettings({ openSettings, setOpenSettings }) {
  const router = useHistory();
  const [openAccount, setOpenAccount] = useState(false);
  const user_logged = useSelector(store => store.users.user_logged)

  const dispatch = useDispatch();

  function pushUrl(url) {
    if(url === routes.session.login && (user_logged.level.toString() === '1')){
      url = routes.session.login.replace("login",user_logged.client.name.toLowerCase())
    }
    // closeMenu();
    router.push(url);
  }

  function handleClick(url) {
    setOpenSettings((os) => !os);
    pushUrl(url);
  }

  return (
    <div
      className={` ${
        openSettings ? "h-animation-open" : "h-animation-close d-none d-lg-block pb-0 pt-0"
      } account-settings-deployable`}
    >
      <div className="pt-3">
        <AccountButton
          icon="profile-icon"
          text={"Mi perfil"}
          className="text-gray-1 w-100"
          onClick={() => handleClick(routes.myProfile.myData)}
        ></AccountButton>
      </div>
      <div>
        <div
          className={`ms-3 ${
            openAccount ? "h-animation-open" : "h-animation-close"
          }`}
        >
        </div>
        <hr />
        <AccountButton
          icon="close-session"
          text={"Cerrar sesión"}
          className="text-gray-1 pb-3"
          onClick={() => pushUrl(routes.session.login) + dispatch(USER_LOGOUT_ACTION())}
        ></AccountButton>
      </div>
    </div>
  );
}
