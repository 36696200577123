import React, { useRef } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function DateTimeInput({dateTime, setDateTime}) {
  const [open, setOpen] = React.useState(false);

  function handleChange(value) {
    setDateTime(value);
    console.log(value);
  }
  function handleOpen() {
    setOpen(!open);
  }

  return (
    <>
      {/* <button onClick={handleOpen} className="text-start common-input position-relative"> */}
      <button onClick={handleOpen} className="text-start common-input position-relative button-date-time w-100">
        {dateTime.toLocaleDateString() + " - " + dateTime.toLocaleTimeString()}
        {/* <img src={DateIcon} className="position-absolute end-0 me-2"></img> */}
        <i className="position-absolute end-0 me-3  icon-calendar-2 icon-body icon-regular"></i>
      </button>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          onClose={handleOpen}
          open={open}
          value={dateTime}
          onChange={handleChange}
          dateRangeIcon={<i className="icon-calendar-2 opacity-50 icon-regular"></i>}
          className="d-none"
          cancelLabel="Cancelar"
          okLabel="Aceptar"
        />
      </MuiPickersUtilsProvider>
    </>
  );
}
