import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, NavLink } from "react-router-dom";
import {CLIENTS_GET_ACTION,USER_LOGIN_ACTION,USER_GET_LOGIN_ACTION,USER_SET_REDIRECTION} from '../../redux/userDucks'
import {useParams} from 'react-router-dom'
import Footer from '../Footer/Footer';
import { routes } from "../../static_data/routes";

export default function Login() {

    const {email} = useParams()

    return (
        <div>
            <div className="recover">
                <header className="header d-flex align-items-center">
                    
                    <div className="brand-wrapper">
                        <NavLink className="brand text-decoration-none" to={routes.base}>
                        MediaCore®
                        </NavLink>
                    </div>                
                </header>
                
                <div className="container-card">
                    <div className="card-background">
                        <h1 className="md-font text-mediahaus-1 mb-2 mb-md-5">Email enviado</h1>
                        <div class="d-flex flex-column align-items-start w-100">
                            <p className="size-2 font-1 text-gray-9 ">Te enviamos un email con instrucciones para restablecer la contraseña a <span className="font-0">{email} </span>
                                Si no lo ves en tu bandeja de entrada, revisa la carpeta de correo no deseado.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
    }