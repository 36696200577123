import {CATEGORY_DELETE_ACTION} from "../../../../redux/categoryDucks"
import {TAG_DELETE_ACTION} from "../../../../redux/tagsDucks"
import { useDispatch, useSelector } from "react-redux";

const DeleteModalCategory = (props) => {
    const dispatch = useDispatch()
    return (
    <>
        <div className="modal fade " id={"category-" + props.category.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Eliminación del emprendimiento</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    Es posible que el emprendimiento  <b>"{props.category.name}"</b> esté siendo utilizado en alguna recurso <br /> ¿Desea eliminar el emprendimiento de forma permanente? 
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" onClick={() => {dispatch(TAG_DELETE_ACTION(props.category.id))}} className="btn btn-danger" data-bs-dismiss="modal">Eliminar</button>
                </div>
                </div>
            </div>
        </div>
    </>)
}

export default DeleteModalCategory