import React, { useState, useRef, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Breadcrumb from "../../Breadcrumb";
import Wrapper_1 from "../../Wrapper_1";

import axios from 'axios'

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {useParams} from 'react-router-dom'

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import DateTimeInput from '../../../Globals/DateTimeInput'

import { POST_GET_ACTION, POST_UPDATE_ACTION,POST_UPDATE_IMAGE_ACTION } from '../../../../redux/blogsDucks'
import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'
import { TAGS_GET_ACTION } from '../../../../redux/tagsDucks'
import { toast } from "react-hot-toast";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
registerLocale('es', es)

import DatePicker from "react-datepicker";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.css";

import es from 'date-fns/locale/es';
// registerLocale('es', es)

import { routes } from "../../../../static_data/routes";

const breadcrumbItems = [
  {
    text: "MediaBlog®",
    route: routes.base
  },
  {
    text: "Novedades",
    route: routes.mediaBlog.news,
  },
  {
    text: "Nueva novedad",
    route: routes.mediaBlog.createNew
  },
];

// Mock data
const filters = [
  "Arquitectura sustentable",
  "Apto Crédito",
  "Departamentos",
  "Desarrollos",
  "Emprendimientos",
  "Multiuso",
];

export default function CreateNew() {
  const inputImage = useRef(null);
  const {id} = useParams()

  const dispatch = useDispatch();
  const response = useSelector(store => store.posts.response)
  const post = useSelector(store => store.posts.post)
  const loading = useSelector(store => store.posts.loading)
  const tags = useSelector(store => store.tags.tags)
  const user_logged = useSelector(store => store.users.user_logged)
  const categories = useSelector(store => store.categories.categories)

  
  const [loadTags,setLoadTags] = useState([])
  
  const history = useHistory()
  
  // const [startDate, setStartDate] = useState(new Date());
  
  
  const [sizeInput,setSizeInput] = useState(255);

  const [datos, setDatos] = useState({
    title:'',
    user_id:1,
    excerpt:'',
    image_caption:'',
    image:'',
    content:'',
    active:1,
    tags:[],
    publish_date:'',
    featured:0
  })

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const [active, setActive] = useState(true);
  const [dateTime, setDateTime] = useState(new Date());

  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();
  
  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    // console.log(picture_profile.current.files[0])
    setDatos({ ...datos, image: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };
  
  const uploadCallbackFunction = async (file) => {
    const imgData = await uploadInlineImageForArticle(file);
    return Promise.resolve({ data: { 
      link: `${imgData}`
    }});
  }

  const getCategory = (category) => {
    return categories?.find(item => item.name === category)?.id
  }
  
  const uploadInlineImageForArticle = async (file) => {
    let formData = new FormData();
    formData.append('image', file);
    try {
      const {data} = await axios.post('https://dev.mediacore.com.ar/api/v1/images',formData,{
        headers:{
          'Authorization': 'Bearer ' + user_logged.token
        }
      })
      return data.data.uri;
    } catch (error) {
        console.log(error);
      return null;
    }
  }

  useEffect(() => {
    dispatch(POST_GET_ACTION(id))
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
  },[])

  useEffect(() => {
    if(post.title){
        let curTags = [];
        for (const iterator of post.tags) {
          curTags.push(iterator.id.toString())
        }
        setDatos({
          title:post.title,
          user_id:post.user_id,
          excerpt:post.excerpt,
          image_caption:post.image_caption,
          image:post.image,
          content:post.content,
          tags:curTags,
          active:post.active,
          featured:post.featured,
          publish_date:new Date(post.publish_date)
        })
        const blocksFromHtml = htmlToDraft(post.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState))
    }
  },[post])

  useEffect(() => {
    if(post){
      if(datos.image !== post.image && datos.image !== ''){
        dispatch(POST_UPDATE_IMAGE_ACTION(post.id,datos.image))
        
      }
    }
  },[datos.image])

  useEffect(() => {
    if(response.success){
      if(response.statusText !== 'La imagen de portada fue actualizada correctamente.')
      {
        history.push("/app/media-blog/novedades")
      }
    }
  },[response])

  const handleCreateNew = (e) => {
    e.preventDefault();
    console.log(datos);
    if (!datos.image){
      toast('La portada es obligatoria.', { type: 'error' })
    }
    else if (sizeInput - datos.excerpt.length < 0){
      toast('El subtitulo debe tener un máximo de 255 caracteres.', { type: 'error' })
    }
    else if (sizeInput - datos.image_caption.length < 0){
      toast('El pie de imagen debe tener un máximo de 255 caracteres.', { type: 'error' })
    }
    else{
      dispatch(POST_UPDATE_ACTION(datos,post.id));
    }
  }
  const handleUpdateNew = (e) => {
    if(e.target.name == 'tags'){
      if(datos.tags.find(element => element === e.target.value)){
          const newTags = datos.tags.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newTags, 
          })
          return null
      }
      setDatos({
          ...datos,
          [e.target.name] : [...datos.tags,e.target.value], 
      })
      return null
  }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }
  const setPublish_date = (date) => {
    setDatos({
      ...datos,
      publish_date:date
    })
  }

  const checkTagUser = (tag,user) => {
    if(user.level === '10' || user.level === '9'){return true}
    return user.tags.find(element => element?.id.toString() === tag?.id?.toString())
  }

  return (
    <Wrapper_1 breadcrumbItems={breadcrumbItems} title="Nueva novedad">
      <form onSubmit={handleCreateNew} className="content-mobile-padding">
        <div className="mb-input-grid">
          <div className="d-flex flex-column ">
            <label className="font-1">Título</label>
            <input
              className="common-input"
              type="text"
              name="title"
              placeholder="Título"
              value={!loading ? datos.title : 'Cargando..'}
              onChange={handleUpdateNew}
            />
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Subtítulo</label>
            <input
              type="text"
              name="excerpt"
              placeholder="Subtítulo"
              onChange={handleUpdateNew}
              value={!loading ? datos.excerpt : 'Cargando..'}
              className={"common-input " + (sizeInput - datos.excerpt.length < 0 ? "border-danger" : "")}
              
              />
              <label className={"mt-2 " + ((sizeInput - datos.excerpt.length) < 0 && 'text-danger')}>
                {(sizeInput - datos.excerpt.length) + " caracteres disponibles"}
              </label>
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Fecha de la novedad: </label>
            <div className="d-flex input align-items-center">
            <DatePicker dateFormat="dd/MM/yyyy" selected={datos.publish_date} onChange={(date) => setPublish_date(date)}  locale="es" />
            <i className="icon-calendar icon-regular icon-body"></i>
            </div>
          </div>
          <div className="d-flex flex-column ">
            {/* <FileInput label="Logo" containerClassName="" className="py-3" /> */}
            <div class="content-img">
              <label for="addFile" className="font-1">Subir portada</label>  
                <div className={"drag mb-4 uploader w-100 d-flex " + drag}
                  dragleave={onDragLeave}
                  dragenter={onDragEnter}
                  onDrop={onDrop}
                 >
                  <div className={`d-flex flex-column w-100`}>
                    <span for="addFile" className={`image-picker-button small-font `}>
                      <label  for="addFile">
                        AGREGAR IMAGEN (JPG, PNG 1200x900)
                      </label>
                    </span>
                    <input
                      type="file"
                      name="addFile"
                      className="d-none"
                      ref={picture_profile}
                      onChange={changeFile}
                      id="addFile"
                    />
                  </div>
                  <img
                    className={"mx-0 mt-0 ms-2 " +( renderImage ? renderImage : datos.image ? datos.image : ' d-none')}
                    src={renderImage ? renderImage : datos.image ? "https://dev.mediacore.com.ar" + datos.image : ''}
                    width="150"
                  />{" "}
                </div>
              </div>
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Pié de imagen principal</label>
            <input
              className="common-input"
              type="text"
              name="image_caption"
              onChange={handleUpdateNew}
              value={!loading ? datos.image_caption : 'Cargando..'}
            />
          </div>
          <div className="d-flex flex-column">
            <label className="font-1">Filtros</label>
            <div className="grid-checkboxes">
              {tags ? tags?.filter(element => element?.categories?.find(element => checkTagUser(element,user_logged) && element.id === getCategory("blog"))).map((filter, i) => (
                
                // <Checkbox key={i} text={filter.name} />
                <div className="d-flex">
                <input
                  type="checkbox"
                  checked={datos.tags.find(element => element === filter.id.toString())}
                  // onChange={() => setChecked(!i)}
                  onClick={handleUpdateNew}
                  className="checkbox me-1"
                  name="tags"
                  value={filter.id}
                />
                <label className={`small-font  `}>
                {/* ${checked ? "font-0" : "font-1"} */}
                  {filter.name}
                </label>
              </div>
              )):''}
            </div>
          </div>

          <div className="d-flex flex-column mt-3 mb-4">
            <label className="font-1">Descripción</label>
            <div>
            <Editor
              editorState={editorState}
              wrapperClassName="card"
              editorClassName="card-body"
              onEditorStateChange={newState => {
                  setEditorState(newState);
                  handleUpdateNew({target:{name:"content",value:(draftToHtml(convertToRaw(newState.getCurrentContent())))}});;
              }}
              toolbar={{
                options: ['inline', 'blockType', 'list','link', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                inline: { 
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: false },
                blockType: {
                  inDropdown: false,
                  options: ['H1', 'H2', 'H3'],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                },
                image: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback:uploadCallbackFunction,
                  previewImage: true,
                  inputAccept: 'image/jpeg,image/jpg,image/png',
                  defaultSize: {
                    height: 'auto',
                    width: '100%',
                  },
                },
            }}
              />
            </div>
          </div>
        </div>
        <div className="new-ticket-inputs-2">
        {(user_logged.level === '9' || user_logged.level === '10')
          && 
          <>
              <label className="font-1">Activo</label>
              <div className="mt-2 mb-4 d-flex">
                <span
                  className={`font-1 small-font active-button-${
                    (datos.active !== true ? "gray" : "black")
                  }`}
                  onClick={() => {
                    handleUpdateNew({target:{name:"active",value:true}});              }}
                >
                  Si
                </span>
                <span
                  className={`font-1 small-font active-button-${
                    (datos.active !== false ? "gray" : "black")
                  }`}
                  onClick={() => {
                    handleUpdateNew({target:{name:"active",value:false}});
                  }}
                >
                  No
                </span>
              </div>
          </>
        }
          <button className="blue-button mt-3">Guardar</button>
          </div>
      </form>
    </Wrapper_1>
  );
}

function Checkbox({ is = false, text }) {
  const [checked, setChecked] = useState(is);

  return (
    <div className="d-flex">
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
        className="checkbox me-1"
      />
      <label className={`small-font  ${checked ? "font-0" : "font-1"}`}>
        {text}
      </label>
    </div>
  );
}
