import React, { useState, useEffect } from "react";
import Breadcrumb from "../../Breadcrumb";

import Wrapper_1 from "../../Wrapper_1";
import { routes } from "../../../../static_data/routes";

import { TAG_UPDATE_ACTION,TAG_GET_ACTION } from '../../../../redux/tagsDucks'
import { useSelector, useDispatch } from "react-redux";

import { useHistory,useParams } from "react-router-dom";

export default function UpdateFilter() {
  const breadcrumbItems = [
    {
      text: "MediaTeam®",
      route: routes.base,
    },
    {
      text: "Filtros",
      route: routes.mediaTeam.filters,
    },
    {
      text: "Actualizar filtro",
      route: routes.mediaTeam.updateFilter,
    },
  ];

  const history = useHistory()

  const {id} = useParams()

  const dispatch = useDispatch();
  const response = useSelector(store => store.tags.response)
  const user_logged = useSelector(store => store.users.user_logged)
  const tag = useSelector(store => store.tags.tag)
  const loading = useSelector(store => store.tags.loading)



  const [datos,setDatos] = useState({
    name:'',
    id:''
  });

  useEffect(() => {
    dispatch(TAG_GET_ACTION(id))
  },[])

  useEffect(() => {
    if(tag){
        setDatos({
          name:tag.name,
          id:tag.id,
        })
    }
    console.log(tag)
  },[tag])


  useEffect(() => {
    if(response.success){
      history.push("/app/media-team/filtros")
    }
  },[response])



  const handleCreateFilter = (e) => {
    e.preventDefault();
    setDatos({
      ...datos,
      user_id : user_logged.id, 
    })
    dispatch(TAG_UPDATE_ACTION(datos,id));
  }

  const handleUpdateTag = (e) => {
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }

  return (
    <Wrapper_1 breadcrumbItems={breadcrumbItems} title="Nuevo filtro">
      <form onSubmit={handleCreateFilter} className="content-mobile-padding w-100">
        <div className="d-flex flex-column align-items-start w-100">
          <label>Nombre</label>
          <input name="name" value={!loading ? datos.name : 'Cargando..'} onChange={handleUpdateTag} type="text" className="common-input w-100 mxw-500 mb-2" />
          <button className="blue-button mt-4">Guardar</button>
        </div>
      </form>
    </Wrapper_1>
  );
}
