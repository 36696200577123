import React from 'react'

export default function Footer() {
 
    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    return (
        <div className="footer">
            {/* <footer className="mt-3 mt-lg-0 " style={{textAlign:"center"}}> */}
                {/* Plataforma <span style={{color:"#00A1FF"}} > MediaCore</span>by <a target="_blank" href="https://www.mediahaus.com.ar" style={{color:"#00A1FF"}}> MediaHaus<span style={{fontSize:".5rem", verticalAlign:"text-top"}}>®</span> </a>{getCurrentYear()}. <br className="d-block d-lg-none"/> Todos los derechos reservados. */}
            {/* </footer> */}
            {/* <p className="font-1 small-font text-gray-2">By <a style= {{textDecoration:"none",color:"#707070"}} href="https://www.mediacore.com.ar/app" target="_blank">MediaCore<span style={{fontSize:".5rem", verticalAlign:"text-top"}}>® </span> 1.0 </a>  by <a className="link-1" href="https://www.mediahaus.com.ar" target="_blank">MediaHaus<span style={{fontSize:".5rem", verticalAlign:"text-top"}}>® </span></a> {getCurrentYear()}. <br className="d-lg-none" /> Todos los derechos reservados.</p> */}
            <p className="font-1 small-font text-gray-2">Plataforma <a className="link-1" href="https://www.mediahaus.com.ar" target="_blank">MediaHaus<span style={{fontSize:".5rem", verticalAlign:"text-top"}}>® </span></a>  & Powered by  <a style= {{textDecoration:"none",color:"#707070"}} href="https://www.mediacore.com.ar/app" target="_blank">MediaCore<span style={{fontSize:".5rem", verticalAlign:"text-top"}}>® </span> </a> v1 - {getCurrentYear()}. <br className="d-lg-none" /> Todos los derechos reservados. </p>
        </div>
    )
}
