import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

import postsDucks from './blogsDucks.jsx'
import tagsDucks from './tagsDucks.jsx'
import usersDucks from './userDucks.jsx'
import clientsDucks from './clientsDucks.jsx'
import analyticsDucks from './analyticsDucks'
import investorsDucks from './investorsDucks'
import editorsDucks from './editorsDucks'
import advisersDucks from './advisersDucks'
import membersDucks from './membersDucks'
import categoriesDucks from './categoryDucks'
import renderDucks from './renderDucks'

const rootReducer = combineReducers({
    posts: postsDucks,
    tags: tagsDucks,
    users: usersDucks,
    clients: clientsDucks,
    analytics: analyticsDucks,
    investors: investorsDucks,
    editors: editorsDucks,
    advisers: advisersDucks,
    members: membersDucks,
    categories: categoriesDucks,
    render: renderDucks,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore( rootReducer, composeEnhancers( applyMiddleware(thunk) ) )
    return store
}