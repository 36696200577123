import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "../../../assets/svgs/search-icon.svg";
import AddIcon from "../../../assets/svgs/add-icon.svg";
import EditIcon from "../../../assets/svgs/edit-icon.svg";
import DeleteIcon from "../../../assets/svgs/delete-icon.svg";
import Wrapper_2 from "../Wrapper_2";
import Table from "../Table";
import {CLIENTS_GET_ACTION, CLIENTS_TYPE_GET_ACTION} from "../../../redux/clientsDucks"
import { useDispatch, useSelector } from "react-redux";


import ModalDelete from './Modal/DeleteModalClient'

import Loading from '../../../components/Globals/Loading';

import { routes } from "../../../static_data/routes";

export default function News() {
  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.base,
    },
    {
      text: "Clientes",
      route: routes.mediaSmart.clients,
    },
  ];

  const dispatch = useDispatch();

  const clients = useSelector(store => store.clients.clients)

  const error = useSelector(store => store.clients.error)
  const success = useSelector(store => store.clients.success)

  const response = useSelector(store => store.clients.response)

  const [showModal,setModal] = useState(true)

  const client_types = useSelector(store => store.clients.client_types)
  const loading = useSelector(store => store.clients.loading)
  const [searchName,setSearchName] = useState('')
  const [searchType,setSearchType] = useState('-1')



  useEffect(() => {
    // if(!clients){
      dispatch(CLIENTS_GET_ACTION());
    // }
    console.log(client_types)
    if(client_types.length === 0){
      dispatch(CLIENTS_TYPE_GET_ACTION());
    }
  },[])

  return !loading ? (
    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Clientes"
      rightLink={{
        text: "Nuevo cliente",
        icon: AddIcon,
        route: routes.mediaSmart.createClient,
      }}>
      <div className="content-mobile-padding w-100p">
        <div className="d-flex flex-wrap gap-3 mb-2r">
          <div className="input-search-period w-50p hover-border-blue-1">
            <input
              type="text"
              placeholder="Palabra clave"
              onChange={(e) => setSearchName(e.target.value)}
              className="focus-outline-none"
            />
            <img src={SearchIcon} alt="buscar periodo" width="15px" />
          </div>
          <select
            className="common-input input-search-period w-25p"
            onChange={(e) => {setSearchType(e.target.value)}}
          >
            <option value="-1">Tipo de Usuario</option>
            {
              client_types ?
              client_types.filter(element => element.id > 3).map((element,index) => (
                  <option value={element.id}>{element.name}</option>
              )):''
            }
          </select>
        </div>
          <div className="tables-wrapper">
            <h3>Activos</h3>
            <ClientsTablet clients={clients.filter(element => element.id > 5 && (element.active.toString() === 'true' || element.active.toString() === '1') )} searchName={searchName} searchType={searchType}/>
            <h3>No Activos</h3>
            <ClientsTablet clients={clients.filter(element => element.id > 5 && (element.active.toString() === 'false' || element.active.toString() === '0') )} searchName={searchName} searchType={searchType}/>
          </div>
      </div>
    </Wrapper_2>
  ):<Loading />;
}

function ClientsTablet({ clients,searchName,searchType}) {
    const client_types = useSelector(store => store.clients.client_types)

    const getType_client = (types,id) => {
        const finder = types.find(type => type.id.toString() === id.toString())
        return finder ? finder : [];
    } 

    const getDate = (date) => {
        const step = date.split("T");
        return step[0];
    }


    const formateDateDDMMYYYY = (date) => {
        if(!date){return null}
        const dateparse = date.split("-")
        return dateparse[2] + '/' + dateparse[1] + '/' + dateparse[0]
      }

    const checkCompleteClient = (client) => {
        if(client.domain_expiration_date === '' || client.domain_name === '' || client.domain_extension === '' || client.ga_client_id === '' || !client.tokko_apikey ){
            return false
        }
        return true
    }

    const checkTokkoClient = (client) => {
      const schedule = client.schedules.filter(element => element.type === "tokko-api")[0]
      if(!schedule){
        return false
      }
      const response = {
        status: schedule?.success,
        description_property: schedule?.description.split(" | ").length > 1 ? `Se generaron ${schedule.description.split(" - ")[1].split(" | ")[0]}` : schedule.description,
        description_development: schedule?.description.split(" | ").length > 1 ? `Se generaron ${schedule.description.split(" - ")[2].split(" | ")[0]}` : schedule.description,
        date:schedule.created_at.split("T")[0] + " " + schedule.created_at.split("T")[1].split(".")[0]
      } 
      return response
    }
    
    return clients && client_types ? (
        <Table
        TableHeader={
            <tr>
            <th className="width-300">Cliente</th>
            <th>Tipo</th>
            <th>Dominio</th>
            <th>Vencimiento</th>
            <th>API Tokko</th>
            <th>Completo</th>
            <th>Acciones</th>
            </tr>
        }
        TableRows={clients.map((client, i) => (
            client.name.toLowerCase().includes(searchName.toLowerCase()) && (client.client_type_id.toString() === searchType.toString() || searchType.toString() === "-1") ?
            <tr key={i} className={"text-nowrap " + (!client.active ? 'disabled' : '')}>
            <td className="width-300">
                <img src={"https://dev.mediacore.com.ar" + client.logo} alt="usuario" className="me-4 rounded-circle" height="35px" width="35px" style={{objectFit:"cover",objectPosition:"center"}} />
                {client.name}</td>
            <td>{client_types ? getType_client(client_types,client.client_type_id).name : 'Cargando..'}</td>
            <td  className="width-300">
                {client.domain_name ? client.domain_name : 'Sin especificar'}
            </td>
            <td className={"" + (client.domain_status_id.toString() === '1' ? 'success' : 'danger-' + client.domain_status_id.toString())}>
              <div className="d-flex justify-content-center align-items-center">
                {/* <div className={"radius-span me-2 rounded-circle " + (client.domain_status_id.toString() === '1' ? 'bg-success' : 'bg-danger-' + client.domain_status_id.toString()) } style={{width:"1rem",height:"1rem"}}></div> */}
                {formateDateDDMMYYYY(client.domain_expiration_date)}
              </div>
            </td>
            <td className="" >
                <div className={"radius-span ms-2 rounded-circle d-inline-block mx-auto " + (checkTokkoClient(client).status ? 'bg-success' : 'bg-danger')} style={{width:"1rem",height:"1rem"}}>
                  <p>
                    Últma actualización <br />
                    {checkTokkoClient(client).date} <br />
                    {checkTokkoClient(client).description_property} <br />
                    {checkTokkoClient(client).description_development !== checkTokkoClient(client).description_property && checkTokkoClient(client).description_development}
                  </p>
                </div>
            </td>
            <td className="" >
              <div className={"radius-span ms-2 rounded-circle d-inline-block mx-auto " + (checkCompleteClient(client) ? 'bg-success' : 'bg-danger')} style={{width:"1rem",height:"1rem"}}></div>
            </td>
            <td>
                <button className="button-no-styled ">
                <Link to={"clientes/actualizar-cliente/"+client.id} ><img src={EditIcon} alt="editar novedad" className="me-3" /></Link>
                <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#client-"+client.id}><img src={DeleteIcon}  alt="editar novedad" /></button>
                <ModalDelete client={{id:client.id,name:client.name}} />
                </button>
            </td>
            </tr>
            :
            ''
        ))}
        />
    ):<Loading />;
}
