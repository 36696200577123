import React, { useState, useEffect, useRef } from "react";
import Wrapper_2 from "../../Wrapper_2";
import TableOrder from '../../TableOrder'
import { Link } from "react-router-dom";
import { routes } from "../../../../static_data/routes";
import { useDispatch, useSelector } from "react-redux";
import { USERS_GET_ACTION } from '../../../../redux/userDucks'
import { MEMBERS_GET_ACTION } from '../../../../redux/membersDucks'
import { POSTS_GET_ACTION, POSTS_UPDATE_FEATURED_ACTION,CLEAR_POST_ACTION,CLEAR_INVESTORS_ACTION } from '../../../../redux/blogsDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'
import ModalDuplicate from '../../MediaBlog/News/DuplicateModalNew'
import ModalDelete from './DeleteModalNew'

import Loading from '../../../Globals/Loading'
// import { dispatch } from "react-hot-toast/dist/core/store";

import '../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
// Mock data


export default function News() {

  const dispatch = useDispatch();
  const [searchGenerals,setSearchGenerals] = useState('')
  const [searchGroupal,setSearchGroupal] = useState('')
  const [searchPrivate,setSearchPrivate] = useState('')
  const [searchNotActive,setSearchNotActive] = useState('')
  const posts = useSelector(store => store.posts.posts)
  const loading = useSelector(store => store.posts.loading)
  const categories = useSelector(store => store.categories.categories)

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  useEffect(() => {
    dispatch(CLEAR_POST_ACTION())
    dispatch(POSTS_GET_ACTION())
    dispatch(USERS_GET_ACTION())
    dispatch(MEMBERS_GET_ACTION())
    dispatch(CLEAR_POST_ACTION())
    dispatch(CLEAR_INVESTORS_ACTION())
    dispatch(CATEGORIES_GET_ACTION())

  },[])

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaDocs.about,
    },
    {
      text: "MediaHub®",
      route: routes.mediaDocs.about,
    },
    {
      text: "Recursos",
      route: routes.mediaDocs.news,
    },
  ];

  const getTime_text = (time) => {
    const spliter = time.split('T');

    const fecha = spliter[0].split('-');

    const hora_spliter = spliter[1].split('.');
    const hora = hora_spliter[0].split(':')

    // const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
    const date_time = fecha[2] + "/"+ fecha[1] + "/" + fecha[0] + " " + hora[0] + ":" + hora[1] + ":" + hora[2];

    return date_time;

  }

  const columns = [{
    dataField: 'title',
    text: 'Titulo del recurso',
    sort: true,
    formatter: (cell, row) => {
      return <div className="d-flex align-items-center">
        <div className="title-min-width">{row.title}</div>
        {row.tags.map((element,index) => (
          index < 2 &&
          <div className="d-none d-xxl-flex pill">{element.name}</div>
        ))}
      </div>
    },
  }, {
    dataField: 'user.name',
    text: 'Creador',
    sort: true,
    formatter: (cell, row) => {
      return(
        <div className="d-flex justify-content-center align-items-center">
        <img
          src={process.env.REACT_APP_BASE_URL + (row.user.profile_image ? row.user.profile_image : "/storage/no-profile.jpg")}
          alt="creador de la novedad"
          className="me-3 d-block"
          width="35px"
          height={"35px"}
          style={{ borderRadius: "50%" }}
        />
        {row.user.name}
      </div>
      )
    }
  }, {
    dataField: 'created_at',
    text: 'Fecha de creación',
    formatter: (cell, row) => {
      return getTime_text(row.created_at)
    },
    sort: true

  },
  {
    dataField: 'updated_at',
    text: 'Última modificación',
    formatter: (cell, row) => {
      return getTime_text(row.updated_at)
    },
    sort: true

  },
  {
    dataField: 'actions',
    text: 'Acciones',
    formatter: (cell, row) => {
      return (
        <button className={"button-no-styled "}>
        {
            <>
                <Link to={"recursos/actualizar-comunicacion/" + row.id} ><i className="icon-edit-icon me-0 me-lg-3"></i></Link>
                <button className="me-2" style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#post-"+row.id}><i className="icon-delete"></i></button>
                {/* <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#post-duplicate-"+row.id}><i className="icon-copy"></i></button> */}
                <ModalDelete post={{id:row.id,title:row.title}} />
                <ModalDuplicate post={row} />
            </>
        }
        
    </button>

      )}
  }];

  const filterNoActive = (news,filtering = false) => {
    let filtered = []
    filtered = news.filter((new_) => (new_.active === false || new_.active.toString()  === '0' ) && new_.categories.find(element => element.id === getCategory("docs")))
    let response = filtered;
    if(filtering){
      response = filtered.filter((comunication) => comunication?.title?.toLowerCase().includes(searchNotActive.toLowerCase()))
    }
    return response.sort((a,b) => b.id - a.id)
  }
  const filterNews = (news,bool_active,filtering = false) => {
    const filtered = news.filter((new_) => new_.active.toString() === bool_active.toString() && new_.categories.find(element => element.id === getCategory("docs")) && new_.tags.length > 0 && new_.groups_count === 0)
    let response = filtered;
    if(filtering){
      response = filtered.filter((comunication) => comunication?.title?.toLowerCase().includes(searchGroupal.toLowerCase()))
    }
    return response.sort((a,b) => b.id - a.id)
  }
  const filterOportunities = (news,bool_active,filtering = false) => {
    const filtered = news.filter((new_) => new_.active.toString() === bool_active.toString() && new_.categories.find(element => element.id === getCategory("docs")) && new_.tags.length === 0 && new_.groups_count === 0)
    let response = filtered;
    if(filtering){
      response = filtered.filter((comunication) => comunication?.title?.toLowerCase().includes(searchGenerals.toLowerCase()))
    }
    return response.sort((a,b) => b.id - a.id)
  }
  const filterPrivate = (news,bool_active,filtering = false) => {
    const filtered = news.filter((new_) => new_.active.toString() === bool_active.toString() && new_.categories.find(element => element.id === getCategory("docs")) && new_.groups_count > 0)
    let response = filtered;
    if(filtering){
      response = filtered.filter((comunication) => comunication?.title?.toLowerCase().includes(searchPrivate.toLowerCase()))
    }
    return response.sort((a,b) => b.id - a.id)
  }

  return posts ? (
    <Wrapper_2
      breadcrumbItems={breadcrumbItems}
      title="Recursos"
      rightLink={{
        text: "Nuevo Recurso",
        icon: "addIcon",
        route: routes.mediaDocs.createNew,
      }}
      check="Posts scope for writing records"
    >
      <div className="content-mobile-padding w-100p ">
        {!loading ? 
          <>
            <div className={"tables-wrapper mt-5" + (filterOportunities(posts,true).length > 0 ? '' : ' d-none')}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Recursos para todos los usuarios</h3>
                <div className="input-search-period w-40p hover-border-blue-1">
                  <input type="text" placeholder="Palabra clave" onChange={(e) => setSearchGenerals(e.target.value)} className="focus-outline-none" />
                  <i className="icon-search-icon"></i>
                </div>
              </div>
              <BootstrapTable keyField='id' data={filterOportunities(posts,true,true)} columns={ columns } />
              {/* <NewsTable news={filterOportunities(posts,true)} searchName={searchName} /> */}
            </div>
            <div className={"tables-wrapper mt-5" + (filterNews(posts,true).length > 0 ? '' : ' d-none')}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3>Recursos referentes a emprendimientos</h3>
                <div className="input-search-period w-40p hover-border-blue-1">
                    <input type="text" placeholder="Palabra clave" onChange={(e) => setSearchGroupal(e.target.value)} className="focus-outline-none" />
                    <i className="icon-search-icon"></i>
                </div>
              </div>
              <BootstrapTable keyField='id' data={filterNews(posts,true,true)} columns={ columns } />
              {/* <NewsTable news={filterNews(posts,true)} searchName={searchName} /> */}
            </div>
            <div className={"tables-wrapper mt-5" + (filterPrivate(posts,true).length > 0 ? '' : ' d-none')}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Recursos exclusivos para los usuarios</h3>
                <div className="input-search-period w-40p hover-border-blue-1">
                    <input type="text" placeholder="Palabra clave" onChange={(e) => setSearchPrivate(e.target.value)} className="focus-outline-none" />
                    <i className="icon-search-icon"></i>
                </div>
              </div>
              <BootstrapTable keyField='id' data={filterPrivate(posts,true,true)} columns={ columns } />
            </div>
            <div className={"tables-wrapper mt-5" + (filterNoActive(posts).length > 0 ? '' : ' d-none')}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Recursos en borrador</h3>
                <div className="input-search-period w-40p hover-border-blue-1">
                    <input type="text" placeholder="Palabra clave" onChange={(e) => setSearchNotActive(e.target.value)} className="focus-outline-none" />
                    <i className="icon-search-icon"></i>
                </div>
              </div>
              <BootstrapTable keyField='id' data={filterNoActive(posts,true)} columns={ columns } />
            </div>
          </>
        :<Loading /> }
        {/* */}
      </div>
    </Wrapper_2>
  ):'';
}

function NewsTable({ news, isFinished = false, searchName = '' }) {

  const [newFilter,setNewFilter] = useState()

  useEffect(() => {
    setNewFilter(news)
  })


  return (
    <>
      <TableOrder 
      tableHeader = {['Título de la novedad','Creador','Fecha de creación','Última modificación','Acciones']}
      tableRow = {news ? news.filter((new_) => new_.title.toLowerCase().includes(searchName.toLowerCase())) : ''}
      searchName = {searchName} 
      members = {true}
      />
    </>
  );
}
