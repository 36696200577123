import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


import LogoImage from "../../assets/svgs/logo.svg";

import DeployableButton from "./DeployableButton";
import { DeployableSettings } from "../Header/Header";
import { routes } from "../../static_data/routes";
import { useSelector, useDispatch } from "react-redux";

import {USER_LOGOUT_ACTION} from '../../redux/userDucks'
import {SET_MENU_ACTION} from "../../redux/renderDucks"

import { useLocation } from "react-router";

const assets_path = "assets";

export default function Sidebar() {
  const router = useHistory();
  const dispatch = useDispatch()
  const [openAccount, setOpenAccount] = useState(false);

  const location = useLocation()

  const user_logged = useSelector(store => store.users.user_logged)
  const menu = useSelector(store => store.render.menu)

  const staticBody = () => {
    var body = document.body;
    body.classList.toggle("overflow-hidden-mobile");
  }

  function pushUrl(url) {
    if(url === routes.session.login && user_logged.level.toString() === '1'){
      url = routes.session.login.replace("login",user_logged.client.name.toLowerCase())
    }
    closeMenu();
    router.push(url);
  }
  function closeMenu() {
    // setMenu(false);
    staticBody()
    dispatch(SET_MENU_ACTION(false))
  }

  const orderButtons = (buttons) => {
    for(let j = 0; j < buttons.length; j++){
      for(let i = 0; i < user_logged.client.modules.length; i++){
        if(buttons[j].module_id === user_logged.client.modules[i].id){
          const wrapper = buttons[j]
          buttons.splice(j, 1)
          buttons.unshift(wrapper)
        }
      }
    }

    return buttons
  } 
  
  const DeployableButtons = [
    {
      text: "MediaOwner®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaProp®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaBroker®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaPush®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaRent®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaPro®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaData®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaLab®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaCom®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaMailing®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaPress®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaSign®",
      subButtons: [],
      module_id:99,
      rightIcon: "locker",
    },
    {
      text: "MediaHub®",
      subButtons: [],
      rightIcon: "locker",
      module_id:4,
      active:"media-hub",
      subButtons:[
        {
          text: "Recursos",
          // rightIcon: NewsIcon,
          rightIcon:"new-icon ",
          route: routes.mediaDocs.news,
        },
        {
          text: "Emprendimientos",
          // rightIcon: LabelIcon,
          rightIcon: "label-icon",
          route: routes.mediaDocs.entrepreneurships,
        },
        {
          text: "Usuarios",
          rightIcon: "people-icon",
          route: routes.mediaDocs.members,
        }
      ]
    },
    {
      text: "MediaInvestor®",
      subButtons: [],
      rightIcon: "locker",
      module_id:2,
      active:"media-investor",
      subButtons:[
        {
          text: "Novedades",
          // rightIcon: NewsIcon,
          rightIcon:"new-icon ",
          route: routes.mediaInvestor.news,
        },
        {
          text: "Emprendimientos",
          // rightIcon: LabelIcon,
          rightIcon: "label-icon",
          route: routes.mediaInvestor.entrepreneurships,
        },
        {
          text: "Inversores",
          rightIcon: "people-icon",
          route: routes.mediaInvestor.investors,
        },
        {
          text: "Configuracion",
          rightIcon: "gear",
          route: routes.mediaInvestor.config,
        },
        // {
        //   text: "Acerca de MI®",
        //   rightIcon: InfoIcon,
        //   route: routes.mediaInvestor.about,
        // },
      ]
    },
    {
      text: "MediaTeam®",
      subButtons: [],
      rightIcon: "locker",
      module_id:5,
      active: 'media-team',
      subButtons: [
        {
          text: "Novedades",
          rightIcon: "new-icon",
          route: routes.mediaTeam.news,
        },
        {
          text: "Filtros",
          rightIcon: "label-icon",
          route: routes.mediaTeam.filters,
        },
        {
          text: "Asesores",
          rightIcon: "people-icon",
          route: routes.mediaTeam.editors,
        },
      ],
    },
    {
      text: "MediaBlog®",
      subButtons: [],
      rightIcon: "locker",
      module_id:1,
      active: 'media-blog',
      subButtons: [
        {
          text: "Novedades",
          rightIcon: "new-icon",
          route: routes.mediaBlog.news,
        },
        {
          text: "Filtros",
          rightIcon: "label-icon",
          route: routes.mediaBlog.filters,
        },
        {
          text: "Editores",
          rightIcon: "people-icon",
          route: routes.mediaBlog.editors,
        },
      ],
    },
    {
      text: "MediaTracker®",
      active: 'media-data',
      subButtons: [
        {
          text: "Métricas sitio web",
          rightIcon: "metricas",
          route: routes.mediaData.analytics,
        },
      ],
      module_id:3,
    },
    // {
    //   text: "Más Servicios",
    //   subButtons: [],
    //   rightIcon: LockIcon,
    // },
  ];

  const InvestorsButtons = [
    {
      text: "Inversores",
      rightIcon: "locker",
      module_id:1,
      active: 'inversor',
      subButtons: [
        {
          text: "Mis inversiones",
          route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/inversor/mis-inversiones",
          rightIcon: "mis_emprendimientos",
        },
        {
          text: "Mis novedades",
          route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/inversor/mis-novedades",
          rightIcon: "mis_novedades",
        },

        {
          text: "Prelanzamientos",
          route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/inversor/prelanzamientos",
          rightIcon: "prelanzamientos",
        },
        {
          text: "Novedades",
          route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/inversor/novedades",
          rightIcon: "new-icon",
        },
      ],
    },
  ]
  const MembersButtons = [
    {
      text: "Recursos",
      rightIcon: "locker",
      module_id:3,
      active: 'usuario',
      subButtons: [
        {
          text: "Generales",
          route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/media-hub/recursos",
          rightIcon: "new-icon",
        },
        {
          text: "Propias",
          route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/media-hub/mis-recursos",
          rightIcon: "new-icon",
        },

        {
          text: "Emprendimientos",
          route: "/app/" + user_logged?.client?.name.replaceAll(" ","-").toLowerCase() + "/media-hub/emprendimientos",
          rightIcon: "mis_emprendimientos",
        },
      ],
    },
  ]
  const EditorButtons = [
    {
      text: "MediaBlog®",
      subButtons: [],
      rightIcon: "locker",
      module_id:1,
      active: 'media-blog',
      subButtons: [
        {
          text: "Novedades",
          rightIcon: "new-icon",
          route: routes.mediaBlog.news,
        },
      ],
    },
  ]
  const AsesorButtons = [
    {
      text: "MediaTeam®",
      subButtons: [],
      rightIcon: "locker",
      module_id:5,
      active: 'media-team',
      subButtons: [
        {
          text: "Novedades",
          rightIcon: "new-icon",
          route: routes.mediaTeam.news,
        },
      ],
    },
  ]

  const SmartLiteButtons = [
    {
      text: "MediaSmartLite®",
      rightIcon: "locker",
      module_id:3,
      active: 'media-smart',
      subButtons: [
        {
          text: "Clientes",
          route: "/app/media-smart/clientes",
          rightIcon: "mis_emprendimientos",
        },
        {
          text: "Usuarios",
          route: "/app/media-smart/usuarios",
          rightIcon: "mis_novedades",
        },
      ],
    },
  ]

  useEffect(() => {
  },[user_logged])

  const getCurrentView = (name) => {
    return location?.pathname.includes(name)
  }

  return user_logged ? (
    <aside className={`left-side menu ${menu ? "open-menu" : ""}`}>
      <div className="d-flex flex-column align-items-center mt-5 mt-lg-0" id="side-menu">
        <img
          className="logo-brand d-none d-lg-block"
          src={user_logged.client.logo && user_logged.level === '10' || user_logged.level === '9' || user_logged.level === '2' || user_logged.level === '3' || user_logged.level === '4' ? process.env.REACT_APP_BASE_URL + user_logged.client.logo : user_logged.level === '1' && user_logged.client.investors_logo ? process.env.REACT_APP_BASE_URL + user_logged.client.investors_logo : LogoImage}
          alt="Logo Template"
          width="100%"
        />

        {user_logged?.level?.toString() === '9' ? orderButtons(SmartLiteButtons).map((buttonDataSmart, i) => (
          <DeployableButton isSmart={true} activeButton={getCurrentView(buttonDataSmart.active)} deployed={(getCurrentView(buttonDataSmart.active)) ? true : false}  index={i} key={i} {...buttonDataSmart} onClick={closeMenu} />
        )):''}
        {user_logged?.level?.toString() === '10' || user_logged?.level?.toString() === '9' ? orderButtons(DeployableButtons).map((buttonData, i) => (
          <DeployableButton activeButton={getCurrentView(buttonData.active)} deployed={(getCurrentView(buttonData.active)) ? true : false}  index={i} key={i} {...buttonData} onClick={closeMenu} />
        )):''}
        {user_logged?.level?.toString() === '1' ? InvestorsButtons.map((buttonDataInversor,i) => 
          <DeployableButton activeButton={getCurrentView(buttonDataInversor.active)} deployed={(getCurrentView(buttonDataInversor.active) || user_logged?.level.toString() === '1' || user_logged?.level?.toString() === '2')} index={i} key={i} {...buttonDataInversor} onClick={closeMenu}  />
        ):''}
        {user_logged?.level?.toString() === '2' ? MembersButtons.map((buttonDataMember,i) => 
          <DeployableButton activeButton={getCurrentView(buttonDataMember.active)} deployed={(getCurrentView(buttonDataMember.active) || user_logged?.level.toString() === '1' || user_logged?.level?.toString() === '2')} index={i} key={i} {...buttonDataMember} onClick={closeMenu}  />
        ):''}
        {user_logged?.level?.toString() === '3' ? EditorButtons.map((buttonDataEditor,i) => 
          <DeployableButton activeButton={getCurrentView(buttonDataEditor.active)} deployed={(getCurrentView(buttonDataEditor.active) || user_logged?.level.toString() === '1' || user_logged?.level?.toString() === '2')} index={i} key={i} {...buttonDataEditor} onClick={closeMenu}  />
        ):''}
        {user_logged?.level?.toString() === '4' ? AsesorButtons.map((AsesorButton,i) => 
          <DeployableButton activeButton={getCurrentView(AsesorButton.active)} deployed={(getCurrentView(AsesorButton.active) || user_logged?.level.toString() === '1' || user_logged?.level?.toString() === '2')} index={i} key={i} {...AsesorButton} onClick={closeMenu}  />
        ):''}
        
      </div>
          
      <div className="account-buttons d-flex flex-column ">
        <AccountButton floatIcon="profile-icon" text={"Mi perfil"} onClick={() => {pushUrl(routes.myProfile.myData);}}></AccountButton>
        <AccountButton floatIcon="close-session" className="mt-3" text={"Cerrar sesión"} onClick={() => { pushUrl(routes.session.login) + dispatch(USER_LOGOUT_ACTION()) }} ></AccountButton>
      </div>
    </aside>
  ):'';
}

export const AccountButton = ({
  text,
  icon,
  rightIcon,
  floatIcon,
  onClick = () => {},
  className = "",
}) => {
  return (
    <button className={`account-button position-relative ${className}`} onClick={onClick} >
      {icon && (<i className={"icon-" + (icon)}></i> )}
      {/* {icon && <img src={icon} alt="icon button" width="15px" />} */}
      <span>{text}</span>
      {/* {rightIcon && <img src={rightIcon} alt="icon button" />} */}
      {/* {rightIcon && <i className={"icon-" + rightIcon}></i>} */}
      {/* {floatIcon && ( <img className="position-absolute end-0 me-2" src={floatIcon} alt="icon button" /> )} */}
      {floatIcon && ( <i className={"position-absolute end-0 me-2 icon-" + floatIcon}></i> )}

      
    </button>
  );
};


  // {
  //   text: "MediaHost®",
  //   deployed: true,
  //   subButtons: [
  //     {
  //       text: "Soporte",
  //       rightIcon: SupportIcon,
  //       route: routes.mediaHost.support,
  //     },
  //     {
  //       text: "Acerca de MH®",
  //       rightIcon: InfoIcon,
  //       route: routes.mediaHost.about,
  //     },
  //   ],
  // },
  // {
  //   text: "MediaAcademy®",
  //   subButtons: [
  //     {
  //       text: "Novedades",
  //       rightIcon: NewsIcon,
  //       route: routes.mediaAcademy.news,
  //     },
  //     {
  //       text: "Acerca de MA®",
  //       rightIcon: InfoIcon,
  //       route: routes.mediaAcademy.about,
  //     },
  //   ],
  // },
  // {
  //   text: "MediaCore®",
  //   subButtons: [
  //   ],
  //   module_id:5,
  // },