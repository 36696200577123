import React, { useState, useCallback, useEffect } from "react";
import TableRow from "./TableRow";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "./arrayMove";
import styled from "styled-components";
import {POST_UPDATE_POSITION_ACTION,} from '../../redux/blogsDucks'
import { useDispatch, useSelector } from "react-redux";

const MyTableWrapper = styled.div`
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement(props => <TableRow {...props} />);

const MyTable = (props) => {

  const posts = useSelector(store => store.posts.posts)

  const {tableHeader,tableRow} = props
  const dispatch = useDispatch();
  const [item,setItem] = useState({})

  const [items, setItems] = useState(tableRow);

  useEffect(() => {
    setItems(tableRow)
  }, [posts]);


  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    const newItem = (get_New_Position(items,oldIndex,newIndex))
    dispatch(POST_UPDATE_POSITION_ACTION(newItem[0],newItem[1]))
    setItems(items => arrayMove(item,items, oldIndex, newIndex)); //Reacomodo los items
  });

  const get_New_Position = (array, from, to) => {
    let NewItem = []
      if(to !== 0 && to !== array.length-1){ // No lo envio ni al prinicipio ni al final
        if(to > from){ // Si el new es mayor que el old, es una baja, por lo tanto el item se mueve hacia abajo
          NewItem= ({ ...array[from], position : ((Math.round(array[to].position +array[to+1].position)/2))}) 
        }
        else{ //Si el old es mayor que el new, es una subida, por lo tanto el item se mueve hacia arriba
          NewItem = ({ ...array[from], position : ((Math.round(array[to].position + array[to-1].position)/2))}) //Le resto uno al que estaba en ese lugar
        }
      }
      else if(to === 0){ // Lo envio al principio 
        NewItem = ({ ...array[from], position : (Math.round(array[0].position)+1)}) //Si lo envio al principio, entonces le resto uno al primero
      }
      else if(to === array.length-1){ // Lo envio al final
        NewItem = ({ ...array[from], position : (Math.round(array[array.length-1].position)-1)}) //Si lo envio al principio, entonces le resto uno al primero
      }
    return [NewItem.id,Math.round(NewItem.position)]
  }

  return (
      <MyTableWrapper>
      <table className="table table-light fixed_header">
        <thead>
          <tr>
            {tableHeader.map((item, index) => (
              <th className={index > 1 || item === "Creador" && (props?.investors || props?.members) ? 'text-center' : ''} key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={["30%", "50%"]}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {
            items.sort((a,b) => b.position - a.position).map((value, index) => (
            value.title.toLowerCase().includes(props.searchName.toLowerCase()) &&
            <SortableItem
                key={`item-${index}`}
                index={index}
                value={value}
                investors = {props?.investors}
                members = {props?.members}
                team = {props?.team}
              />
            ))
          }
        </SortableCont>
      </table>
      </MyTableWrapper>
  );
};

export default MyTable;
