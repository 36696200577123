import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, NavLink } from "react-router-dom";
import {CLIENTS_GET_ACTION,USER_LOGIN_ACTION,USER_GET_LOGIN_ACTION,USER_SET_REDIRECTION,USER_RECOVER_PASSWORD} from '../../redux/userDucks'
import {useParams} from 'react-router-dom'
import Footer from '../Footer/Footer';
import { routes } from "../../static_data/routes";


export default function Login() {

    const history = useHistory()
    const dispatch = useDispatch()

    const [email, setEmail] = useState("")

    const response = useSelector(store => store.users.response)

    useEffect(() => {
        if(response.status === 422){
            // toast.error(response.statusText) //Hecho en toastResponse
        }
        if(response.status === 200){
            history.push(routes.session.recoverSend.replace(":email", email))
        }
    } , [response])

    const sendMail = (e) => {
        e.preventDefault()
        dispatch(USER_RECOVER_PASSWORD(email))
    }

  return (
    <div>
        <div className="recover">
            <header className="header d-flex align-items-center">
                
                <div className="brand-wrapper">
                    <NavLink className="brand text-decoration-none" to={routes.base}>
                     MediaCore®
                    </NavLink>
                </div>                
            </header>
            <div className="container-card">
                <div className="card-background">
                    <h1 className="md-font text-mediahaus-1 mb-2 mb-md-5">Recuperar contraseña</h1>
                    <form onSubmit={sendMail} class=" w-100">
                        <div class="d-flex flex-column align-items-start w-100">
                            <label className="mb-2">Email</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" class="login-input common-input w-100 mxw-500 mb-2"></input>
                            {/* <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" class="login-input common-input w-100 mxw-500 mb-2 size-1"></input> */}
                            <button class="blue-button login-button mt-2 mt-md-3 w-100">Enviar Email</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}
