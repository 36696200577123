import React, { useState } from "react";
import Wrapper_1 from "../../Wrapper_1";
import { routes } from "../../../../static_data/routes";
import {CATEGORIES_GET_ACTION} from "../../../../redux/categoryDucks";
import {TAGS_GET_ACTION} from "../../../../redux/tagsDucks";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import {EDITOR_ADD_ACTION} from "../../../../redux/editorsDucks";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';


// Mock data

export default function CreateInvestor() {
  const [active, setActive] = useState(true);
  const history = useHistory()

  const [datos,setDatos] = useState({
    name:"",
    email:"",
    password:"",
    repassword:"",
    phone:"",
    active:"",
    info:"",
    category:[],
    tags:[],
  }) 

  const dispatch = useDispatch();

  const {categories} = useSelector((state) => state.categories);
  const {tags} = useSelector((state) => state.tags);

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaBlog.about,
    },
    {
      text: "MediaBlog®",
      route: routes.mediaBlog.about,
    },
    {
      text: "Editores",
      route: routes.mediaBlog.editors,
    },
    {
      text: "Nuevo editor",
      route: routes.mediaBlog.createEditor,
    },
  ];

  useEffect(() =>  {
    dispatch(CATEGORIES_GET_ACTION());
    dispatch(TAGS_GET_ACTION());
  },[])

  const handleUpdateEditor = (e) => {
    if(e.target.name === 'tags'){
      if(datos.tags.find(element => element === e.target.value)){
          const newCategories = datos.tags.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newCategories, 
          })
          return null
      }
      else{
        setDatos({
            ...datos,
            [e.target.name] : [...datos.tags,e.target.value], 
        })
        return null
      }
  }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }

  const handleCreateEditor = () => {
    if(datos.password !== '' && datos.repassword !== '' && datos.password === datos.repassword){ 
      dispatch(EDITOR_ADD_ACTION(datos))
    }
    else{
      toast.error("La contraseñas deben coincidir.", { duration: 4000, position: 'top-center'})
    }
  }

  const response = useSelector(store => store.editors.response)

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  useEffect(() => {
    if(response.success){
      history.push("/app/media-blog/editores")
    }
  },[response])

  useEffect(() => {
    handleUpdateEditor({target:{name:'active',value:active}})
  },[active])

  return (
    <Wrapper_1 title="Nuevo Editor" breadcrumbItems={breadcrumbItems}>
      <div className="content-mobile-padding w-100p">
        <div className="mi-grid-create-investor">
          <form className="grid-2-col mb-1r" autocomplete="off">
            <div className="d-flex flex-column ">
              <label className="font-1">Nombre*</label>
              <input className="common-input" autocomplete="on" onChange={handleUpdateEditor} name="name" type="text" />
            </div>

            <div className="d-flex flex-column ">
              <label className="font-1">Email*</label>
              <input className="common-input" autocomplete="off" onChange={handleUpdateEditor} name="email" type="text" />
            </div>

            <div className="d-flex flex-column">
              <label className="font-1">Contraseña*</label>
              <input className="common-input" autocomplete="off" onChange={handleUpdateEditor} name="password" type="password" />
            </div>

            <div className="d-flex flex-column">
              <label className="font-1">Repetir Contraseña*</label>
              <input className="common-input" autocomplete="off" onChange={handleUpdateEditor} name="repassword" type="password" />
            </div>

            <div className="d-flex flex-column ">
              <label className="font-1">Teléfono/WhatsApp</label>
              <input className="common-input" autocomplete="off" onChange={handleUpdateEditor} name="phone" type="text" />
            </div>

            <div className="m-0 p-0"></div>
          </form>

          <div className="d-flex flex-column mb-1r">
            <label className="font-1">Permisos</label>
            <div className="grid-checkboxes w-100p mt-1r gap-4">
            {tags?.filter(element => element?.categories?.find(element => element.id === getCategory("blog"))).map((group, i) => (
                <div className="d-flex">
                  <input
                    type="checkbox"
                    onClick={handleUpdateEditor}
                    className="checkbox me-1"
                    name="tags"
                    value={group.id}
                  />
                  <label className={`small-font  `}>
                    {group.name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex flex-column w-100p">
            <label className="font-1">Información Extra</label>
            <textarea name="info" onChange={handleUpdateEditor} autocomplete="off" className="common-input h-6r" />
          </div>
        </div>

        <div className="new-ticket-inputs-2 mt-2r">
          <label className="font-1">Activo</label>
          <div className="mt-2 mb-4 d-flex">
            <span
              className={`font-1 small-font active-button-${
                active ? "black" : "gray"
              }`}
              onClick={() => {
                setActive((a) => true);
              }}
            >
              Si
            </span>
            <span
              className={`font-1 small-font active-button-${
                active ? "gray" : "black"
              }`}
              onClick={() => {
                setActive((a) => false);
              }}
            >
              No
            </span>
          </div>
        </div>
        <p>*Obligatorio</p>
        <button onClick={() => handleCreateEditor()} className="blue-button mt-1r">Guardar</button>
      </div>
    </Wrapper_1>
  );

}