import React, {useEffect, useState} from "react";
import CardNew from "./CardNew";
import Wrapper_2 from "../../Wrapper_2";

import {useDispatch,useSelector} from 'react-redux'
import { CATEGORIES_GET_ACTION} from '../../../../redux/categoryDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { POSTS_GET_ACTION,CLEAR_POST_ACTION,CLEAR_POST_PRIVATE_ACTION} from '../../../../redux/blogsDucks'

import { useLocation } from "react-router";
import { routes } from "../../../../static_data/routes";
import Loading from '../../../Globals/Loading'


export default function News() {
  const breadcrumbItems = [
    {
      text: "Inversores",
      route: routes.mediaInvestor.investor,
    },
    {
      text: "Novedades",
      route:routes.mediaInvestor.investor,
    },
  ];

  const dispatch = useDispatch()
  const tags = useSelector(store => store.tags.tags)
  const categories = useSelector(store => store.categories.categories)
  const posts = useSelector(store => store.posts.posts)
  const loading = useSelector(store => store.posts.loading)

  const location = useLocation()

  const [activeTags,setActiveTags] = useState([])

  useEffect(() => {
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    dispatch(POSTS_GET_ACTION())
    dispatch(CLEAR_POST_ACTION())
    dispatch(CLEAR_POST_PRIVATE_ACTION())
    getCurrentView()
  },[])

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  const toggleActive = (tag) => {
    const finder = activeTags.find(element => element.id.toString() === tag.id.toString())

    if(finder){
      setActiveTags(activeTags.filter(element => element.id.toString() !== tag.id.toString()))
    }
    else{
      setActiveTags([...activeTags,tag])
    }
  }

  const getBlogForTag = (blog,tags) => {
    if(activeTags.length === 0){return true}
    for(let i = 0 ; i < blog.tags.length ; i++){
      for(let j = 0 ; j < tags.length ; j++){
        if(blog.tags[i].id === tags[j].id){
          //Validar si el tag ademas de estar activo, lo tiene el usuario.
          return true;
        }
      }
    }
    return false;
  }

  const getCurrentView = () => {
    console.log(location)
  }

  const getBlogs = (blogs) => {
    let filters_blogs = []
    
    for (const iterator of blogs) {
      if(iterator.categories.find(element => element.id === getCategory("blog")) && getBlogForTag(iterator,activeTags)){
        if (iterator.active === true || iterator.active.toString() === 'true'){
          filters_blogs.push(iterator)
        }
      }
    }
    return filters_blogs;
  }

  return (
    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Novedades">
      <div className="content-mobile-padding">
        <div className="div-search w-100 hover-border-blue-1">
          <div className="contenedor-tags">
            {activeTags.map(element => 
              <span className="span-tag span-active"> {element.name} <i onClick={() => toggleActive(element)}>x</i> </span>
            )}
          </div>
          <div className="div-icon-search">
           <i className="icon-search-icon icon-big icon-body"></i>
          </div>
        </div>
        <div className="div-tags mb-4 mt-4 ">
        {tags?.filter(element => element?.categories?.find(element => element.id === getCategory("blog"))).map((cat, i) => (
            activeTags.find(element => element.id === cat.id) ?
            '':
            <span className="span-tag" onClick={() => toggleActive(cat)}> {cat.name}</span>
        ))}
        </div>
        <hr className="mt-0 mb-2r"/>
        {
        loading ? 
          <Loading /> 
          :
          <div className="ma-news">
            <div className="ma-news-grid">
              {getBlogs(posts).sort((a,b) => b.position - a.position).map((new_, i) => (
                <CardNew key={i} {...new_} isFirst={i === 0} />
              ))}
            </div>

          </div>
        }
      </div>
    </Wrapper_2>
  );
}
