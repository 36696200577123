import React, {useEffect, useState} from "react";
import CardNew from "./CardNew";

import Wrapper_2 from "../../Wrapper_2";
import img1 from "../../../../assets/images/temporal/cafe.jpeg";

import {useDispatch,useSelector} from 'react-redux'
import { CATEGORIES_GET_ACTION} from '../../../../redux/categoryDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { POSTS_GET_ACTION,CLEAR_POST_ACTION,POSTS_USER_GET_ACTION} from '../../../../redux/blogsDucks'

import { routes } from "../../../../static_data/routes";

import Loading from '../../../Globals/Loading'

// Mock data
const news = [
  {
    title: "Novedades para Instagram.",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit diam nibh, feugiat curae in euismod auctor neque lacus ornare, sapien fusce morbi nec sociosqu arcu ultricies suspendisse.",
    date: "5 de Abril 2021",
    image: img1,
  },
  {
    title: "Ultimas tendencias en Marketing Digital.",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "07 Ene 2021",
    image: img1,
  },
  {
    title: "50% del tráfico de internet son videos",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Importancia de las Redes Sociales ",
    description:
      "Avanzamos un 60% con los trabajos de revoque exterior del edificio, abarcando todas las paredes de cada nivel, para el mismo utilizamos hormigón…",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Ultimas novedades para vos.",
    description:
      "Fin de mampostería, Pintura exterior, perfilería, y exteriores.",
    date: "07 Ene 2021",
    image: img1,
  },
  {
    title: "Ultimas tendencias en Marketing Digital.",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "07 Ene 2021",
    image: img1,
  },
  {
    title: "50% del tráfico de internet son videos",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Importancia de las Redes Sociales ",
    description:
      "Avanzamos un 60% con los trabajos de revoque exterior del edificio, abarcando todas las paredes de cada nivel, para el mismo utilizamos hormigón…",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Ultimas novedades para vos.",
    description:
      "Fin de mampostería, Pintura exterior, perfilería, y exteriores.",
    date: "07 Ene 2021",
    image: img1,
  },
];

export default function News() {


  const dispatch = useDispatch()
  const tags = useSelector(store => store.tags.tags)
  const categories = useSelector(store => store.categories.categories)
  const {posts_private} = useSelector(store => store.posts)
  const {posts} = useSelector(store => store.posts)
  const user_logged = useSelector(store => store.users.user_logged)

  const loading = useSelector(store => store.posts.loading)

  const breadcrumbItems = [
    {
      text: "Usuario",
      route:routes.mediaDocs.generalDoc.replaceAll(":cliente",user_logged?.client?.name.replaceAll(" ","-").toLowerCase()),
    },
    {
      text: "Recursos personales",
      route: routes.mediaDocs.memberDoc.replaceAll(":cliente",user_logged?.client?.name.replaceAll(" ","-").toLowerCase()),
    },
  ];

  useEffect(() => {
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    dispatch(POSTS_USER_GET_ACTION(user_logged.id))
    dispatch(CLEAR_POST_ACTION())
  },[])

  const getActives = (blogs) => {
    const actives = blogs.filter(post => post.active)
    return actives
  }

  return (
    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Mis recursos">
      <div className="content-mobile-padding">
        <hr className="mt-0 mb-2r"/>

        {loading ? <Loading /> :
          posts_private.length === 0 ?
            <h3>No hay recursos disponibles.</h3>
          :
            <div className="ma-news">
              <div className="row">
                {getActives(posts_private).map((new_, i) => (
                  <div className="col-lg-3 mb-3">
                    <CardNew key={i} {...new_} isFirst={i === 0} />
                  </div>
                ))}
              </div>
            </div>
        }
      </div>

    </Wrapper_2>
  );
}
