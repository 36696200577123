import React, { useState, useRef, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Breadcrumb from "../../Breadcrumb";
import Wrapper_1 from "../../Wrapper_1";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { POST_ADD_ACTION } from '../../../../redux/blogsDucks'
import { TAGS_GET_ACTION } from '../../../../redux/tagsDucks'
import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'

import { routes } from "../../../../static_data/routes";
import DatePicker from "react-datepicker";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import toast from "react-hot-toast";

const breadcrumbItems = [
  {
    text: "MediaTeam®",
    route: routes.base
  },
  {
    text: "Novedades",
    route: routes.mediaTeam.news,
  },
  {
    text: "Nueva novedad",
    route: routes.mediaTeam.createNew
  },
];

export default function CreateNew() {
  // const inputImage = useRef(null);

  const [sizeInput,setSizeInput] = useState(255);

  const dispatch = useDispatch();
  const response = useSelector(store => store.posts.response)
  const tags = useSelector(store => store.tags.tags)
  const user_logged = useSelector(store => store.users.user_logged)
  const categories = useSelector(store => store.categories.categories)

  const [startDate, setStartDate] = useState(new Date());

  const history = useHistory()

  const [datos, setDatos] = useState({
    title:'',
    user_id:1,
    excerpt:'',
    image_caption:'',
    image:'',
    content:'Contenido de prueba.',
    active:1,
    tags:[],
    featured:0,
    publish_date:new Date()
  })

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };


  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();

  //Files and images
  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    // console.log(picture_profile.current.files[0])
    setDatos({ ...datos, image: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };
  const uploadCallbackFunction = async (file) => {
    const imgData = await uploadInlineImageForArticle(file);
    return Promise.resolve({ data: { 
      link: `${imgData}`
    }});
  }
   const uploadInlineImageForArticle = async (file) => {
    // const headers = await this.getAuthHeader();
    let formData = new FormData();
    formData.append('image', file);
    try {
      const {data} = await axios.post('https://dev.mediacore.com.ar/api/v1/images',formData,{
        headers:{
          'Authorization': 'Bearer ' + user_logged.token
        }
      })
      return data.data.uri;
    } catch (error) {
        console.log(error);
      return null;
    }
  }
  //

  // Functions
  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }
  const handleCreateNew = (e) => {
    e.preventDefault();
    setDatos({
      ...datos,
      user_id : user_logged.id, 
      publish_date:startDate,
      categories:getCategory('team')
    }) 
    if (!datos.image){
      toast('La portada es obligatoria.', { type: 'error' })
    }
    else if (sizeInput - datos.excerpt.length < 0){
      toast('El subtitulo debe tener un máximo de 255 caracteres.', { type: 'error' })
    }
    else if (sizeInput - datos.image_caption.length < 0){
      toast('El pie de imagen debe tener un máximo de 255 caracteres.', { type: 'error' })
    }
    else{
      dispatch(POST_ADD_ACTION(datos));
    }
  }
  const handleUpdateNew = (e) => {
    if(e.target.name === 'tags'){
      if(datos.tags.find(element => element === e.target.value)){
          const newTags = datos.tags.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newTags, 
              categories:getCategory('team')
            })
          return null
      }
      setDatos({
          ...datos,
          [e.target.name] : [...datos.tags,e.target.value], 
          categories:getCategory('team')
      })
      return null
    }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
      categories:getCategory('team')
    })
  }
  const setPublish_date = (date) => {
    setDatos({
      ...datos,
      publish_date:date
    })
  }

  // UseEffects  
  useEffect(() => {
    if(response.success){
      history.push("/app/media-team/novedades")
    }
  },[response])
  
  useEffect(() => {
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    setDatos({
      ...datos,
      user_id : user_logged.id, 
    })
  },[])

  const checkTagUser = (tag,user) => {
    if(user.level === '10' || user.level === '9'){return true}
    return user.tags.find(element => element?.id.toString() === tag?.id?.toString())
  }

  return tags ? (
    <Wrapper_1 breadcrumbItems={breadcrumbItems} title="Nueva novedad">
      <form onSubmit={handleCreateNew} className="content-mobile-padding">
        <div className="mb-input-grid">
          <div className="d-flex flex-column ">
            <label className="font-1">Título *</label>
            <input
              className="common-input"
              type="text"
              name="title"
              placeholder="Título"
              onChange={handleUpdateNew}
            />
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Subtítulo *</label>
            <input
              className={"common-input " + (sizeInput - datos.excerpt.length < 0 ? "border-danger" : "")}
              type="text"
              name="excerpt"
              placeholder="Subtítulo"
              onChange={handleUpdateNew}
            />
            <label className={"mt-2 " + ((sizeInput - datos.excerpt.length) > 100 && ' d-none ') + ((sizeInput - datos.excerpt.length) < 0 && ' text-danger ')}>
              {(sizeInput - datos.excerpt.length) + " caracteres disponibles"}
            </label>
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Fecha de la novedad: </label>
            <div className="d-flex input align-items-center">
            <DatePicker dateFormat="dd/MM/yyyy" selected={datos.publish_date} onChange={(date) => setPublish_date(date)}  locale="es" />
            <i className="icon-calendar icon-regular icon-body"></i>
            </div>
          </div>
          <div className="d-flex flex-column ">
            {/* <FileInput label="Logo" containerClassName="" className="py-3" /> */}
            <div class="content-img">
              <label for="addFile" className="font-1">Subir portada *</label>  
                <div className={"drag mb-4 uploader w-100 d-flex " + drag}
                  dragleave={onDragLeave}
                  dragenter={onDragEnter}
                  onDrop={onDrop}
                 >
                  <div className={`d-flex flex-column w-100`}>
                    <span for="addFile" className={`image-picker-button small-font `}>
                      <label for="addFile">
                        AGREGAR IMAGEN (JPG, PNG 1200x900)
                      </label>
                    </span>
                    <input
                      type="file"
                      name="addFile"
                      className="d-none"
                      ref={picture_profile}
                      onChange={changeFile}
                      id="addFile"
                    />
                  </div>
                  <img
                    className={"mt-0 me-0 ms-3 d-inline-block " + (renderImage ? '' : 'd-none')}
                    src={renderImage ? renderImage : ''}
                    width="150"
                    style={{objectFit:"cover"}}
                  />{" "}
                </div>
              </div>
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Pié de imagen principal</label>
            <input
              className={"common-input " + (sizeInput - datos.image_caption.length < 0 ? "border-danger" : "")}
              type="text"
              name="image_caption"
              onChange={handleUpdateNew}
            />
            <label className={"mt-2 " + ((sizeInput - datos.image_caption.length) > 100 && ' d-none ') + ((sizeInput - datos.image_caption.length) < 0 && ' text-danger')}>
              {(sizeInput - datos.image_caption.length) + " caracteres disponibles"}
            </label>
          </div>
          <div className="d-flex flex-column">
            <label className="font-1">Etiqueta</label>
            <div className="grid-checkboxes">
              {tags ? tags.filter(element => checkTagUser(element,user_logged) && element.categories.find(element => element.id === getCategory("blog"))).map((filter, i) => (
                <div className="d-flex">
                <input
                  type="checkbox"
                  onClick={handleUpdateNew}
                  className="checkbox me-1"
                  name="tags"
                  value={filter.id}
                />
                <label className={`small-font  `}>
                  {filter.name}
                </label>
              </div>
              )):''}
            </div>
          </div>

          <div className="d-flex flex-column mt-3 mb-4">
            <label className="font-1">Descripción</label>
            <div>
                <Editor
                  editorState={editorState}
                  wrapperClassName="card"
                  editorClassName="card-body"
                  onEditorStateChange={newState => {
                      setEditorState(newState);
                      handleUpdateNew({target:{name:"content",value:(draftToHtml(convertToRaw(newState.getCurrentContent())))}});;
                  }}
                  toolbar={{
                      options: ['inline', 'blockType', 'list','link', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                      inline: { 
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                      blockType: {
                        inDropdown: false,
                        options: ['H1', 'H2', 'H3'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      image: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: true,
                        uploadCallback:uploadCallbackFunction,
                        previewImage: true,
                        inputAccept: 'image/jpeg,image/jpg,image/png',
                        defaultSize: {
                          height: 'auto',
                          width: '100%',
                        },
                      },
                  }}
              />
            </div>
          </div>
        </div>

        <div className="new-ticket-inputs-2">
        {( user_logged.level === '9' || user_logged.level === '10' )
          && 
          <>
            <label className="font-1">Activo</label>
            <div className="mt-2 mb-4 d-flex">
              <span
                className={`font-1 small-font active-button-${
                  (datos.active !== 1 ? "gray" : "black")
                }`}
                onClick={() => {
                  handleUpdateNew({target:{name:"active",value:1}});
                }}
              >
                Si
              </span>
              <span
                className={`font-1 small-font active-button-${
                  (datos.active !== 0 ? "gray" : "black")
                }`}
                onClick={() => {
                  handleUpdateNew({target:{name:"active",value:0}});
                }}
              >
                No
              </span>
            </div>
          </>
        }
          <button className="blue-button mt-3">Guardar</button>
        </div>
      </form>
    </Wrapper_1>
  ):'';
}