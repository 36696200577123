import axios from 'axios'
import { ErrorIcon } from 'react-hot-toast'

// Constantes
const dataInicial = {
    clients:[],
    client:{},
    loading:true,
    client_types:[],
    response:{},
    modules:[]
}

// Types
const LOADING = 'LOADING'
const GET_CLIENTS = 'GET_CLIENTS'
const GET_CLIENT = 'GET_CLIENT'
const ADD_CLIENT = 'ADD_CLIENT'
const UPDATE_CLIENTE = 'UPDATE_CLIENTE'
const UPDATE_LOGO_CLIENT = 'UPDATE_LOGO_CLIENT'
const DELETE_CLIENT = 'DELETE_CLIENT'

const GET_MODULES = 'GET_MODULES'
const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR'
const CLEAR_STATUS = 'CLEAR_STATUS'


const GET_CLIENT_TYPES = 'GET_CLIENT_TYPES'

// Reducer
export default function clientReducer(state = dataInicial, action){
    switch(action.type){
        case GET_MODULES:{ //Get all clients
            return {
                ...state, modules : action.payload.data.data.modules.data,
            }
        }
        case GET_CLIENTS:{ //Get all clients
            return {
                ...state, clients : action.payload.data.data.clients.data,
                loading:false 
            }
        }
        case GET_CLIENT_TYPES: {//Get all clients
            return {
                ...state, client_types : action.payload.data.data.clients.data,
                loading:false 
            }
        }
        case GET_CLIENT: {// Get details client
            return {
                ...state, client : action.payload,
                loading:false 
            }
        }
        case UPDATE_CLIENTE:{ // Update
            return {...state, 
                clients: [...state.clients.filter(client => client.id !== action.payload.data.data.client.id),action.payload.data.data.client], 
                response:{
                    status:action.payload.status,
                    statusText:["El cliente fue actualizado correctamente."],
                    success:true,
                },
            }
        }
        case ADD_CLIENT:{ // Add
            return {...state, clients: [...state.clients,action.payload.data.data.client],                
                response:{
                    status:action.payload.status,
                    statusText:["El cliente fue creado correctamente."],
                    success:true
                },
                loading:false
            }
        }
        case DELETE_CLIENT:{ // Delete
            return {...state, clients: [...state.clients.filter((client) => client.id !== action.payload.data.data.client.id)],                
                response:{
                    status:action.payload.status,
                    statusText:["El cliente fue eliminado correctamente."],
                    success:true
                },
                loading:false
            }
        }
        case GET_CLIENTS_ERROR: { // Error
            return {...state, response : action.payload,  loading:false }
        }
        case LOADING: {
            return {...state, loading : action.payload, response:{}}
        }
        case CLEAR_STATUS:{ // Clear
            return {...state, response:{}}
        }
        case UPDATE_LOGO_CLIENT:{
            return {...state, clients: [...state.clients,action.payload.data.data.client],                
                response:{
                    status:action.payload.status,
                    statusText:["El logo fue actualizado correctamente."],
                    success:true,
                    noreload:true,
                },
                loading:false
            }
        }
        default:
            return state;
    }
}
// Actions
export const CLEAR_CLIENTS_STATUS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_STATUS})
}
export const CLIENTS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/clients?limit=1000',{
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token_id')
            },
        })
        dispatch({type: GET_CLIENTS,payload: res})
    } catch (error) {
        dispatch({type: GET_CLIENTS_ERROR,payload:error.response})
        console.log(error)
    }
}
export const MODULES_GET_ACTION = () => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/modules?limit=20',{
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token_id')
            },
        })
        dispatch({type: GET_MODULES,payload: res})
    } catch (error) {
        // dispatch({type: GET_CLIENTS_ERROR,payload:error.response})
    }
}
export const CLIENT_GET_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/clients/'+ id,{
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token_id')
            },
        })
        dispatch({
            type: GET_CLIENT,
            payload: res
        })
    } catch (error) {
        dispatch({type: GET_CLIENTS_ERROR,payload:error.response})
    }
}
export const CLIENT_ADD_ACTION = (data) => async (dispatch, getState) => {
    
    dispatch({type: LOADING,payload: true})
    
    var dataSend = new FormData();
    dataSend.append("name", data.name);
    dataSend.append("client_type_id", data.client_type_id);
    dataSend.append("email", data.email);
    dataSend.append("phone", data.phone);
    dataSend.append("description", data.description);
    dataSend.append("logo", data.logo);
    dataSend.append("main_color", data.main_color);
    dataSend.append("active", data.active);
    for (const module of data.modules) {
        dataSend.append("modules[]",module);
    }
    dataSend.append("ga_client_id", data.ga_client_id);
    dataSend.append("domain_name", data.domain_name);
    dataSend.append("domain_extension", data.domain_extension);
    dataSend.append("domain_expiration_date", data.domain_expiration_date.getFullYear() + '-' + (data.domain_expiration_date.getMonth() + 1) + '-' + data.domain_expiration_date.getDate());
    dataSend.append("ssl_active", data.ssl_active);
    dataSend.append("tokko_apikey", data.tokko_apikey);
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/clients',dataSend,{
            headers:{
                "Authorization": 'Bearer ' + localStorage.getItem('token_id'),
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        console.log(res)
        dispatch({
            type: ADD_CLIENT,
            payload: res
        })
    } catch (error) {
        let errors_texts = []
        console.log(error.response)
        if(error.response){
            for (const iterator of Object.values(error.response.data.errors)) {
                for (const text of iterator){
                    errors_texts.push(text)
                }
            }
        }
        else{
            errors_texts.push("Ocurrió un error.")
        }

        dispatch(
            {type: GET_CLIENTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const CLIENT_DELETE_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/clients/'+id,{
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token_id')
            },
        })
        dispatch({
            type: DELETE_CLIENT,
            payload: res
        })
    } catch (error) {
        dispatch(
            {type: GET_CLIENTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:["Ocurrió un error."],
                success:false
            }
        })
        console.log(error.response)
    }
}
export const CLIENT_UPDATE_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    let response = {}
    response.name = data.name;
    response.phone = data.phone;
    response.description = data.description;
    response.client_type_id = data.client_type_id;
    response.main_color = data.main_color;
    response.modules = data.modules;
    response.active = data.active;    
    response.ga_client_id =  data.ga_client_id;
    response.domain_name =  data.domain_name;
    response.domain_extension =  data.domain_extension;
    response.ssl_active =  data.ssl_active;
    response.domain_expiration_date = data.domain_expiration_date.getFullYear() + '-' + (data.domain_expiration_date.getMonth() + 1) + '-' + data.domain_expiration_date.getDate();
    response.tokko_apikey =  data.tokko_apikey;
    if(getState().clients.client.data.data.client.email !== data.email) {
        response.email = data.email ;
    }

    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/clients/'+id,response,{
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token_id')
            },
        })
        console.log(res)
        dispatch({
            type: UPDATE_CLIENTE,
            payload: res,
        })
    } catch (error) {
        console.log(error)
        console.log(error.response)
        // dispatch(
        //     {
        //         type: GET_CLIENTS_ERROR,
        //         payload:{
        //             // status:'Error',
        //             statusText:'Error',
        //             success:false,
        //         }
        //     })
    }
}
export const CLIENT_UPDATE_LOGO_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    var dataSend = new FormData();
    dataSend.append("logo", data);
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/clients/'+id+'/logo',dataSend,{
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token_id'),
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        dispatch({
            type: UPDATE_LOGO_CLIENT,
            payload: res
        })
    } catch (error) {
        dispatch(
            {type: GET_CLIENTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:error.response.statusText,
                success:false
            }
        })
        console.log(error)
    }
}
export const CLIENTS_TYPE_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/client-types?limit=20',{
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token_id')
            },
        })
        dispatch({type: GET_CLIENT_TYPES,payload: res})
    } catch (error) {
        dispatch({type: GET_CLIENTS_ERROR,payload:error.response})
        console.log(error)
    }
}
