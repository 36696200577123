import { routes } from "../../static_data/routes";
import {Route,Redirect,useLocation} from 'react-router-dom'

import {useSelector, useDispatch} from 'react-redux'
import {USER_LOGIN_ACTION,USER_GET_LOGIN_ACTION,USER_SET_REDIRECTION} from '../../redux/userDucks'
import Loading from '../Globals/Loading'
import {useEffect} from 'react'

const PrivateRoute = props => {

    const api_key = localStorage.getItem('token_id')
    const user_logged = useSelector(store => store.users.user_logged)
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if(user_logged.token){
            if(user_logged.client.modules.length === 0){return ( <Redirect to={routes.welcome.welcome} />)}
            return <Route {...props} /> 
        }
    },[user_logged])

    if(api_key){
        if(!user_logged.token){
            dispatch(USER_GET_LOGIN_ACTION())
        }
        else{
            return <Route {...props} />
        }
    }
    dispatch(USER_SET_REDIRECTION(location.pathname))
    return ( <Redirect to={routes.session.login} />)
}

export default PrivateRoute