import React, { useState, useRef, useEffect } from "react";
import Wrapper_1 from "../Wrapper_1";
import { EditorState, onvertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DateTimeInput from '../../Globals/DateTimeInput'
import toast, { Toaster } from 'react-hot-toast';

import {CLIENT_UPDATE_ACTION} from '../../../redux/userDucks'
import {INVESTOR_UPDATE_BACKGROUND_ACTION,INVESTOR_UPDATE_LOGO_ACTION} from '../../../redux/investorsDucks'
import { routes } from "../../../static_data/routes";
import { useSelector, useDispatch } from "react-redux";

const breadcrumbItems = [
  {
    text: "MediaCore®",
    route: routes.mediaInvestor.about,
  },
  {
    text: "MediaInvestors®",
    route: routes.mediaInvestor.about
  },
  {
    text: "Configuración",
    route: routes.mediaInvestor.config
  },
];

// Mock data
const filters = [
  "Arquitectura sustentable",
  "Apto Crédito",
  "Departamentos",
  "Desarrollos",
  "Emprendimientos",
  "Multiuso",
];

export default function Config() {

  const user_logged = useSelector(store => store.users.user_logged)

  const dispatch = useDispatch()

  const refInputUrl = useRef()

  const inputImageBackground = useRef(null);
  const inputImageLogo = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const [datos, setDatos] = useState({
    investors_logo:'',
    investors_background:'',
    investors_background_color:'',
    investors_primary_color:'',
  })

  const [drag, setDrag] = useState(false);
  
  
  // Background
  const picture_profile = useRef();
  const [imgToUploadBackground, setImgToUploadBackground] = useState();
  const [renderImageBackground,setRenderImageBackground] = useState();
  const changeFile = () => {

    const image = picture_profile.current.files[0];
    setImgToUploadBackground(image);
    setDatos({ ...datos, investors_background: picture_profile.current.files[0] });
    loadImageBackground(image);
  };

  const loadImageBackground = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImageBackground(e.target.result)
    };
    reader.readAsDataURL(file);
  };

  // Logo
  const logo_profile = useRef();
  const [imgToUploadLogo, setImgToUploadLogo] = useState();
  const [renderImageLogo,setRenderImageLogo] = useState();

  const changeFileLogo = () => {
    const imageLogo = logo_profile.current.files[0];
    setImgToUploadLogo(imageLogo);
    setDatos({ ...datos, investors_logo: logo_profile.current.files[0] });
    loadImageLogo(imageLogo);
  };
  
  const loadImageLogo = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImageLogo(e.target.result)
    };
    reader.readAsDataURL(file);
  };

  const handleUpdateClient = (e) => {
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }

 

  useEffect(() => {
    setDatos({
      investors_logo:user_logged?.client?.investors_logo,
      investors_background:user_logged?.client?.investors_background,
      investors_background_color:user_logged?.client?.investors_background_color ,
      investors_primary_color:user_logged?.client?.investors_primary_color,
    })
  },[user_logged])

  useEffect(() => {
    if(datos?.investors_background){
      dispatch(INVESTOR_UPDATE_BACKGROUND_ACTION(user_logged?.client.id,imgToUploadBackground))
    }
  },[imgToUploadBackground])

  useEffect(() => {
    if(datos?.investors_logo){
      dispatch(INVESTOR_UPDATE_LOGO_ACTION(user_logged?.client.id,imgToUploadLogo))
    }
  },[imgToUploadLogo])
  return (
    <Wrapper_1 breadcrumbItems={breadcrumbItems} title="Configuración">
      <div className="content-mobile-padding w-100">
        <div className="row">
          <div className="col-6">
              <div class="content-img">
              <label for="addFile" className="font-1">Imagen de fondo</label>  
                <div className={"drag mb-4 uploader w-100 d-flex " + drag}
                 >
                  <div className={`d-flex flex-column w-100`}>
                    <span for="addFile" className={`image-picker-button small-font `}>
                      <label style={{color:"#d6d6d6"}} for="addFile">
                        IMAGEN DE FONDO (JPG, PNG 1200x1200)
                      </label>
                    </span>
                    <input
                      type="file"
                      name="addFile"
                      className="d-none"
                      ref={picture_profile}
                      onChange={changeFile}
                      id="addFile"
                    />
                  </div>
                  <img
                    className={"mx-0 mt-0 ms-2 " +( renderImageBackground ? renderImageBackground : datos.investors_background ? datos.investors_background : ' d-none')}
                    src={renderImageBackground ? renderImageBackground : datos.investors_background ? "https://dev.mediacore.com.ar" + datos.investors_background : ''}
                    width="150"
                  />{" "}
                </div>
              </div>
          </div>
          <div className="col-6">
              <div class="content-img">
              <label for="addFileLogo" className="font-1">Logo</label>  
                <div className={"drag mb-4 uploader w-100 d-flex " + drag}
                 >
                  <div className={`d-flex flex-column w-100`}>
                    <span for="addFileLogo" className={`image-picker-button small-font `}>
                      <label style={{color:"#d6d6d6"}} for="addFileLogo">
                      AGREGAR IMAGEN (PNG CON TRANSPARENCIA)
                      </label>
                    </span>
                    <input
                      type="file"
                      name="addFileLogo"
                      className="d-none"
                      ref={logo_profile}
                      onChange={changeFileLogo}
                      id="addFileLogo"
                    />
                  </div>
                  <img
                    className={"mx-0 mt-0 ms-2 " +( renderImageLogo ? renderImageLogo : datos.investors_logo ? datos.investors_logo : ' d-none')}
                    src={renderImageLogo ? renderImageLogo : datos.investors_logo ? "https://dev.mediacore.com.ar" + datos.investors_logo : ''}
                    width="150"
                  />{" "}
                </div>
              </div>
          </div>
    
          <div className="col-6 mt-3">
            <div className="d-flex flex-column">
                <label className="font-1">Color de fondo</label>
                <div className="position-relative w-100">
                  <input type="text" className="common-input w-100" value={datos.investors_background_color} onChange={(e)=>handleUpdateClient({target:{name:"investors_background_color",value:e.target.value}})} />
                  <input
                    className="w-100s position-absolute me-2"
                    style={{
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                      border: "none",
                    }}
                    value={datos.investors_background_color}
                    name="investors_background_color"
                    onChange={(e)=>handleUpdateClient({target:{name:"investors_background_color",value:e.target.value}})}
                    type="color"
                  />
                </div>
            </div>   
          </div>   
          <div className="col-6 mt-3">
          <div className="d-flex flex-column">
              <label className="font-1">Color primario</label>
              <div className="position-relative w-100">
                <input type="text" className="common-input w-100" value={datos.investors_primary_color} onChange={(e)=>handleUpdateClient({target:{name:"investors_primary_color",value:e.target.value}})} />
                <input
                  className="w-100s position-absolute me-2"
                  style={{
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    border: "none",
                  }}
                  value={datos.investors_primary_color}
                  name="investors_primary_color"
                  onChange={(e)=>handleUpdateClient({target:{name:"investors_primary_color",value:e.target.value}})}
                  type="color"
                />
              </div>
            </div>
          </div>   
          {/* <div className="col-6 mt-5">
            <div className="d-flex flex-column">
              <label className="font-1">La URL de ingreso para los inversores es:</label>
              <div className="position-relative w-100">
                <input type="text" ref={refInputUrl} id="url_login" className="common-input w-100" value={"https://www.mediacore.com.ar/app/" + user_logged?.client?.name?.toLowerCase().replaceAll(" ","-")} disabled />
              </div>
            </div>
          </div>    */}
        </div>
      </div>
      {/* <p className="mt-5 ps-1">La URL de ingreso para los inversores es: <a href={"http://www.mediacore.com.ar/app/" + user_logged?.client?.name?.toLowerCase().replaceAll(" ","-")} target="_blank" rel="noopener noreferrer">{"http://www.mediacore.com.ar/app/" + user_logged?.client?.name?.toLowerCase().replaceAll(" ","-")}</a></p> */}
      <div className="button-copy d-flex align-items-center">
        La URL de ingreso para los inversores es: <div onClick={() => navigator.clipboard.writeText("https://www.mediacore.com.ar/app/" + user_logged?.client?.name?.toLowerCase().replaceAll(" ","-")) + toast.success("Enlace copiado correctamente", { duration: 4000, position: 'top-center'})}> {"https://www.mediacore.com.ar/app/" + user_logged?.client?.name?.toLowerCase().replaceAll(" ","-")}</div>
        <button onClick={() => navigator.clipboard.writeText("https://www.mediacore.com.ar/app/" + user_logged?.client?.name?.toLowerCase().replaceAll(" ","-")) + toast.success("Enlace copiado correctamente", { duration: 4000, position: 'top-center'})} className="blue-button ms-3"><i className="icon-copy"></i></button>
      </div>
      <button onClick={() => dispatch(CLIENT_UPDATE_ACTION({
            investors_background_color:datos.investors_background_color,
            investors_primary_color:datos.investors_primary_color,
      },user_logged.client.id))} className="blue-button px-4 mt-4">Guardar</button>
    </Wrapper_1>
  );
}

function Checkbox({ is = false, text }) {
  const [checked, setChecked] = useState(is);

  return (
    <div className="d-flex">
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
        className="checkbox me-1"
      />
      <label className={`small-font  ${checked ? "font-0" : "font-1"}`}>
        {text}
      </label>
    </div>
  );
}
