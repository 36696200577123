import React, { useEffect, useState } from "react";
import Wrapper_2 from "../../Wrapper_2";
import SearchInput from "../../../Globals/SearchInput";
import Table from "../../Table";

import { routes } from "../../../../static_data/routes";

import {useDispatch, useSelector} from "react-redux";
import {INVESTORS_GET_ACTION} from "../../../../redux/investorsDucks";
import DeleteModalInvestor from './DeleteModalInvestor'
import Loading from '../../../Globals/Loading'

import { useHistory,useParams } from "react-router-dom";

export default function Investors() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(INVESTORS_GET_ACTION());
  },[])


  const investors = useSelector(store => store.investors.investors)
  const loading_investors = useSelector(store => store.investors.loading_investors)

  const user_logged = useSelector(store => store.users.user_logged)

  const [searcher,setSearcher] = useState("")

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "MediaInvestor®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "Inversores",
      route: routes.mediaInvestor.investors,
    },
  ];

  return (
    <Wrapper_2
      title="Inversores"
      breadcrumbItems={breadcrumbItems}
      rightLink={{
        route: routes.mediaInvestor.createInvestor,
        text: "Nuevo Inversor",
        icon: "addIcon",
      }}
    >
      <div className="content-mobile-padding w-100p">
        <SearchInput setSearcher={setSearcher} placeholder="Buscar" className="w-50p mb-2r" />
        <div className="tables-wrapper">
          {loading_investors ? 
          <Loading /> 
          :
          <InvestorsTable searcher={searcher} investors={investors.filter(element => element.client_id.toString() === user_logged.client.id.toString() && element.level === "1")} />
          }
        </div>
      </div>
    </Wrapper_2>
  );
}

function InvestorsTable({ searcher,investors }) {

  const user_logged = useSelector(store => store.users.user_logged)

  const history = useHistory()

  const getTime_text = (time) => {
    const spliter = time.split('T');

    const fecha = spliter[0].split('-');

    const hora_spliter = spliter[1].split('.');
    const hora = hora_spliter[0].split(':')

    // const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
    const date_time = fecha[2] + "/"+ fecha[1] + "/" + fecha[0] + " " + hora[0] + ":" + hora[1] + ":" + hora[2];
    return date_time;
  }

  return (
    <Table
      TitleButton={``}
      TableHeader={
        <tr>
          <th className="width-100">Usuario</th>
          <th>Email</th>
          <th>Registrado</th>
          <th>Acciones</th>
        </tr>
      }
      TableRows={investors.sort((a,b) => b.active - a.active).map((investor, i) => (
        investor.name.includes(searcher) || investor.email.includes(searcher) ?
        <tr className={(investor.active === false || investor.active === "false" ? 'opacity-self' : '')} key={i}>
          <td className={"d-lg-flex align-items-center width-custom-self "}>
            <img
              src={process.env.REACT_APP_BASE_URL + (investor.profile_image ? investor.profile_image : "/storage/no-profile.jpg")}
              alt="creador de la novedad"
              className="me-2 d-block"
              width="35px"
              style={{ borderRadius: "50%" }}
            />
            {investor.name}
          </td>
          <td>{investor.email}</td>
          <td>{getTime_text(investor.created_at)}</td>
          <td>
            <button className="button-no-styled ">
              <button onClick={() => history.push("inversores/actualizar-inversor/" + investor.id)} style={{border:"none",background:"transparent"}} ><i className="icon-edit-icon me-0 me-lg-3"></i></button>
              <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#investor-"+investor.id}><i className="icon-delete"></i></button>
                <DeleteModalInvestor investor={{id:investor.id,name:investor.name}} />
              </button>
          </td>
        </tr>
        :''
      ))}
    />
  );
}
