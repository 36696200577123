const Loading = (props) => {
    return (
        <>
            <div className={"loading-section " + (props.isAbsolute ? "absolute-loading" : '') + (props.inside ? " inside " : '')}>
                <div class="loader"></div>
                {/* <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
            </div>
        </>
    )
}

export default Loading