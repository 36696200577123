import React, { useState, useEffect, useRef } from "react";
import Wrapper_2 from "../../Wrapper_2";
import Table from "../../Table";
import TableOrder from '../../TableOrder'

import { routes } from "../../../../static_data/routes";
import { useDispatch, useSelector } from "react-redux";
import { POSTS_GET_ACTION, POST_TOGGLE_ACTIVE_ACTION,CLEAR_POST_ACTION } from '../../../../redux/blogsDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'

import ModalDelete from './DeleteModalNew'
import {check_access} from '../../../../functions/accessFunctions'

import Loading from '../../../Globals/Loading'

import '../../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import { Link } from "react-router-dom";



export default function News() {

  const dispatch = useDispatch();
  const [searchName,setSearchName] = useState('')
  const posts = useSelector(store => store.posts.posts)
  const loading = useSelector(store => store.posts.loading)
  const categories = useSelector(store => store.categories.categories)
  const {user_logged} = useSelector(store => store.users)

  const [searchPending,setSearchPending] = useState('')

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  useEffect(() => {
    dispatch(CLEAR_POST_ACTION())
    dispatch(POSTS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())

  },[])

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.base,
    },
    {
      text: "MediaBlog®",
      route: routes.mediaBlog.news,
    },
    {
      text: "Novedades",
      route: routes.mediaBlog.news,
    },
  ];

  const filterNews = (news,bool_active) => {
    const filtered = news.filter((new_) => 
          (user_logged.level === '3' ? new_?.user_id?.toString() === user_logged?.id?.toString() : true)
      &&  (new_.active.toString() === bool_active.toString()) 
      &&  (new_.categories.find(element => element.id === getCategory("blog"))))

    console.log(news)

    if(user_logged.level === '3'){
      return news.filter((new_) => new_.user_id?.toString() === user_logged?.id?.toString() && new_.active.toString() === bool_active.toString() && new_.categories.find(element => element.id === getCategory("blog")))
    }

    return bool_active ? filtered : filtered.filter(element => element.user.level !== '3') // in the case of active = false, we filter the news not actives and that are not created by the editors
  }

  const filterNewsPending = (news) => {
    const filtered = news.filter((new_) => 
          (new_.active.toString() === 'false') 
      &&  (new_.user.level === '3')
      &&  (new_.categories.find(element => element.id === getCategory("blog"))))
    return filtered.filter(element => (element.user.name.includes(searchPending) || element.tags.find(tag => tag.name.includes(searchPending))))
  }

  const getTime_text = (time) => {
    const spliter = time.split('T');

    const fecha = spliter[0].split('-');

    const hora_spliter = spliter[1].split('.');
    const hora = hora_spliter[0].split(':')

    // const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
    const date_time = fecha[2] + "/"+ fecha[1] + "/" + fecha[0] + " " + hora[0] + ":" + hora[1] + ":" + hora[2];

    return date_time;

  }

  const columns = [
    {
    dataField: 'title',
    text: 'Titulo de la novedad',
    sort: true,
    formatter: (cell, row) => {
      return <div className="d-flex align-items-center">
        <div className="title-min-width">{row.title}</div>
        {row.tags.map((element,index) => (
          index < 2 &&
          <div className="d-none d-xxl-flex pill">{element.name}</div>
        ))}
      </div>
    },}, 
    {
      dataField: 'user.name',
      text: 'Creador',
      sort: true,
      formatter: (cell, row) => {
        return(
          <div className="d-flex justify-content-center align-items-center">
          <img
            src={process.env.REACT_APP_BASE_URL + (row.user.profile_image ? row.user.profile_image : "/storage/no-profile.jpg")}
            alt="creador de la novedad"
            className="me-3 d-block"
            width="35px"
            height={"35px"}
            style={{ borderRadius: "50%" }}
          />
          {row.user.name}
        </div>
        )
      }
    }, {
      dataField: 'created_at',
      text: 'Fecha de creación',
      formatter: (cell, row) => {
        return getTime_text(row.created_at)
      },
      sort: true
    
    },
    {
      dataField: 'updated_at',
      text: 'Última modificación',
      formatter: (cell, row) => {
        return getTime_text(row.updated_at)
      },
      sort: true
    
    },
    {
      dataField: 'actions',
      text: 'Acciones',
      formatter: (cell, row) => {
        return (
          <button className={"button-no-styled d-flex align-items-center mx-auto"}>
          {
              <>
                  <Link to={"novedades/actualizar-novedad/" + row.id} ><i className="icon-edit-icon me-0 me-lg-3"></i></Link>
                  <button className="me-2" style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#post-"+row.id}><i className="icon-delete"></i></button>
                  <div class="dropdown show">
                    <a class="" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="icon-three-dots-icon"></i>
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a onClick={() => dispatch(POST_TOGGLE_ACTIVE_ACTION(row,row.id))} class="dropdown-item" href="#">Aprobar</a>
                    </div>
                  </div>
                  {/* <button onClick={() => dispatch(POST_TOGGLE_ACTIVE_ACTION(row,row.id))} className="me-2 mb-1" style={{border:"none",background:"transparent"}} type="button" >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Check_green_circle.svg/2048px-Check_green_circle.svg.png" width={20} alt="" />
                  </button> */}
                  {/* <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#post-duplicate-"+row.id}><i className="icon-copy"></i></button> */}
                  <ModalDelete post={{id:row.id,title:row.title}} />
                  {/* <ModalDuplicate post={row} /> */}
              </>
          }
          
      </button>
    
        )}
  }];

  return posts ? (
    <Wrapper_2
      breadcrumbItems={breadcrumbItems}
      title="Novedades"
      rightLink={{
        text: "Nueva Novedad",
        icon: "addIcon",
        route: routes.mediaBlog.createNew,
      }}
      check="Posts scope for writing records"
    >
      <div className="content-mobile-padding w-100p ">
        <div className="input-search-period w-50p mb-2r hover-border-blue-1">
          <input type="text" placeholder="Palabra clave" onChange={(e) => setSearchName(e.target.value)} className="focus-outline-none" />
          {/* <img src={SearchIcon} alt="buscar periodo" width="15px" /> */}
          <i className="icon-search-icon"></i>
        </div>
        {!loading ? 
          <>
            <div className="tables-wrapper">
              <h3>{user_logged.level === '3' ? 'Aprobadas' : 'Activas'}</h3>
              <NewsTable news={filterNews(posts,true)} searchName={searchName} />
            </div>
            <div className="tables-wrapper mt-5">
              <h3>{user_logged.level === '3' ? 'Pendientes de aprobación' : 'No Activas'}</h3>
              <NewsTable
                news={filterNews(posts,false)}
                isFinished={true}
                searchName={searchName}
              />
            </div>
            {
            user_logged.level === '10' || user_logged.level === '9'
            && <div className="tables-wrapper mt-5">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3>{'Pendientes de aprobación'}</h3>
                    <div className="input-search-period w-40p hover-border-blue-1">
                      <input type="text" placeholder="Palabra clave" onChange={(e) => setSearchPending(e.target.value)} className="focus-outline-none" />
                      <i className="icon-search-icon"></i>
                    </div>
                  </div>
                  <BootstrapTable keyField='id' data={filterNewsPending(posts)} columns={ columns } />
                </div>
             }
          </>
        :<Loading /> }
        {/* */}
      </div>
    </Wrapper_2>
  ):'';
}

function NewsTable({ news, isFinished = false, searchName = '' }) {

  const posts = useSelector(store => store.posts.posts)

  const [newFilter,setNewFilter] = useState()

  useEffect(() => {
    setNewFilter(news)
  })

  return (
    <>
      <TableOrder 
      tableHeader = {["Posición",'Título de la novedad','Novedad destacada','Creador','Fecha de creación','Última modificación','Acciones']}
      tableRow = {news ? news.filter((new_) => new_.title.toLowerCase().includes(searchName.toLowerCase())) : 'A'}
      searchName = {searchName} 
       />
    </>
  );
}
