import axios from 'axios'

// Constantes
const dataInicial = {
    editors:[],
    editor:{},
    developments:[],
    development:{},
    tags:[],
    loading_editors:false,
    loading_absolute:false,
    response:{},
}

// Types
const LOADING_EDITORS = 'LOADING_EDITORS'
const LOADING = 'LOADING'
const LOADING_EDITORS_ABSOLUTE = 'LOADING_EDITORS_ABSOLUTE'
const GET_EDITORS = 'GET_EDITORS'
const GET_EDITOR = 'GET_EDITOR'
const ADD_EDITOR = 'ADD_EDITOR'
const UPDATE_EDITOR = 'UPDATE_EDITOR'
const DELETE_EDITOR = 'DELETE_EDITOR'

const GET_EDITORS_ERROR = 'GET_EDITORS_ERROR'
const CLEAR_STATUS = 'CLEAR_STATUS'

const CLEAR_EDITOR = 'CLEAR_EDITOR'

// Reducer
export default function editorReducer(state = dataInicial, action){
    switch(action.type){
        case CLEAR_STATUS:{ //Clear status
            return {
                ...state, response : {},
            }
        }
        case CLEAR_EDITOR:{ //Clear editor
            return {
                ...state, editor : {},
            }
        }
        case LOADING_EDITORS:{ //Loading
            return {
                ...state, loading_editors : true,
            }
        }
        case LOADING_EDITORS_ABSOLUTE:{ //Loading
            return {
                ...state, loading_absolute : true,
            }
        }
        case GET_EDITORS:{ //Get all editor
            return {
                ...state, editors : action.payload,
                loading_editors:false 
            }
        }
        case GET_EDITOR: {// Get details client
            return {
                ...state, editor : action.payload,
                loading_editors:false 
            }
        }
        case ADD_EDITOR:{ // Add editor
            return {...state, editors: [...state.editors,action.payload.data.data.user],                
                response:{
                    status:action.payload.status,
                    statusText:"El editor fue creado correctamente.",
                    success:true
                },
                loading_editors:false,
                loading_absolute:false,
            }
        }
        case UPDATE_EDITOR:{ // Update 
            return {...state,
                editors: [...state.editors.filter(editor => editor.id !== action.payload.data.data.user.id),action.payload.data.data.user], 
                response:{
                    status:action.payload.status,
                    statusText:"El editor fue actualizado correctamente.",
                    success:true,
                },
                loading_editors:false,
                loading_absolute:false,
            }
        }
        case DELETE_EDITOR:{ // Delete
            return {...state, editors: [...state.editors.filter((editor) => editor.id !== action.payload.data.data.user.id)],                
                response:{
                    status:action.payload.status,
                    statusText:"El editor fue eliminado correctamente.",
                    success:true
                },
                loading_editors:false
            }
        }
        case GET_EDITORS_ERROR:{ // Set errors
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
                loading_editors:false,
                loading_absolute:false
            }
        }
        default:
            return state;
    }
}
// Actions
export const CLEAR_STATUS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_STATUS,payload: true})
}
export const CLEAR_EDITOR_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_EDITOR,payload: true})
}
export const EDITORS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_EDITORS,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/users?limit=1000',{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_EDITORS,payload: res.data.data.users.data.filter(element => element.level.toString() === '3')})
    } catch (error) {
        console.log(error)
    }
}
export const EDITOR_GET_ACTION = (id) => async (dispatch, getState) => {
dispatch({type: LOADING_EDITORS,payload: true})
try {
const res = await axios.get('https://dev.mediacore.com.ar/api/v1/users/'+ id,{
    headers:{
        'Authorization': 'Bearer ' + getState().users.user_logged.token
    },
})
dispatch({
    type: GET_EDITOR,
    payload: res.data.data.user
})
} catch (error) {
dispatch({type: GET_EDITORS_ERROR,payload:error.response})
console.log(error)
}
}
export const EDITOR_ADD_ACTION = (data) => async (dispatch, getState) => {
    
    dispatch({type: LOADING_EDITORS,payload: true})
    dispatch({type: LOADING_EDITORS_ABSOLUTE,payload: true})

    var dataSend = new FormData();
    dataSend.append("name", data.name);
    dataSend.append("nickname", data.name + "-" + getState().users.user_logged.client.id);
    dataSend.append("email", data.email);
    dataSend.append("password", data.password);
    dataSend.append("level", 3);
    dataSend.append("info", data.info);
    dataSend.append("phone", data.phone);
    for (const iterator of data.tags) {
        dataSend.append("tags[]", iterator);
    }
    dataSend.append("active", data.active ? 1 : 0);
    dataSend.append("client_id", getState().users.user_logged.client.id);

    //Scopes
    dataSend.append("scopes[]", "posts");
    dataSend.append("scopes[]", "categories.read");
    dataSend.append("scopes[]", "tags.read");
    dataSend.append("scopes[]", "groups.read");
    dataSend.append("scopes[]", "categories.list");
    dataSend.append("scopes[]", "tags.list");
    dataSend.append("scopes[]", "groups.list");
    
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/users',dataSend,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        dispatch({
            type: ADD_EDITOR,
            payload: res
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_EDITORS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
        console.log(error)
    }
}
export const EDITOR_UPDATE_ACTION = (data,id) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    dispatch({type: LOADING_EDITORS_ABSOLUTE,payload: true})
    var dataSend = {};
    data.name !== getState().editors.editor.name ? dataSend.name = data.name : '';
    data.email !== getState().editors.editor.email ? dataSend.email = data.email : '';
    data.password !== getState().editors.editor.password && data.password !== '' ? dataSend.password = data.password : '';
    data.info !== getState().editors.editor.info ? dataSend.info = data.info : '';
    data.phone !== getState().editors.editor.phone ? dataSend.phone = data.phone : '';
    data.active !== getState().editors.editor.active ? dataSend.active = data.active : '';
    dataSend.tags = data.tags;
    //Scopes
    dataSend.scopes = [
        "posts",
        "tags.list",
        "tags.read",
        "groups.read",
        "groups.list",
        "categories.read",
        "categories.list",
    ];

    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/users/'+id,dataSend,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            },
        })
        console.log(res)
        dispatch({
            type: UPDATE_EDITOR,
            payload: res,
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_EDITORS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const EDITOR_DELETE_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/users/'+id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: DELETE_EDITOR,
            payload: res
        })
    } catch (error) {
        dispatch({
            type: GET_EDITORS_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrió un error inesperado."],
                success:false
            }
        })
        console.log(error)
    }
}