import { useEffect } from 'react'
import  {useSelector} from 'react-redux'
const Screen = () => {

    const loading_filter = useSelector(store => store.tags.loading_absolute)
    const loading_users = useSelector(store => store.users.loading_absolute)
    const loading_posts = useSelector(store => store.posts.loading_absolute)
    const loading_category = useSelector(store => store.categories.loading_absolute)
    const loading_investors = useSelector(store => store.investors.loading_absolute)
    const loading_members = useSelector(store => store.members.loading_absolute)
    const loading_editors = useSelector(store => store.editors.loading_absolute)

    useEffect(() =>{
        const elem = document.getElementById("body");
        if(loading_posts || loading_filter ||  loading_users || loading_category || loading_investors || loading_members || loading_editors){
            elem.style.overflow = 'hidden';
        }
        else{
            elem.style.overflow = 'auto';
        }
    },[loading_posts,loading_filter,loading_users,loading_investors])

    return loading_posts || loading_filter ||  loading_users || loading_category || loading_investors || loading_members || loading_editors ?(
        <>
            <div className={"loading-section absolute-loading"}>
                <div class="loader"></div>
            </div>
        </>
    
    )   :''
}

export default Screen