import React, { useState, useRef, useEffect } from "react";
import Wrapper_1 from "../Wrapper_1";
import { routes } from "../../../static_data/routes";
import { CLIENT_GET_ACTION,CLIENTS_TYPE_GET_ACTION,CLIENT_UPDATE_ACTION,MODULES_GET_ACTION, CLIENT_UPDATE_LOGO_ACTION } from "../../../redux/clientsDucks"
import { useDispatch, useSelector } from "react-redux";
import {useParams} from 'react-router-dom'
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom"; 

import Loading from '../../Globals/Loading'

import DatePicker from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

export default function updateClient() {

  const dispatch = useDispatch();
  const history = useHistory();
  
  // const queryForge = new Date.now()
  //get current time
  const queryForge = new Date()

  //Variables
  const {id} = useParams()
  const [type, setType] = useState(1);
  const [active, setActive] = useState(false);
  const [color, setColor] = useState("#000000");
  const modules = useSelector(store => store.clients.modules)
  const [datos, setDatos] = useState({
    name:'',
    description:'',
    email:'',
    phone:'',
    main_color:'#000000',
    client_type_id:4,
    logo:'',
    active:1,
    modules:['3'],
    ssl_active:1,
    ga_client_id:'',
    domain_name:'',
    domain_extension:'',
    domain_expiration_date:new Date(),
    tokko_apikey:'',
  })
  const client = useSelector(store => store.clients.client)
  const client_types = useSelector(store => store.clients.client_types)
  const response = useSelector(store => store.clients.response)

  //Imagen
  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();

  //Funciones
  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    setDatos({ ...datos, logo: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.base,
    },
    {
      text: "Clientes",
      route: routes.mediaSmart.clients,
    },
    {
      text: "Actualizar cliente",
      route: routes.mediaSmart.updateClient,
    },
  ];

  const handleCreateClient = (e) => {
    e.preventDefault();
    dispatch(CLIENT_UPDATE_ACTION(id,datos));
  }
  const handleUpdateClient = (e) => {
    if(e.target.name == 'modules'){
      if(datos.modules.find(element => element.toString() === e.target.value.toString())){
          const newModules = datos.modules.filter(element => element.toString() !== e.target.value.toString())
          setDatos({
              ...datos,
              [e.target.name] : newModules, 
          })
          return null
      }
      setDatos({
          ...datos,
          [e.target.name] : [...datos.modules,e.target.value], 
      })
      return null
  }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }
  const setExpirationDate = (date) => {
    setDatos({
      ...datos,
      domain_expiration_date:date
    })
  }
  
  useEffect(() => {
    dispatch(CLIENT_GET_ACTION(id))
    dispatch(CLIENTS_TYPE_GET_ACTION())
    dispatch(MODULES_GET_ACTION());
  },[])
  
  const checkChanges = () => {
    return false;
    if(client.data){
      if(
        datos.name === client.data.data.client.name && 
        datos.description === client.data.data.client.description &&
        datos.email === client.data.data.client.email &&
        datos.phone === client.data.data.client.phone &&
        datos.main_color === client.data.data.client.main_color &&
        datos.client_type_id === client.data.data.client.client_type_id &&
        datos.active === client.data.data.client.active &&
        datos.ga_client_id === client.data.data.client.ga_client_id &&
        datos.domain_name == client.data.data.client.domain_name &&
        datos.domain_extension == client.data.data.client.domain_extension
        ){
          return true;
        }
    }

  }

  useEffect(() => {
    if(client.data){
      if(datos.logo !== client.data.data.client.logo && datos.logo !== ''){
        dispatch(CLIENT_UPDATE_LOGO_ACTION(id,datos.logo))
      }
    }
  },[datos.logo])

  useEffect(() => {
    if(client.data){
        setDatos({
          name:client.data.data.client.name,
          description:client.data.data.client.description,
          email:client.data.data.client.email,
          phone:client.data.data.client.phone,
          main_color:client.data.data.client.main_color,
          logo:client.data.data.client.logo,
          client_type_id:client.data.data.client.client_type_id,
          active:client.data.data.client.active,
          modules:client.data.data.client.modules.map(element => element.id.toString()),
          ssl_active:client.data.data.client.ssl_active,
          ga_client_id:client.data.data.client.ga_client_id,
          domain_name:client.data.data.client.domain_name,
          domain_extension:client.data.data.client.domain_extension,
          domain_expiration_date:convertDate(client.data.data.client.domain_expiration_date),
          tokko_apikey:client.data.data.client.tokko_apikey,
        })
    }
  },[client])

  useEffect(() => {
      if(response.success ){
        history.push(routes.mediaSmart.clients)
      }
  },[response])

  const checkStatusBoolean = (status) => {
    if(status === true || status === 1 || status === '1' || status === 'true'){
      return 1
    }
    return 0
  }

  const convertDate = (date) => {
    if(date){
      let dateArray = date.split('-');
      let newDate = new Date(dateArray[0],dateArray[1]-1,dateArray[2]);
      return newDate;
    }
    return null;
  }

  console.log(datos.modules)

  return client && client_types && modules ? (
    <Wrapper_1 title="Actualizar cliente" breadcrumbItems={breadcrumbItems}>
      <form onSubmit={handleCreateClient} className="content-mobile-padding w-100p media-core">
        <div className="grid-3-col">
          <div className="d-flex flex-column ">
            <label className="font-1">Nombre*</label>
            <input className="common-input" value={datos.name} name="name" onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Tipo*</label>
            <div className="d-flex gap-0">
              {client_types.filter(element => element.id > 3).map((element,index) => (
                <span
                  className={`font-1 small-font active-button-${
                    datos.client_type_id.toString() === element.id.toString() ? "blue" : "gray"
                  }`}
                  onClick={() => {
                    handleUpdateClient({target:{name:"client_type_id",value:element.id}});
                  }}
                >
                  {element.name}
                </span>
              ))}
            </div>
          </div>

        </div>
        <div className="grid-3-col mt-3">
          <div className="d-flex flex-column">
            <label className="font-1">Email*</label>
            <input className="common-input" name="email" value={datos.email} onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column">
            <label className="font-1">Teléfono*</label>
            <input className="common-input" name="phone" value={datos.phone} onChange={handleUpdateClient} type="text" />
          </div>

        </div>
        <div className="grid-3-col mt-3">
        <div className="d-flex flex-column">
            <label className="font-1">Descripción*</label>
            <input className="common-input" name="description" value={datos.description} onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column">
            <label className="font-1">Color primario*</label>
            <div className="position-relative w-100">
              <input type="text" className="common-input w-100" value={datos.main_color} onChange={(e)=>handleUpdateClient({target:{name:"main_color",value:e.target.value}})} />
              <input
                className="w-100s position-absolute me-2"
                style={{
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                }}
                value={datos.main_color}
                name="main_color"
                onChange={(e)=>handleUpdateClient({target:{name:"main_color",value:e.target.value}})}
                type="color"
              />
            </div>
          </div>   
        </div>
        <div className="grid-3-col mt-3">
          <div className="d-flex flex-column ">
              {/* <FileInput label="Logo" containerClassName="" className="py-3" /> */}
              <div class="content-img">
                <label for="addFile" className="font-1">Subir logo*</label>  
                  <div className={"drag uploader w-100 d-flex " + drag}
                    dragleave={onDragLeave}
                    dragenter={onDragEnter}
                    onDrop={onDrop}
                  >
                    <div className={`d-flex flex-column w-100`}>
                      <span for="addFile" className={`image-picker-button small-font `}>
                        <label style={{color:"#d6d6d6"}} for="addFile">
                          AGREGAR IMAGEN (JPG, PNG 1200x900)
                        </label>
                      </span>
                      <input
                        type="file"
                        name="addFile"
                        className="d-none"
                        ref={picture_profile}
                        onChange={changeFile}
                        id="addFile"
                      />
                    </div>
                    <img
                    style={{objectFit:"cover",objectPosition:"center"}}
                    className={"mx-0 mt-0 ms-2 " + (renderImage ? renderImage : datos.logo ? datos.logo : ' d-none')}
                    src={renderImage ? renderImage : datos.logo ? "https://dev.mediacore.com.ar" + datos.logo + "?" + queryForge.valueOf() : ''}
                    width="150"
                  />
                    {/* <img
                      className={"mt-0 me-0 ms-3 d-inline-block " + (renderImage ? '' : 'd-none')}
                      src={renderImage ? renderImage : ''}
                      width="150"
                    />{" "} */}
                  </div>
                </div>
          </div>
        </div>
        <div className="grid-3-col mt-5">
          <div className="d-flex flex-column justify-content-between" >
            <label className="font-1">Nombre del dominio (ej: dominio.com)</label>
            <input className={"common-input mb-4 " + (datos.domain_name === '' && "border-danger")} value={datos.domain_name} name="domain_name" onChange={handleUpdateClient} type="text" />
            <label className="font-1">Entidad registrante del dominio (Nic.ar & GoDaddy)</label>
            <input className={"common-input " + (datos.domain_extension === '' && "border-danger")} value={datos.domain_extension} name="domain_extension" onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column justify-content-between" >
            <label className="font-1">Certificado SSL</label>
            <div className="mt-0 mb-4 d-flex">
            <span
              className={`font-1 small-font active-button-${
                (checkStatusBoolean(datos.ssl_active) === 1 ? "black" : "gray")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"ssl_active",value:1}});
              }}
            >
              Si
            </span>
            <span
              className={`font-1 small-font active-button-${
                (checkStatusBoolean(datos.ssl_active) !== 0 ? "gray" : "black")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"ssl_active",value:0}});
              }}
            >
              No
            </span>
          </div>
            <label className="font-1">Expiración del dominio</label>
            <div className={"common-input calendary-picker " + ((datos.domain_expiration_date === '' || datos.domain_extension === '' || datos.domain_name === '') && "border-danger")}>
              <DatePicker dateFormat="yyyy/MM/dd" selected={datos.domain_expiration_date} onChange={(date) => setExpirationDate(date)}  locale="es" />
            </div>
          </div>
        </div>
        <div className="grid-3-col mt-5">
          <div className="d-flex flex-column pt-3" style={{ maxWidth: "200px" }}>
            <h4 className="mb-3">Activación de módulos</h4>
            {modules.map((element, index) => (
              <div className={"d-flex justify-content-between mb-3 " + (element?.id?.toString() === "3" && 'disabled') }>
                <label>{element?.name?.toString() === "mediadata" ? 'MediaTracker' : element.name}®</label>
                <input
                  type="checkbox"
                  id={element.id}
                  name="modules"
                  // defaultChecked={datos?.modules?.find(module => module?.toString() === element?.id?.toString())}
                  checked={datos?.modules?.find(module => module?.toString() === element?.id?.toString())}
                  value={element.id}
                  disabled={element?.id?.toString() === "3"}
                  onChange={handleUpdateClient}
                  className="checkbox-1 me-1"
                />
                <label className="label-module" htmlFor={element.id}>
                  <div></div>
                </label>
              </div>
            ))}
          </div>
          <div className={"d-flex flex-column pt-3 "}>
            <label className="font-1">Código de seguimiento MediaTracker® (view_id Google Analytics) </label>
            <input className={"common-input " + (datos.ga_client_id === '' && "border-danger")}  value={datos.ga_client_id} name="ga_client_id" onChange={handleUpdateClient} type="text" />
            <label className="font-1 mt-5">Api key de Tokko</label>
            <input className={"common-input " + (!datos.tokko_apikey && "border-danger")}  value={datos.tokko_apikey} name="tokko_apikey" onChange={handleUpdateClient} type="text" />
          </div>
          <div className={"d-flex flex-column pt-3 "}>
          </div>
        </div>
        <div className="new-ticket-inputs-2 mt-1 mt-md-5">
          <label className="font-1">Activo</label>
          <div className="mt-2 mb-4 d-flex">
            <span
              className={`font-1 small-font active-button-${
                (checkStatusBoolean(datos.active) === 1 ? "black" : "gray")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"active",value:1}});
              }}
            >
              Si
            </span>
            <span
              className={`font-1 small-font active-button-${
                (checkStatusBoolean(datos.active) !== 0 ? "gray" : "black")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"active",value:0}});
              }}
            >
              No
            </span>
          </div>
        </div>
        <button disabled={checkChanges()} className={"blue-button mt-1r " + (checkChanges() && "disabled")}>Guardar</button>
        <Link to={routes.mediaSmart.clients}>
          <button  className={"cancell-button mt-1r ms-3 " + (checkChanges() && "disabled")}>Cancelar</button>
        </Link>
      </form>
    </Wrapper_1>
  ):<Loading />;
}