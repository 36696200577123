import axios from 'axios'

// Constantes
const dataInicial = {
    users:[],
    user: {},
    user_logged:{
            // "id": 36,
            // "name": "Axel Recupero",
            // "nickname": "Recupero",
            // "email": "axel.recupero@mediahaus.com.ar",
            // "phone": "1166409978",
            // "client_id": 15,
            // "level": 1,
            // "profile_image": null,
            // "cover_image": null,
            // "info": null,
            // "active": true,
            // "deleted_at": null,
            // "created_at": "2021-09-17T15:00:57.000000Z",
            // "updated_at": "2021-09-17T15:07:57.000000Z",
            // "client": {
            //     "id": 15,
            //     "client_type_id": 6,
            //     "name": "Mediahaus",
            //     "description": "Esta es una prueba.",
            //     "email": "hola@mediahaus.com.ar",
            //     "phone": "1111111111",
            //     "logo": "/storage/clients/logos/15.png",
            //     "main_color": "#00A1FF",
            //     "active": 1,
            //     "deleted_at": null,
            //     "created_at": "2021-09-07T20:24:59.000000Z",
            //     "updated_at": "2021-09-17T14:31:36.000000Z"
            // },
            // "client":{
            //     "id": 28,
            //     "client_type_id": 6,
            //     "name": "RED",
            //     "description": "Descripción",
            //     "email": "prueba@bgb.com.ar",
            //     "phone": "1111111",
            //     "logo": "/storage/clients/logos/28.png",
            //     "main_color": "#fd0101",
            //     "active": 1,
            //     "deleted_at": null,
            //     "created_at": "2021-09-09T20:38:42.000000Z",
            //     "updated_at": "2021-09-17T16:24:52.000000Z"
            // },
            // "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDBkYjZkNDdmMTMwOGEwODlhZGFjOTljY2I2MDFlNDM5YzcxYTRiNWEzYTA5YjdhZjMyZjBkNzliZTkzMjAwNjE2MGFlYTUzNTBjOGRjYjIiLCJpYXQiOjE2MzE4OTEyNzcsIm5iZiI6MTYzMTg5MTI3NywiZXhwIjoxNjYzNDI3Mjc3LCJzdWIiOiIzNiIsInNjb3BlcyI6WyIqIl19.JS9zYdg90hlU9Aj3rCOXrKpUt_bJw846AqS5SfGgpeFxMeG9_8ftN-OZsgnvSU8kwy66O5q3-CU4prFdGz0pR5C4FPa8Qtd4Yv8vje7YxpiZc1Ai2v-dsLTRKFefNGpHh_eSnzQcCBo77Jk5Syoh77QiDb7BlyaVpLrsMWp4quCw2r9jeT2RztgSFeIC3q83gphnMFRhb0i2fzFzAtkJZGaMhKY7TCg4bboEXQ0vU7ph3QU7pxpUltalhZTvT0sxNCROQWsUCHkYSW-riGeMhihf1YW9x8ET84ADJC6qWJEuHldgWSmyO4N-FHQu7yWCYLL26Nynd-vep8ZzwHbNTbLoVkjhMURCMVSdEt9lZPfBc1Eq_wIZgZOKK_U05IepRZmj2hewFwJYG7FtcxAxTwps2Lx6MFB4oVXA74biCoGJwHX3iFi9Pc_scDYjKMV6vtCA78QyN9NdV2eIjuAWeruLV9Bl5SKHIFKGC7LxWf6D6xz28fFGff-diXNmZmKxodq4jhGcWex84oDNMCVPh_3ROfyYiVU2Wsdpvd6-Pw7O6cUZhAs3h8vXcmHhWVvmudjqgWEv9e9BWBITKWKE6x0OWLx5pdig4KwZpMTy9miAe3O3obUNNs92xIUGsJnxliZR8esBHeFqwqNs7Ag9h7OW_DgN44x3LB-pyfj-fL0"
    },
    clients:[],
    response:{},
    loading_user:false,
    loading_absolute:false,
    userRedirection:''
}

// Types
const LOADING_USER = 'LOADING_USER'
const LOADING_USERS_ABSOLUTE = 'LOADING_USERS_ABSOLUTE'
const GET_USERS = 'GET_USERS'
const GET_CLIENTS = 'GET_CLIENTS'
const GET_USER = 'GET_USER'
const ADD_USER = 'ADD_USER'
const UPDATE_USER = 'UPDATE_USER'
const UPDATE_USER_ME = 'UPDATE_USER_ME'
const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
const DELETE_USER = 'DELETE_USER'

const LOGGED_IN_USER = 'LOGGED_IN_USER'
const LOGGED_GET_IN_USER = 'LOGGED_GET_IN_USER'
const LOGGED_OUT_USER = 'LOGGED_OUT_USER'
const USER_REDIRECTION = 'USER_REDIRECTION'
const LOGIN_ERROR = 'LOGIN_ERROR'

const RESET_PASSWORD = 'RESET_PASSWORD'
const RESET_ERROR_PASSWORD = 'RESET_ERROR_PASSWORD'

const UPDATE_CLIENT = 'UPDATE_CLIENT'

const GET_USERS_ERROR = 'GET_USERS_ERROR'
const CLEAR_STATUS = 'CLEAR_STATUS'

// Reducer
export default function postReducer(state = dataInicial, action){
    switch(action.type){
        case CLEAR_STATUS:{ //Clear status
            return {
                ...state, response : {},
            }
        }
        case LOADING_USERS_ABSOLUTE:{ //Clear status
            return {
                ...state, loading_absolute : action.payload,
            }
        }
        case LOADING_USER:{
            return {
                ...state,loading_user:action.payload,
            }
        }
        case GET_USERS:{ //Get all tags
            return {
                ...state, users : action.payload.data.data.users.data,
                loading_user:false 
            }
        }
        case GET_CLIENTS:{ //Get all tags
            return {
                ...state, clients : action.payload.data.data.clients.data,
                loading_user:false 
            }
        }
        case GET_USER: {// Get details tag
            return {
                ...state, user : action.payload,
                loading_user:false 
            }
        }
        case GET_USER: {// Get details tag
            return {
                ...state, user : action.payload,
                loading_user:false 
            }
        }
        case ADD_USER:{ // Add users
            return {...state, users: [...state.users,action.payload.data.data.user],                
                response:{
                    status:"200",
                    statusText:"El usuario fue creado correctamente.",
                    success:true
                },
                loading_user:false
            }
        }
        case UPDATE_USER:{ // Update 
            return {...state,
                users:[...state.users.map(user => user.id === action.payload.data.data.user.id ? action.payload.data.data.user : user)],
                response:{
                    status:201,
                    statusText:"El usuario fue actualizado correctamente.",
                    success:true,
                },
                loading_absolute:false
            }
        }
        case UPDATE_USER_ME:{ // Update 
            return {...state,
                user_logged : {...action.payload,
                    name:action.payload.name,
                    email:action.payload.email,
                    info:action.payload.info,
                    nickname:action.payload.nickname,
                    phone:action.payload.phone
                }, 
                response:{
                    status:201,
                    statusText:"El usuario fue actualizado correctamente.",
                    success:true,
                },
                loading_absolute:false
            }
        }
        case UPDATE_CLIENT:{ // Update 
            return {...state,
                response:{
                    status:201,
                    statusText:"Los datos fueron actualizados correctamente.",
                    success:true,
                },
                loading_absolute:false
            }
        }
        case UPDATE_USER_PROFILE:{ // Update 
            return {...state,
                response:{
                    status:201,
                    statusText:"La imagen de perfil fue actualizada correctamente.",
                    success:true,
                },
                loading_absolute:false
            }
        }
        case DELETE_USER:{ // Delete
            return {...state, users: [...state.users.filter((user) => user.id !== action.payload.data.data.user.id)],                
                response:{
                    status:action.payload.status,
                    statusText:"El usuario fue eliminado correctamente.",
                    success:true
                },
                loading_user:false,
                loading_absolute:false,
            }
        }
        case LOGGED_IN_USER:{ // Set errors
            return {...state,
                user_logged:action.payload.data.data.user,
                response:{
                    status:action.payload.status,
                    statusText:"Haz ingresado correctamente.",
                    success:true,
                },
                loading_user:false
            }
        }
        case LOGGED_GET_IN_USER:{ // Set errors
            return {...state,
                user_logged:action.payload,
                loading_user:false
            }
        }
        case LOGGED_OUT_USER:{ // Set errors
            return {...state,
                user_logged:{},
                response:{
                    success:true,
                },
                loading_user:false
            }
        }
        case USER_REDIRECTION:{ // Set errors
            return {...state,
                userRedirection:action.payload,
            }
        }
        case GET_USERS_ERROR:{ // Set errors
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
                loading_user:false,
                loading_absolute:false,
            }
        }
        case LOGIN_ERROR : {
            return {...state,
                response:{
                    status:401,
                    statusText:["Email o contraseña incorrecta."],
                    success:false,
                },
                loading_user:false
            }
        }
        case RESET_ERROR_PASSWORD : {
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
            }        
        }
        case RESET_PASSWORD : {
            return {...state,
                response:{
                    status:200,
                    statusText:[action.payload],
                    success:true,
                },
            }        
        }
        default:
            return state;
    }
}
// Actions Sin funcionamiento por el momento.
export const CLEAR_STATUS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_STATUS,payload: true})
}
export const USERS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_USER,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/users?limit=150',{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_USERS,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const USER_GET_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_USER,payload: true})

    try {
    const res = await axios.get('https://dev.mediacore.com.ar/api/v1/users/'+ id,{
        headers:{
            'Authorization': 'Bearer ' + getState().users.user_logged.token,
        },
    })
    dispatch({
        type: GET_USER,
        payload: res.data.data.user
    })
    } catch (error) {
        dispatch({type: GET_USERS_ERROR,payload:error.response})
        console.log(error)
        }
}
export const USER_ADD_ACTION = (data) => async (dispatch, getState) => {
    
    dispatch({type: LOADING_USER,payload: true})
    
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/users',data,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: ADD_USER,
            payload: res
        })
    } catch (error) {
        let errors_texts = []
        if(error.response){
            for (const iterator of Object.values(error.response.data.errors)) {
                for (const text of iterator){
                    errors_texts.push(text)
                }
            }
        }
        else{
            errors_texts.push("Ocurrió un error.")
        }
        dispatch(
            {type: GET_USERS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const USER_UPDATE_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING_USERS_ABSOLUTE,payload: true})
    let send_response = {}
    if(data.name){send_response.name=data.name}
    if(data.nickname){send_response.nickname=data.nickname}
    if(data.phone){send_response.phone=data.phone}
    if(data.level){send_response.level=data.level}
    if(data.scopes){send_response.scopes=data.scopes}
    if(data.password){send_response.password=data.password}
    if(data.email !== getState().users.user.email){send_response.email = data.email}

    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/users/'+id,send_response,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        // let response = getState().users.user_logged;
        // response.email = res.data.data.user.email
        // response.name = res.data.data.user.name
        // response.info = res.data.data.user.info
        // response.nickname = res.data.data.user.nickname
        // response.phone = res.data.data.user.phone
        console.log(res)
        dispatch({
            type: UPDATE_USER,
            payload: res,
        })
    } catch (error) {
        console.log(error.response)
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_USERS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const USER_ME_UPDATE_ACTION = (data) => async (dispatch, getState) => {
    dispatch({type: LOADING_USERS_ABSOLUTE,payload: true})
    let send_response = {}
    if(data.email !== getState().users.user_logged.email){send_response.email = data.email}
    send_response.name = data.name
    send_response.phone = data.phone
    if(data.password){send_response.password = data.password}

    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/users/me',send_response,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: UPDATE_USER_ME,
            payload: res,
        })
    } catch (error) {
        console.log(error.response)
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_USERS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const USER_UPDATE_PROFILE_ACTION = (data) => async (dispatch, getState) => {
    dispatch({type: LOADING_USERS_ABSOLUTE,payload: true})
    var dataSend = new FormData();
    dataSend.append("profile_image", data);
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/users/profile-image',dataSend,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({type: UPDATE_USER_PROFILE})

    } catch (error) {
        console.log(error)
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_USERS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const USER_DELETE_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_USER,payload: true})
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/users/'+id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            },
        })
        console.log(res)
        dispatch({
            type: DELETE_USER,
            payload: res
        })
    } catch (error) {
        dispatch({
            type: GET_TAGS_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrió un error inesperado."],
                success:false
            }
        })
        console.log(error)
    }
}
export const CLIENTS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_USER,payload: true})
    try {
        // eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZDJhMmZkZWMxZmJkZWE3OTA2N2U5M2QxZjkzY2YzMmE3NDhkZjk4Mzc2ZmFiNzAyZTlmMmE5MjQ3NWQ0MGYxNjdmOGFjN2FhZjc3ZTI3MWQiLCJpYXQiOjE2NjY4MDA2OTIsIm5iZiI6MTY2NjgwMDY5MiwiZXhwIjoxNjk4MzM2NjkyLCJzdWIiOiIxMTgiLCJzY29wZXMiOlsicG9zdHMiLCJjYXRlZ29yaWVzIiwidGFncyIsInVzZXJzIiwiY2xpZW50cyIsImZpbGVzIiwiZ3JvdXBzIl19.mfU7XWvn6BpcUh-rp8uP23X2kLjk0n3NlLsWdjYhru_G5dfXWOWUp8beyxqltV9mYoVp1t6KUjahdyDr8QCcBVx2LuDZMWWr4YBs6LypKNOgrFQpWOeWHrZRE88in08G6sLoPlPsdKIFgdUgIfzgStTq0iVNgnl1f6au97gDfZq-yFBCCp3lrypAGMqZLT3jpZtGLyuGHIMF--WhE2Mw-fbwEl1-HgeA6lsEANq_6LVF_wvTO65PSldPG5kGWNFAansUxOnrlTq0WdKvLSQPcNaTYOy_Z2-C0Cy5ZBPO1SEavWS4da3164YOHQMD1WyrU2_i2Dy_qCySmFx90O0fJEqT45UnjnLvdwrS1kkrDLteAofwSLRCmUksoV5ysbhhIfwfM0zop83gUa_-p596GdbEkScqSl-p0hQVPd_uO5tJTMY9Zxww6YYhM4usCGgdnWdflWSJ-0jBs471klUBF750imHiBaRcu9b47Ua-3iM5B4LeRWBboRh7a1ab5MX9KSUO5AHxi-ZZ66Bv7fBmfFVMXH15fey5I_ZEfAuCBeWL2oF2MeatBpf6quxqTop-mUeuTN-ywgzBcdKCgwM4n2zwAkrpot66IT4AZuOI33DqQH3CrLINryHUG7j6vxDrMQAddxW5Mc3QImEHd5Cgw2vaWhzbzVx75e9N0TrBPpI
        // eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYzMzNGFmZmMwNzhhMmVlMGY1YjA3MjM2NjgyOTUzYTVmZmQ3YTQ1NTUyMWE3NGIyZTI5ZmIxNjU2ODE1ODdlMDBkNDM3NzM1YTE3MjIyM2EiLCJpYXQiOjE2NDk0NDg1MDAsIm5iZiI6MTY0OTQ0ODUwMCwiZXhwIjoxNjgwOTg0NTAwLCJzdWIiOiIzNiIsInNjb3BlcyI6WyIqIl19.i4vqMHhnsnwkIKpzIufdnGqUjF7qbdFb413eyrQYjqA0PC1jGYyue3xqUWofldGFSCoqB-azuspmUlgM4vygAVkJQ608_irg_-ZL_RPVK3P_XFIKtzimL0f7ok-G-lAR68c7l97BwpdGXaVgQ1ckL3gxboU-iwsOYHHFTI1l6JM6dSG0KuQc09zY2o3KjhwPbWOYgVw2jqMuLuaBUa2obdeK90i_1Pw4LCpieoGCtBIghzGoFHX25DyHqawT7gxf4-9Xw01jpeah6k_K-MJoeEeLa5m5g73S6fSrfNW1JtAxfnNHJ7RL0ylWMNWO0AzM1KaBh7f6rxgGc83-W1nD3akQPJyv8CJaZ1qLMFVJ6xd9j2A2J23a1KtrPt3e12dUDxW3rqvykIyr3v4Xw9cCF8M1fX2k7-FtrDP0KjGWYvTd__n_VUZZFdoWgyzAoL3Ll5IWHERKAYeLRHgVFRzUoREMaTJWUcu-GcxDhC71e-d3DzWSiBQVrwfkb5m_AnDGs9QfBltdcv2OZOuwIFmddyqKs1KzPU537TqrlQWSFJYYgcEHH537fFflzdxwNa_RVZAj2WaPO0lUaHDewu7LnmCH2uStSQhoGp5PwvfJtzvDNPtHBxg_7-_IRVGY8ZlXBrPwt1yWBioMyjSFmRwwspvv-OGa2Jm7Mw1v-beJ-gs
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/clients?limit=150',{
            headers:{
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiMzJkM2FjY2IxZDVlMGMxZmVjYTBiZGE2NmVhY2M5YzU1MGVlYWFmYTlmM2E5NWI3NGFkM2FlODc0ZDg4NTA4NzZiMTdlMjUzYzQ5YTBmZmEiLCJpYXQiOjE3MDcxNTc5OTQsIm5iZiI6MTcwNzE1Nzk5NCwiZXhwIjoxNzM4NzgwMzk0LCJzdWIiOiIyOSIsInNjb3BlcyI6WyIqIl19.LrHaxTF1gfHB1ViBNdNpxgW5_x5e1JamTjNDCVHwLJ6K78-vgNBdaqdTPI1vlfSmeUEJS6ctRVKsFeRVDem7LzozEu-RGxf5iTi6HiQJX_61NKHyi0qSC1YaNKDNXnc_RPrRczCZcZm4cCQ0s5qHU0zTpkKm8DU0BpPLdnhDJH3CsKqn3pfD_3zA9PHNfisGFemF0qn9bWDMJTTMllREhYb-k_8npd_1L8Sfqk7l1uzotQeZ9cbEuq6zdwV1iUikbj-bf86kGoKkUaqJhZspc0zKeMcg7sfx7a_bZL63wXnqygomCWX28020ThIvQuc2NlSQTMmSBn9vH7DY9fJsXqHV6QLmKy9mjhCD2Lo-9zj-8xtRMj3o-feKG6MRk6qaNxLKT_G_o2KTaQLbNswVkTdR58SZI3OZNjzUWN-0KBpfQMGrEbESmUQEG4uu87VHTqH7CoHmwl-WNE6neDvCNdpke0Lk6KzxVdBdltH7kgEh4NuqwRU-WVK7q3bkfIAm6D0Lb41LcooTFfscP2w9CS-0JbewQjObGhn4F_mH8cZIgkOwCMQ3eUwp7jVXysGY2d6rbhSjhlkSy7WS8U9-FvZwAkjLQ_vxefOHCrNgUHzLS87b-sav10vCV7UfM4POl_WBjbgUq875FXFQ00nsqS-nvO19Jr-mea_Ee9_y374',
            }
        })
        dispatch({type: GET_CLIENTS,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const CLIENT_UPDATE_ACTION = (data,id) => async (dispatch, getState) => {
    dispatch({type: LOADING_USERS_ABSOLUTE,payload: true})
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/clients/' + id, data,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({type: UPDATE_CLIENT})

    } catch (error) {
        console.log(error)
        let errors_texts = []
        if(error.response?.data?.errors)
        {
            for (const iterator of Object.values(error.response.data.errors)) {
                for (const text of iterator){
                    errors_texts.push(text)
                }
            }
        }
        dispatch(
            {type: GET_USERS_ERROR,
            payload:{
                status:error?.response?.status || 400,
                statusText:errors_texts.length > 0 ? errors_texts : ["Ocurrió un error inesperado."],
                success:false
            }
        })
    }
}

//Login
export const USER_LOGIN_ACTION = (data) => async (dispatch, getState) => {

    dispatch({type: LOADING_USER,payload: true})

    try {
        const res = await axios.post('https://dev.mediacore.com.ar/login',{"email":data.email,"password":data.password},{
        headers:{
            // 'Content-Type': 'application/json',
        },
    })
    localStorage.setItem('token_id', res.data.data.user.token);
    dispatch({
        type: LOGGED_IN_USER,
        payload: res
    })
    } catch (error) {
            dispatch({
                type: LOGIN_ERROR,
                payload: error.response
            })
            console.log()
        }
}
export const USER_GET_LOGIN_ACTION = (data) => async (dispatch, getState) => {

    dispatch({type: LOADING_USER,payload: true})

    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/users/me',{
        headers:{
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + localStorage.getItem('token_id'),
            "Accept": "application/json"
        },
    })
    const response = res.data.data
    response.token = localStorage.getItem('token_id');
    dispatch({
        type: LOGGED_GET_IN_USER,
        payload: response
    })
    } catch (error) {

    }
}
export const USER_LOGOUT_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_USER,payload: true})
    localStorage.removeItem('token_id');
    dispatch({ type: LOGGED_OUT_USER,})
}
export const USER_SET_REDIRECTION = (direction) => async (dispatch, getState) => {
    dispatch({ type: USER_REDIRECTION,payload: direction })
}

export const USER_RECOVER_PASSWORD = (email) => async (dispatch, getState) => {
    dispatch({type: LOADING_USER,payload: true})
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/password/reset-request',{"email":email},{
            headers:{
            },
        })
        console.log(res)
        dispatch({type:RESET_PASSWORD,payload:res.data.status})
        
    } catch (error) {
        let errors = []

        for (const iterator of Object.values(error.response.data)) {
            errors.push(iterator)
        }

        dispatch({
            type:RESET_ERROR_PASSWORD,
            payload:{
                status:error.response.status,
                statusText:errors[0]
            }
        })
    }
}

export const USER_SEND_PASSWORD = (token,email,pass,repass) => async (dispatch, getState) => {
    dispatch({type: LOADING_USER,payload: true})
    const data = {
        "email":email,
        'token':token,
        "password":pass,
        "password_confirmation":repass
    }
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/password/reset',data,{
            headers:{
            },
        })
        console.log(res)
        dispatch({type:RESET_PASSWORD,payload:res.data.status})
    } catch (error) {
        let errors = []

        for (const iterator of Object.values(error.response.data)) {
            errors.push(iterator)
        }

        dispatch({
            type:RESET_ERROR_PASSWORD,
            payload:{
                status:error.response.status,
                statusText:errors[0]
            }
        })
        // console.log(error.response)
    }
}
