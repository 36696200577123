import { CLEAR_STATUS_ACTION } from "../../redux/blogsDucks"
import { CLEAR_CLIENTS_STATUS_ACTION } from "../../redux/clientsDucks"
import { useDispatch, useSelector } from "react-redux"

import toast, { Toaster } from 'react-hot-toast';


import { useEffect} from "react"

const Toast_Response = () => {
    const dispatch = useDispatch()

    const response_posts = useSelector(store => store.posts.response)
    const response_tags = useSelector(store => store.tags.response)
    const response_users = useSelector(store => store.users.response)
    const response_investors = useSelector(store => store.investors.response)
    const response_categories= useSelector(store => store.categories.response)
    const response_clients= useSelector(store => store.clients.response)
    const response_members= useSelector(store => store.members.response)
    const response_editors = useSelector(store => store.editors.response)
    const response_advisers = useSelector(store => store.advisers.response)

    useEffect(() => {
        if(response_posts.status){
            if(response_posts.success){
                toast.success(response_posts.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_posts.statusText.length > 1){
                    for (const iterator of response_posts.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_posts.statusText, { duration: 4000, position: 'top-center'})
                }
            }
            setTimeout(function(){ dispatch(CLEAR_STATUS_ACTION()) }, 1000);
        }
    },[response_posts])
    useEffect(() => {
        if(response_tags.status){
            if(response_tags.success){
                toast.success(response_tags.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_tags.statusText.length > 1){
                    for (const iterator of response_tags.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                   toast.error(response_tags.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_STATUS_ACTION()) }, 1000);
        }
    },[response_tags])
    useEffect(() => {
        if(response_users.status){
            if(response_users.success){
                toast.success(response_users.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_users.statusText.length > 1){
                    for (const iterator of response_users.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_users.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_STATUS_ACTION()) }, 1000);
        }
    },[response_users])
    useEffect(() => {
        if(response_investors.status){
            if(response_investors.success){
                toast.success(response_investors.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_investors.statusText.length > 1){
                    for (const iterator of response_investors.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_investors.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_STATUS_ACTION()) }, 1000);
        }
    },[response_investors])
    useEffect(() => {
        if(response_categories.status){
            if(response_categories.success){
                toast.success(response_categories.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_categories.statusText.length > 1){
                    for (const iterator of response_categories.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_categories.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_STATUS_ACTION()) }, 1000);
        }
    },[response_categories])
    useEffect(() => {
        if(response_clients.status){
            if(response_clients.success){
                toast.success(response_clients.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_clients.statusText.length > 1){
                    for (const iterator of response_clients.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_clients.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_CLIENTS_STATUS_ACTION()) }, 1000);
        }
    },[response_clients])
    useEffect(() => {
        if(response_members.status){
            if(response_members.success){
                toast.success(response_members.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_members.statusText.length > 1){
                    for (const iterator of response_members.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_members.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_CLIENTS_STATUS_ACTION()) }, 1000);
        }
    },[response_members])
    useEffect(() => {
        if(response_editors.status){
            if(response_editors.success){
                toast.success(response_editors.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_editors.statusText.length > 1){
                    for (const iterator of response_editors.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_editors.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_CLIENTS_STATUS_ACTION()) }, 1000);
        }
    },[response_editors])
    useEffect(() => {
        if(response_advisers.status){
            if(response_advisers.success){
                toast.success(response_advisers.statusText, { duration: 4000, position: 'top-center'})
            }
            else{
                if(response_advisers.statusText.length > 1){
                    for (const iterator of response_advisers.statusText) {
                        toast.error(iterator, { duration: 4000, position: 'top-center'})
                    }
                }
                else{
                    toast.error(response_advisers.statusText, { duration: 4000, position: 'top-center'})
                } 
            }
            setTimeout(function(){ dispatch(CLEAR_CLIENTS_STATUS_ACTION()) }, 1000);
        }
    },[response_advisers])

    return response_posts || response_tags || response_users || response_investors || response_clients || response_members || response_editors || response_advisers ? (
    <>
        <Toaster />
    </>
    ):''
}

export default Toast_Response
