import axios from 'axios'

// Constantes
const dataInicial = {
    actives:undefined,
    visits:undefined,
    sessions:undefined,
    interaction:undefined,
    states:undefined,
    loading_visits:false,
}

// Types
const LOADING = 'LOADING'
const LOADING_VISITS = 'LOADING_VISITS'
const GET_ACTIVES = 'GET_ACTIVES'
const GET_INTERANCTION = 'GET_INTERANCTION'
const GET_SESSIONS = 'GET_SESSIONS'
const GET_VISTS = 'GET_VISTS'
const GET_DOMAIN = 'GET_DOMAIN'

// Reducer
export default function analyticsReducer(state = dataInicial, action){
    switch(action.type){
        case LOADING_VISITS:{
            return {loading_visits:false}
        }
        case GET_ACTIVES:{ //Get actives
            return {
                ...state, actives : action.payload,
            }
        }
        case GET_INTERANCTION:{ //Get actives
            return {
                ...state, interaction : action.payload,
            }
        }
        case GET_SESSIONS:{ //Get actives
            return {
                ...state, sessions : action.payload,
            }
        }
        case GET_VISTS:{ //Get actives
            return {
                ...state, visits : action.payload,
                loading_visits:false,
            }
        }
        case GET_DOMAIN:{ //Get actives
            return {
                ...state, states : action.payload,
            }
        }
        default:
            return state;
    }
}
// Actions
export const ACTIVES_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/analytics/active-visits?view_id=' + getState().users.user_logged.client.ga_client_id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_ACTIVES,payload: res.data.data.active_visits})
    } catch (error) {
        console.log(error)
    }
}
export const INTERANCTION_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/analytics/interaction-time?view_id=' + getState().users.user_logged.client.ga_client_id + "&start_date=1-1-2020&end_date=1-1-2040",{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_INTERANCTION,payload: res.data.data.visits})
    } catch (error) {
        console.log(error)
    }
}
export const SESSIONS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/analytics/session-sources?view_id=' + getState().users.user_logged.client.ga_client_id + "&start_date=1-1-2020&end_date=1-1-2030",{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_SESSIONS,payload: res.data.data.session_sources})
    } catch (error) {
        console.log(error)
    }
}
export const VISITS_GET_ACTION = (from,to) => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/analytics/visits?view_id=' + getState().users.user_logged.client.ga_client_id + "&start_date="+ from +"&end_date=" + to +"",{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_VISTS,payload: res.data.data.visits})
    } catch (error) {
        console.log(error)
    }
}
export const STATUS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/domain-statuses',{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        console.log(res.data.data.domain_statues.data)
        dispatch({type: GET_DOMAIN,payload:res.data.data.domain_statues.data})
    } catch (error) {
        console.log(error)
    }
}