import Wrapper_2 from "../Wrapper_2";
import { routes } from "../../../static_data/routes";
import faker from 'faker';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ACTIVES_GET_ACTION, INTERANCTION_GET_ACTION, SESSIONS_GET_ACTION, VISITS_GET_ACTION,STATUS_GET_ACTION} from '../../../redux/analyticsDucks';
import Loading from '../../../components/Globals/Loading';

// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const optionsDoughnut = {
    aspectRatio:1.3,
    responsive: true,
    maintainAspectRatio: true, 
    plugins: {
      legend: {
            display: true,
            position: 'bottom',
            labels: {
                color: '#1f1f1f'
                }
            }
    } 
}

const optionsLineMobile = {
  responsive: true,
  // maintainAspectRatio:false,
  aspectRatio:1.2,
  plugins: {
    legend: {
      display:false
    },
    title: {
      display: true,
      // text: 'Chart.js Line Chart',
    },
  },
};
const optionsLine = {
  responsive: true,

  plugins: {
    legend: {
      display:false
    },
    title: {
      display: true,
    },
  },
};

const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.base,
    },
    {
      text: "MediaTracker®",
      route: routes.mediaData.analytics,
    },
    {
      text: "Métricas website",
      route: routes.mediaData.analytics,
    },
  ];

const Analitycs = () => {

  const dispatch = useDispatch();

  const [dateTime, setDateTime] = useState(new Date());

  const actives = useSelector(state => state.analytics.actives);
  const interaction = useSelector(state => state.analytics.interaction);
  const sessions = useSelector(state => state.analytics.sessions);
  const visits = useSelector(state => state.analytics.visits);
  const states = useSelector(state => state.analytics.states);
  const loading_visits = useSelector(state => state.analytics.loading_visits);

  const user_logged = useSelector(state => state.users.user_logged);

  const [minRange,setMinRange] = useState(quitDays());
  const [maxRange,setMaxRange] = useState(new Date());

  const [dataDoughnut,setDataDoughnut] = useState({
    labels: ['Directo', 'Orgánico', 'Referido'],
    datasets: [
      {
        label: 'Visitas',
        data: [0, 0, 0,],
      },
    ],
  })
  const [dataLine,setDataLine] = useState({
    datasets: [
      {
        label: 'Visitas',
      },
    ],
  });

  // Functions
  function quitDays() {
    var date = new Date();
    date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 35,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
  );
    return date;
  } 
  const getMinutes = (minutes,count) => {
    if(minutes.toString() === "0.0" || count.toString() === "0") {return "0m 0s"}
    let mins = (parseInt(minutes/count)).toFixed(2)
    if(mins < 60){
      return "0m" + ((mins / 100) * 60).toFixed() + "s"
    }
    else{
      let splitter = (mins/60).toFixed(2).toString().split(".")
      let minute = splitter[0]
      let seconds = (splitter[1] / 100) * 60
      if(splitter.length > 1){
        return minute + "m" + seconds.toFixed() + "s"
      }
      else{
        return minute + "m" + " 0s"
      }
    }
  }
  const getDateAndCount = (data) => {
    let array_complete = []
    let array_date = []
    let array_count = []
    if(data !== undefined){
      data.rows.map(row => {array_complete.push(row[0] + '/' + row[1].slice(4,6) + '/' + row[1].slice(0,4) + "-" + row[2])})
      data.rows.map(row => {array_date.push(row[0] + '/' + row[1].slice(4,6))})
      array_complete = array_complete.sort(function(a, b){return a.split("/")[1]-b.split("/")[1]}); // Ordeno por meses
      array_complete = array_complete.sort(function(a, b){return a.split("/")[2].split("-")[0]-b.split("/")[2].split("-")[0]}); // Ordeno por año
      array_complete.map(date => {array_count.push(date.split("-")[1])})
      array_date = array_complete.map(date => {return date.split("/")[0] + '/' + date.split("/")[1] + '/' + date.split("/")[2].split("-")[0]})
    }
    return [array_date,array_count]
  }
  function formatted_date(d)
  {
    if(d){
      var result="";
      result += d.getDate() + "-" + (d.getMonth()+1)+"-" + d.getFullYear();
      return result;
    }
  }
  const getRowsSources = (rows) => {
    let array_names = []
    let array_values = []
    rows.map(row => {
      if(row[0] === '(none)'){array_names.push("Directo")}
      else{array_names.push(row[0])}
      array_values.push(row[1])
    })
    // [sessions.rows[0][1], sessions.rows[1][1], sessions.rows[2][1]]
    return [array_names,array_values]
  }

  //Dispatchs
  useEffect(() => {
    dispatch(ACTIVES_GET_ACTION());
    dispatch(INTERANCTION_GET_ACTION());
    dispatch(SESSIONS_GET_ACTION());
    dispatch(STATUS_GET_ACTION());
    if(minRange !== undefined && maxRange !== undefined){
      dispatch(VISITS_GET_ACTION(formatted_date(minRange),formatted_date(maxRange)));
    }
  },[])

  useEffect(() => {
    if(minRange !== undefined && maxRange !== undefined){
      dispatch(VISITS_GET_ACTION(formatted_date(minRange),formatted_date(maxRange)));
    }
  },[minRange,maxRange])

  //Config charts
  useEffect(() => {
    if(visits !== undefined && user_logged){
      setDataLine({...dataLine,
        labels:getDateAndCount(visits)[0],
        datasets : [{
          label:"Visitas",
          data :getDateAndCount(visits)[1],
          backgroundColor: [
            '#fff'
          ],
          borderColor: [
            user_logged.client.main_color,
          ],
        }]
      })
    }

  },[visits,user_logged])

  useEffect(() => {
    if(sessions !== undefined && user_logged){
      setDataDoughnut({...dataDoughnut,
        labels: sessions.rows === null ? ['Sin visitas'] : getRowsSources(sessions.rows)[0],
        datasets : [{
          data : sessions.rows === null ? [0] : getRowsSources(sessions.rows)[1],
          backgroundColor: [
            user_logged.client.main_color,
            'rgba(0, 0, 0, 0.6)',
            'rgba(0, 0, 0, 0.4)',
            'rgba(0, 0, 0, 0.2)',
            'rgba(0, 0, 0, 0.1)',
          ],
        }]
      })
    }
  },[sessions,user_logged])

  const formateDateDDMMYYYY = (date) => {
    if(!date){return null}
    const dateparse = date.split("-")
    return dateparse[2] + '/' + dateparse[1] + '/' + dateparse[0]
  }

    return (
        <Wrapper_2
        breadcrumbItems={breadcrumbItems}
        title="MediaTracker"
        rightLink={false}
        >
          
            <div className="row d-flex mt-5 pt-5 pt-lg-0 mt-lg-0">
              <div className="col-lg-5">
                <div className="row d-flex">
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <div className="card-background small text-center">
                      {actives === undefined ? 
                        <Loading inside={true} isAbsolute={true} />
                        :
                        ''
                      }
                      <h4 className="mb-xxl-4 mb-3">Visitas en tiempo real</h4>
                      <h3 className="mb-xxl-3 mb-2">{actives ? actives : 0}</h3>
                      <h5>Usuarios en el sitio web</h5>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <div className="card-background small text-center">
                      {interaction === undefined ? 
                        <Loading inside={true} isAbsolute={true} />
                        :
                        ''
                      }
                      <h4 className="mb-xxl-4 mb-3">Tiempo de interacción</h4>
                      <h3 className="mb-xxl-3 mb-2">{interaction !== undefined && interaction.totalsForAllResults ? getMinutes(Object.values(interaction.totalsForAllResults)[1],Object.values(interaction.totalsForAllResults)[0]) : 0}</h3>
                      <h5>Promedio - histórico</h5>
                    </div>
                  </div>
                  <div className="col-12 mb-4 mb-lg-0">
                    <div className="card-background big mt-lg-4 h-adaptable doughnut">
                      {sessions === undefined ? 
                        <Loading inside={true} isAbsolute={true} />
                        :
                        ''
                      }
                      <h4 className="mb-xxl-4 mb-3 d-block">Procedencia de los nuevos usuarios</h4>
                      <h5>Histórico</h5>
                      <Doughnut data={dataDoughnut} options={optionsDoughnut} />
                    </div>
                    {/* <span className="legend-analytic d-none d-lg-block ">Fuente: Google Analytics®</span> */}
                </div>
                </div>
              </div>
              <div className="col-lg-7">
                  <div className="card-background big mb-4 mb-lg-0">
                    {visits === undefined || loading_visits ? 
                        <Loading inside={true} isAbsolute={true} />
                        :
                        ''
                      }
                      <div className="row text-center text-lg-start">
                        <div className="col-lg-6">
                          <div className="d-lg-flex">
                            <div>
                              <h4>Total de visitas únicas</h4>
                              <h3>{visits !== undefined && visits.totalsForAllResults ? Object.values(visits.totalsForAllResults)[0] : 0}</h3>
                            </div>
                            <div className="ms-xxl-5 ms-2">
                              <h4>Visitas promedio por día</h4>
                              <h3>{visits !== undefined && visits.totalsForAllResults && visits.totalResults ? (parseInt(Object.values(visits.totalsForAllResults)[0], 10) / parseInt((visits.totalResults),10)).toFixed(1) : 0}</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 justify-center d-lg-flex justify-content-lg-end">
                          <div className="me-lg-3">
                            <h4>Periodo</h4>
                            <div className="mt-xxl-3 mt-2 d-flex align-items-center justify-content-center justify-content-lg-start">
                              <div className="content-datepicker d-flex align-items-center">
                                <DatePicker maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={minRange} onChange={(date) => setMinRange(date)}  locale="es" />
                                {/* <img src={Calendar} alt="calendar" width="16px" /> */}
                                <i className="icon-calendar"></i>
                              </div>
                              <div className="mx-xxl-4 mx-1" style={{}}>-</div>
                              <div className="content-datepicker d-flex align-items-center">
                                <DatePicker maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={maxRange} onChange={(date) => setMaxRange(date)}  locale="es" />
                                {/* <img src={Calendar} alt="calendar" width="16px" /> */}
                                <i className="icon-calendar"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-none d-lg-block">
                        <Line data={dataLine} options={optionsLine} />
                      </div>
                      <div className="d-block d-lg-none">
                        <Line data={dataLine} options={optionsLineMobile} />
                      </div>
                  </div>
                  <div className={"card-background justify-content-center mb-4 mb-lg-0 mt-lg-4 small " + (user_logged.client.domain_name ? '' : 'd-none') }>
                    <div className="row">
                      <div className="col-lg-4 h-100 text-center px-lg-3">
                          <h4>Certificado de SSL</h4>
                          <h3 className={"mb-xxl-3 mb-2 font-adaptable " + (user_logged.client.ssl_active ? 'success-color' : 'danger-color') }>{user_logged.client.ssl_active ? 'Activo' : 'No activo'}</h3>
                          <h5>Sitio Web con dominio seguro.</h5>
                      </div>
                      <div className="col-lg-4 h-100 text-center border-left border-right px-lg-1">
                          <h4>Dominio</h4>
                          <h3 className="mb-xxl-3 mb-2 font-adaptable"><a href={(user_logged.client.ssl_active ? 'https://' : "http://") + (user_logged.client.domain_name)} target="_blank">{user_logged.client.domain_name}</a></h3>
                          <h5>Registrado en {user_logged.client.domain_extension}</h5>
                      </div>
                      <div className="col-lg-4 h-100 text-center px-lg-3">
                          <h4>Vencimiento</h4>
                          <h3 className={"mb-xxl-3 mb-2 font-adaptable " + (user_logged.client.domain_status_id.toString() === '1' ? 'success' : 'danger-' + user_logged.client.domain_status_id.toString())}>{formateDateDDMMYYYY(user_logged.client.domain_expiration_date)}</h3>
                            <div className="d-flex flex-wrap justify-content-center  flex-row">
                              {states?.map(element => (
                                <div className={"item-status mr-4 d-flex justify-content-center flex-row " + (element.id.toString() !== user_logged.client.domain_status_id.toString() ? 'disabled' : '')}>
                                  <div className={"square " + (element.id === 1 ? 'success' : 'danger-' + element.id )}></div>
                                  <h5>{element.name}</h5>
                                </div>
                              ))}
                            </div>
                      </div>
                    </div>
                  </div>
              </div>    
              <span className="legend-analytic d-block ">Fuente: Google Analytics®</span>
            </div>
        </Wrapper_2>
    )
}

export default Analitycs;