import React, { useState } from "react";
import Wrapper_1 from "../../Wrapper_1";
import { routes } from "../../../../static_data/routes";
import {CATEGORIES_GET_ACTION} from "../../../../redux/categoryDucks";
import {TAGS_GET_ACTION} from "../../../../redux/tagsDucks";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory,useParams } from "react-router-dom";


import {INVESTOR_GET_ACTION, INVESTOR_UPDATE_ACTION} from "../../../../redux/investorsDucks";
import toast, { Toaster } from 'react-hot-toast';

import eye from "../../../../assets/images/eye.png"
import eyeBlocked from "../../../../assets/images/eye-blocked.png"

// Mock data
const entrs = [
  "Media Haus 1",
  "Media Haus 2",
  "Media Haus 3",
  "Media Haus 4",
  "Media Haus 5",
  "Media Haus 6",
  "Media Haus 7",
  "Media Haus 8",
];

export default function CreateInvestor() {
  const [active, setActive] = useState(true);
  const history = useHistory()
  const {id} = useParams()

  const [datos,setDatos] = useState({
    name:"",
    email:"",
    password:"",
    repassword:"",
    phone:"",
    active:"",
    info:"",
    category:[],
  }) 

  const dispatch = useDispatch();

  const investor = useSelector((state) => state.investors.investor);
  const categories = useSelector((state) => state.categories.categories);
  const tags = useSelector((state) => state.tags.tags);
  const response = useSelector(store => store.investors.response)

  const [inputPassword, setInputPassword] = useState("password");
  const [inputRePassword, setInputRePassword] = useState("password");

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "MediaInvestor®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "Inversores",
      route: routes.mediaInvestor.investors,
    },
    {
      text: "Actualizar inversor",
      route: routes.mediaInvestor.updateInvestor,
    },
  ];

  const handleUpdateInvestor = (e) => {
    if(e.target.name === 'tags'){
      if(datos.tags.find(element => element === e.target.value)){
          const newCategories = datos.tags.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newCategories, 
          })
          return null
      }
      else{
        setDatos({
            ...datos,
            [e.target.name] : [...datos.tags,e.target.value], 
        })
        return null
      }
  }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }

  const handleCreateInvestor = () => {
    if(datos.password === datos.repassword){ 
      dispatch(INVESTOR_UPDATE_ACTION(datos,id))
    }
    else{
      toast.error("La contraseñas deben coincidir.", { duration: 4000, position: 'top-center'})
    }
  }

  useEffect(() =>  {
    dispatch(CATEGORIES_GET_ACTION());
    dispatch(TAGS_GET_ACTION());
    dispatch(INVESTOR_GET_ACTION(id));
  },[])

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  useEffect(() => {
    if(investor.id){
      let curTags = [];
      for (const iterator of investor?.tags) {
        curTags.push(iterator.id.toString())
      }
      setDatos({
        name:investor.name,
        email:investor.email,
        password:"",
        repassword:"",
        phone:investor.phone,
        active:investor.active,
        info:investor.info,
        tags:curTags,
      })
        if(investor.active === 'true' || investor.active === true){
          setActive(true)
        }
        else{
          setActive(false)
        }

    }
  },[investor])

  useEffect(() => {
    if(response.success){
      history.push("/app/media-investor/inversores")
    }
  },[response])

  useEffect(() => {
    handleUpdateInvestor({target:{name:'active',value:active}})
  },[active])

  function mostrarContraseña(){
    let value;
    if( inputPassword === "password"){
        value = "text";
    }else{
        value = "password";
    }
    setInputPassword(value)

}
  function mostrarReContraseña(){
    let value;
    if( inputRePassword === "password"){
        value = "text";
    }else{
        value = "password";
    }
    setInputRePassword(value)

}

  return (
    <Wrapper_1 title="Actualizar Inversor" breadcrumbItems={breadcrumbItems}>
      <div className="content-mobile-padding w-100p">
        <div className="mi-grid-create-investor">
          <form className="grid-2-col mb-1r" autocomplete="off">
            <div className="d-flex flex-column ">
              <label className="font-1">Nombre*</label>
              <input className="common-input" autocomplete="on" value={datos.name ? datos.name : 'Cargando..'} onChange={handleUpdateInvestor} name="name" type="text" />
            </div>

            <div className="d-flex flex-column ">
              <label className="font-1">Email*</label>
              <input className="common-input" autocomplete="off" value={datos.email ? datos.email : 'Cargando..'}  onChange={handleUpdateInvestor} name="email" type="text" />
            </div>

            <div className="d-flex flex-column">
              <label className="font-1">Contraseña*</label>
                <div className="position-relative input_password_eye common-input">
                  <input id="newPass"
                    name="password"
                    value={datos.password}
                    onChange={handleUpdateInvestor}
                    required
                    placeholder="Contraseña" 
                    type={inputPassword} 
                    autocomplete="off"
                    style={{border: "none"}}
                    className="w-100 border-none"/>
                  <img 
                    onClick={mostrarContraseña}
                    className="eye position-absolute "
                    id="boton-eye" 
                    src={ inputPassword === "password" ? eye : eyeBlocked }
                  />
              </div>

            </div>

            <div className="d-flex flex-column">
              <label className="font-1">Repetir Contraseña*</label>
              <div className="position-relative input_password_eye common-input">
                  <input id="newPass"
                    name="repassword"
                    value={datos.repassword}
                    onChange={handleUpdateInvestor}
                    required
                    placeholder="Contraseña" 
                    type={inputRePassword} 
                    autocomplete="off"
                    style={{border: "none"}}
                    className="w-100 border-none"/>
                  <img 
                    onClick={mostrarReContraseña}
                    className="eye position-absolute "
                    id="boton-eye" 
                    src={ inputRePassword === "password" ? eye : eyeBlocked }
                  />
              </div>
              {/* <input className="common-input" autocomplete="off" value={datos.repassword} onChange={handleUpdateInvestor} name="repassword" type="password" /> */}
            </div>

            <div className="d-flex flex-column ">
              <label className="font-1">Teléfono/WhatsApp</label>
              <input className="common-input" autocomplete="off" value={datos.phone ? datos.phone : 'Cargando..'} onChange={handleUpdateInvestor} name="phone" type="text" />
            </div>

            <div className="m-0 p-0"></div>
          </form>

          <div className="d-flex flex-column mb-1r">
            <label className="font-1">Emprendimientos</label>
            <div className="grid-checkboxes w-100p mt-1r gap-4">
              {console.log(datos.tags)}
              {tags?.filter(element => element?.categories?.find(element => element.id === getCategory("investor"))).map((entr, i) => (
                <div className="d-flex">
                  <input
                    type="checkbox"
                    onClick={handleUpdateInvestor}
                    className="checkbox me-1"
                    name="tags"
                    checked={datos.tags?.find(element => element.toString() === entr.id.toString()) ? true : false}
                    value={entr.id}
                  />
                  <label className={`small-font  `}>
                    {entr.name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex flex-column w-100p">
            <label className="font-1">Información Extra</label>
            <textarea name="info" onChange={handleUpdateInvestor}value={datos.info ? datos.info : 'Cargando..'} autocomplete="off" className="common-input h-6r" />
          </div>
        </div>
        <div className="new-ticket-inputs-2 mt-2r">
          <label className="font-1">Activo</label>
          <div className="mt-2 mb-4 d-flex">
            <span
              className={`font-1 small-font active-button-${
                active ? "black" : "gray"
              }`}
              onClick={() => {
                setActive((a) => true);
              }}
            >
              Si
            </span>
            <span
              className={`font-1 small-font active-button-${
                active ? "gray" : "black"
              }`}
              onClick={() => {
                setActive((a) => false);
              }}
            >
              No
            </span>
          </div>
        </div>
        <p>*Obligatorio</p>
        <button onClick={() => handleCreateInvestor()} className="blue-button mt-1r">Guardar</button>
      </div>
    </Wrapper_1>
  );

}