import React, { useState, useEffect } from "react";
import Wrapper_1 from "../../Wrapper_1";
import { routes } from "../../../../static_data/routes";
import { useDispatch,useSelector } from "react-redux";
import {CATEGORY_GET_ACTION, CATEGORY_UPDATE_ACTION} from '../../../../redux/categoryDucks'
import {TAG_GET_ACTION, TAG_UPDATE_ACTION} from '../../../../redux/tagsDucks'
import { useHistory,useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function CreateEntrepreneurship() {

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "MediaInvestor®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "Emprendimientos",
      route: routes.mediaInvestor.entrepreneurships,
    },
    {
      text: "Nuevo emprendimiento",
      route: routes.mediaInvestor.createEntrepreneurship,
    },
  ];

  const {id} = useParams()

  const history = useHistory()
  const dispatch = useDispatch();
  const response = useSelector(store => store.tags.response)
  const tag = useSelector(store => store.tags.tag)
 
  const [datos,setDatos] = useState({
    name:'',
  });

  useEffect(() => {
    if(response.success){
      history.push("/app/media-investor/emprendimientos")
    }
  },[response])

  useEffect(() => {
    dispatch(TAG_GET_ACTION(id))
  },[])

  useEffect(() => {
    if(tag){
        setDatos({
          name:tag.name,
        })
    }
  },[tag])

  const handleUpdateTag = (e) => {
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }

  const updateTag = () => {
    if(datos.name === tag.name){
      toast.error("No se han realizado cambios")
    }
    else{
      dispatch(TAG_UPDATE_ACTION(datos,id))
    }
  }

  return (
    <Wrapper_1 breadcrumbItems={breadcrumbItems} title="Nuevo emprendimiento">
      <div className="content-mobile-padding w-100">
        <div className="d-flex flex-column align-items-start w-100">
          <label>Nombre</label>
          <input type="text" onChange={handleUpdateTag} value={datos.name ? datos.name : 'Cargando..'} name="name" className="common-input w-100 mxw-500 mb-2" />
          <button onClick={() => updateTag()} className="blue-button mt-4">Guardar</button>
        </div>
      </div>
    </Wrapper_1>
  );
}
