import React, { useState, useRef, useEffect } from "react";
import Breadcrumb from "../../Breadcrumb";
import Wrapper_1 from "../../Wrapper_1";
import Avatar from "../../../../assets/images/avatar.png";
import Background from "../../../../assets/images/login-background.png";
import { useSelector, useDispatch } from "react-redux";
import {USER_UPDATE_ACTION,USER_UPDATE_PROFILE_ACTION,USER_ME_UPDATE_ACTION} from "../../../../redux/userDucks"
import toast, { Toaster } from 'react-hot-toast';
import eye from "../../../../assets/images/eye.png";
import eyeBlocked from "../../../../assets/images/eye-blocked.png"

export default function MyPersonalData() {
  const inputFileProfile = useRef(null);
  const inputFileBanner = useRef(null);

  const user_logged = useSelector(store => store.users.user_logged)

  const dispatch = useDispatch();

  const [inputPassword, setInputPassword] = useState("password");

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    nickname: "",
    password: "",
    repassword: "",
    info: "",
    image:''
  });

  const breadcrumbItems = [
    {
      text: "Mi Perfil",
    },
    {
      text: "Mis datos personales",
    },
  ];

  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();

  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    // console.log(picture_profile.current.files[0])
    setData({ ...data, image: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };

  function getFileProfile() {
    // inputFileProfile.current.click();
  }
  function getFileBanner() {
    inputFileBanner.current.click();
  }
  function handleChange(e) {
    setData((this_data) => {
      return { ...this_data, [e.target.name]: e.target.value };
    });
  }


  function mostrarContraseña(){
    let value;
    if( inputPassword === "password"){
        value = "text";
    }else{
        value = "password";
    }
    setInputPassword(value)

}


  useEffect(()=>{
    if(user_logged){
      setData(user_logged)
    }
  },[user_logged])

  useEffect(()=>{
    if(data.image){
      dispatch(USER_UPDATE_PROFILE_ACTION(data.image))
    }
  },[data.image])

  const sendInfo = () => {
    if(data.password === data.repassword){
      // if(data.level === '10'){
      //   dispatch(USER_UPDATE_ACTION(data,user_logged.id))
      // }
      // else{
        dispatch(USER_ME_UPDATE_ACTION(data))
      // }
    }
    else{
      toast.error("Las contraseñas deben ser iguales.", { duration: 4000, position: 'top-center'})
    }
  }

  console.log(data)

  return  (
    <Wrapper_1 title={"Hola, " + (data ? user_logged.name : 'Cargando..') +"!"} breadcrumbItems={breadcrumbItems}>
      <div className="images-container">
        <img
          className="banner-image"
          src={Background}
          alt="perfil de usuario"
          width="75px"
        />
        {/* <button className="edit-banner" onClick={getFileBanner}>
          <img src={PencilIcon} width="15px" alt="edit banner image icon" />
        </button> */}
        <div className="profile-wrapper">
          <img
            className="profile-image"
            // src={Avatar}
            src={ ( renderImage ? renderImage : user_logged.profile_image ? process.env.REACT_APP_BASE_URL + user_logged.profile_image : process.env.REACT_APP_BASE_URL +  "/storage/no-profile.jpg")}
            alt="perfil de usuario"
            width="75px"
          />
          <label for="addFile" className={"edit-profile" + (data.level !== '10' ? ' d-none ' : '')}>
          {/* onClick={getFileProfile} */}
            {/* <img src={PencilIcon} width="15px" alt="edit profile image icon" /> */}
            <i className="icon-pencil-icon"></i>
          </label>
        </div>
        <input type="file" ref={inputFileBanner} className="d-none" />
        <input                    
         type="file"
          name="addFile"
          className="d-none"
          ref={picture_profile}
          onChange={changeFile}
          id="addFile"
          />
      </div>

      <div className="content-mobile-padding w-100p">
        <div className="my-profile-input-grid">
          <div className="d-flex flex-column">
            <h2 className="text-gray-1 font-0">Datos personales</h2>
            <label className="no-display-tablet">Nombre y Apellido*</label>
            <input
              className="common-input input-with-placeholder"
              onChange={handleChange}
              value={data.name}
              disabled={true}
              type="text"
              autocomplete="off"
              name="name"
              placeholder="Nombre y Apellido*"
            />
          </div>
          <div className="d-flex flex-column">
            <label className="no-display-tablet">E-mail*</label>
            <input
              className="common-input input-with-placeholder"
              onChange={handleChange}
              value={data.email}
              autocomplete="off"
              type="email"
              name="email"
              placeholder="E-mail*"
            />
          </div>
          <div className="d-flex flex-column">
            <label className="no-display-tablet">Teléfono / Whatsapp</label>
            <input
              className="common-input input-with-placeholder"
              onChange={handleChange}
              value={data.phone}
              disabled={true}
              autocomplete="off"
              type="text"
              name="phone"
              placeholder="Teléfono / Whatsapp"
            />
          </div>
          <div className="d-flex flex-column">
            <h2 className="text-gray-1 font-0">Datos de acceso</h2>
            <label className="no-display-tablet">Usuario</label>
            <input
              className="common-input input-with-placeholder"
              onChange={handleChange}
              value={data.nickname}
              autocomplete="off"
              disabled={true}
              type="text"
              name="nickname"
              placeholder="Usuario"
            />
          </div>
          <div className="d-flex flex-column position-relative input_password_eye">
            <label className="no-display-tablet">Contraseña*</label>
            <input
              className="common-input input-with-placeholder"
              onChange={handleChange}
              // value={""}
              type={inputPassword}
              autocomplete="off"
              name="password"
              placeholder="Contraseña*"
            />
                <img            
                onClick={mostrarContraseña}
                className="eye position-absolute small-profile "
                id="boton-eye" 
                src={ inputPassword === "password" ? eye : eyeBlocked }
              />
          </div>
          <div className="d-flex flex-column">
            <label className="no-display-tablet">Reingrese Contraseña*</label>
            <input
              className="common-input input-with-placeholder"
              onChange={handleChange}
              // value={""}
              autocomplete="off"
              type={inputPassword}
              name="repassword"
              placeholder="Reingrese Contraseña*"
            />
          </div>
          <div className="d-flex flex-column">
            <label className="no-display-tablet">Sobre mi</label>
            <textarea
              className="common-input input-with-placeholder"
              onChange={handleChange}
              disabled={true}
              value={data.info}
              name="info"
              placeholder="Sobre mi.."
            ></textarea>
          </div>
        </div>

        <div className="save-button-wrapper">
          <button onClick={() => sendInfo()} className="blue-button">Guardar</button>
        </div>
      </div>
    </Wrapper_1>
  );
}
