// ID
// 1-Inversor
// 2-Miembro
// 3-Editor
// 9-SuperAdmin
// 10-Admin

import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import "./assets/styles/App.scss";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import Recover from "./components/Login/Recover";
import RecoverSend from "./components/Login/RecoverSend";
import RecoverChange from "./components/Login/RecoverChange.js";

import PrivateRoute from "./components/Route/PrivateRoute"

// Modal Response
import Toast_Response from './components/Globals/ToastResponse'
import LoadingScreen from './components/Globals/LoadingScreen'
// MediaCore Welcome
import Welcome from "./components/Content/MediaCore/Welcome";
// Media Host
import AboutMediaHost from "./components/Content/MediaHost/About";
import SupportMediaHost from "./components/Content/MediaHost/Support";
import NewTicketMediaHost from "./components/Content/MediaHost/NewTicket";
// Media Academy
import AboutMediaAcademy from "./components/Content/MediaAcademy/About";
import NewsMediaAcademy from "./components/Content/MediaAcademy/News/News";
import SeeNewMediaAcademy from "./components/Content/MediaAcademy/News/SeeNew";
// Investors
import InvestorsHome from "./components/Content/Investors/News/News";
import InvestorMyNovelties from "./components/Content/Investors/News/MyNews";
import InvestorsOportunity from "./components/Content/Investors/News/Oportunity";
import InvestorNovelties from "./components/Content/Investors/News/Novelties";
import SeeNewInvestors from "./components/Content/Investors/News/SeeNew";
// Account
import MyPersonalData from "./components/Content/Account/MyProfile/MyPersonalData";
import AccountState from "./components/Content/Account/Billing/AccountState";
import BillingData from "./components/Content/Account/Billing/BillingData";
// Media Blog
import AnalyticsMediaData from "./components/Content/MediaData/Analytics";
// Media Blog
import EditorsMediaBlog from "./components/Content/MediaBlog/Editors/Editors";
import NewEditorMediaBlog from "./components/Content/MediaBlog/Editors/CreateEditor";
import UpdateEditorMediaBlog from "./components/Content/MediaBlog/Editors/UpdateEditors";
import FiltersMediaBlog from "./components/Content/MediaBlog/Filters/Filters";
import NewFilterMediaBlog from "./components/Content/MediaBlog/Filters/NewFilter";
import UpdateFilterMediaBlog from "./components/Content/MediaBlog/Filters/UpdateFilter";
import NewsMediaBlog from "./components/Content/MediaBlog/News/News";
import CreateNewMediaBlog from "./components/Content/MediaBlog/News/CreateNew";
import UpdateNewMediaBlog from "./components/Content/MediaBlog/News/UpdateNew";
// Media Team
import EditorsMediaTeam from "./components/Content/MediaTeam/Adviser/Editors";
import NewEditorMediaTeam from "./components/Content/MediaTeam/Adviser/CreateEditor";
import UpdateEditorMediaTeam from "./components/Content/MediaTeam/Adviser/UpdateEditors";
import FiltersMediaTeam from "./components/Content/MediaTeam/Filters/Filters";
import NewFilterMediaTeam from "./components/Content/MediaTeam/Filters/NewFilter";
import UpdateFilterMediaTeam from "./components/Content/MediaTeam/Filters/UpdateFilter";
import NewsMediaTeam from "./components/Content/MediaTeam/News/News";
import CreateNewMediaTeam from "./components/Content/MediaTeam/News/CreateNew";
import UpdateNewMediaTeam from "./components/Content/MediaTeam/News/UpdateNew";
// Media Broker
import AboutMediaBroker from "./components/Content/MediaBroker/About";
import EventsMediaBroker from "./components/Content/MediaBroker/Events/Events";
import CreateEventMediaBroker from "./components/Content/MediaBroker/Events/CreateEvent";
import RoomsMediaBroker from "./components/Content/MediaBroker/Rooms/Rooms";
import CreateRoomMediaBroker from "./components/Content/MediaBroker/Rooms/CreateRoom";
import UsersMediaBroker from "./components/Content/MediaBroker/Users/Users";
import CreateUserMediaBroker from "./components/Content/MediaBroker/Users/CreateUser";
// Media Broker New
import AboutBroker from "./components/Content/MediaBrokerNew/AboutBroker";
import EventMediaBroker from "./components/Content/MediaBrokerNew/Events/EventMediaBroker";
import CreateEventBroker from "./components/Content/MediaBrokerNew/Events/CreateEventBroker";
import RoomBroker from "./components/Content/MediaBrokerNew/Rooms/RoomBroker";
import CreateRoomBroker from "./components/Content/MediaBrokerNew/Rooms/CreateRoomBroker";
import User from "./components/Content/MediaBrokerNew/Users/User";
import CreateUserBroker from "./components/Content/MediaBrokerNew/Users/CreateUserBroker";

// Media Investor
import ConfigMediaInvestor from "./components/Content/MediaInvestor/Config";
import AboutMediaInvestor from "./components/Content/MediaInvestor/About";
import NewsMediaInvestor from "./components/Content/MediaInvestor/News/News";
import CreateNewMediaInvestor from "./components/Content/MediaInvestor/News/CreateNew";
import UpdateNewMediaInvestor from "./components/Content/MediaInvestor/News/UpdateNew";
import InvestorsMediaInvestor from "./components/Content/MediaInvestor/Investors/Investors";
import CreateInvestorMediaInvestor from "./components/Content/MediaInvestor/Investors/CreateInvestor";
import UpdateInvestorMediaInvestor from "./components/Content/MediaInvestor/Investors/UpdateInvestor";
import EntrepreneurshipMediaInvestor from "./components/Content/MediaInvestor/Entrepreneurship/Entrepreneurship";
import CreateEntrepreneurMediaInvestor from "./components/Content/MediaInvestor/Entrepreneurship/CreateEntrepreneurship";
import UpdateEntrepreneurMediaInvestor from "./components/Content/MediaInvestor/Entrepreneurship/UpdateEntrepreneurship";

// Media Hub
import NewsMediaDocs from "./components/Content/MediaHub/News/News";
import CreateNewMediaDocs from "./components/Content/MediaHub/News/CreateNew";
import UpdateNewMediaDocs from "./components/Content/MediaHub/News/UpdateNew";
import MembersMediaDocs from "./components/Content/MediaHub/Members/Members";
import CreateMemberMediaDocs from "./components/Content/MediaHub/Members/CreateMember";
import UpdateMemberMediaDocs from "./components/Content/MediaHub/Members/UpdateMember";
import EntrepreneurshipMediaDocs from "./components/Content/MediaHub/Entrepreneurship/Entrepreneurship";
import CreateEntrepreneurMediaDocs from "./components/Content/MediaHub/Entrepreneurship/CreateEntrepreneurship";
import UpdateEntrepreneurMediaDocs from "./components/Content/MediaHub/Entrepreneurship/UpdateEntrepreneurship";

// Members
import MembersGenerals from "./components/Content/Members/News/Generals";
import MembersDevelopments from "./components/Content/Members/News/Developments";
import MembersDocs from "./components/Content/Members/News/MyDocs";
// import InvestorNovelties from "./components/Content/Investors/News/Novelties";
import SeeDoc from "./components/Content/Members/News/SeeNew";

// Media Smart
import ClientsMediaSmart from "./components/Content/MediaSmart/Clients";
import CreateClientsMediaSmart from "./components/Content/MediaSmart/CreateClient";
import UpdateClientsMediaSmart from "./components/Content/MediaSmart/UpdateClient";
import UsersMediaSmart from "./components/Content/MediaSmart/Users";
import CreateUsersMediaSmart from "./components/Content/MediaSmart/CreateUser";
import UpdateUsersMediaSmart from "./components/Content/MediaSmart/UpdateUser";

import {Provider} from 'react-redux'
import generateStore from './redux/store'


// Routes
import { routes } from "./static_data/routes";
import useAuth from "./Hook/useAuth";

function App() {
  const [menu, setMenu] = useState(false);
  const store = generateStore()

  const [isAuth, login, logout] = useAuth(false)

  return (
    <div className="app">
      <Router>
        <Provider store={store}>
          <Toast_Response />
          <LoadingScreen />
          <Switch>
            <Route exact path={routes.session.login} component={Login} />
            <Route exact path={routes.session.recover} component={Recover} />
            <Route exact path={routes.session.recoverSend} component={RecoverSend} />
            <Route exact path={routes.session.recoverChange} component={RecoverChange}
            />
            <Route exact path={routes.session.investors} component={Login}
            />
            <PrivateRoute path={routes.content}>
              <Header />
              <div className="container-2">
                <Sidebar  />
                <article className={`right-side w-100 ${menu ? "hidde-right-side" : ""}`} >
                  <PrivateRoute exact path={routes.myProfile.myData} component={MyPersonalData} auth={isAuth} />
                  <PrivateRoute exact path={routes.welcome.welcome} component={Welcome} />
                  <PrivateRoute exact path={routes.mediaHost.about} component={AboutMediaHost} />
                  <PrivateRoute exact path={routes.mediaHost.support} component={SupportMediaHost} />
                  <PrivateRoute exact path={routes.mediaHost.createTicket} component={NewTicketMediaHost} />
                  <PrivateRoute exact path={routes.mediaAcademy.about} component={AboutMediaAcademy} />
                  <PrivateRoute exact path={routes.mediaAcademy.news} component={NewsMediaAcademy} />
                  <PrivateRoute exact path={routes.mediaAcademy.new} component={SeeNewMediaAcademy} />
                  <PrivateRoute exact path={routes.billing.accountState} component={AccountState} />
                  <PrivateRoute exact path={routes.billing.billingData} component={BillingData} />
                  {/* Analytics */}
                  <PrivateRoute exact path={routes.mediaData.analytics} component={AnalyticsMediaData} />
                  {/* MediaBlog */}
                  <PrivateRoute exact path={routes.mediaBlog.news} component={NewsMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.createNew} component={CreateNewMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.updateNew} component={UpdateNewMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.filters} component={FiltersMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.createFilter} component={NewFilterMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.updateFilter} component={UpdateFilterMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.editors} component={EditorsMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.createEditor} component={NewEditorMediaBlog} />
                  <PrivateRoute exact path={routes.mediaBlog.updateEditor} component={UpdateEditorMediaBlog} />
                  {/* MediaTeam */}
                  <PrivateRoute exact path={routes.mediaTeam.news} component={NewsMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.createNew} component={CreateNewMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.updateNew} component={UpdateNewMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.filters} component={FiltersMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.createFilter} component={NewFilterMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.updateFilter} component={UpdateFilterMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.editors} component={EditorsMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.createEditor} component={NewEditorMediaTeam} />
                  <PrivateRoute exact path={routes.mediaTeam.updateEditor} component={UpdateEditorMediaTeam} />
                  {/* MediaBroker */}
                  <PrivateRoute exact path={routes.mediaBroker.about} component={AboutMediaBroker} />
                  <PrivateRoute exact path={routes.mediaBroker.events} component={EventsMediaBroker} />
                  <PrivateRoute exact path={routes.mediaBroker.createEvent} component={CreateEventMediaBroker} />
                  <PrivateRoute exact path={routes.mediaBroker.rooms} component={RoomsMediaBroker} />
                  <PrivateRoute exact path={routes.mediaBroker.createRoom} component={CreateRoomMediaBroker} />
                  <PrivateRoute exact path={routes.mediaBroker.users} component={UsersMediaBroker} />
                  <PrivateRoute exact path={routes.mediaBroker.createUser} component={CreateUserMediaBroker} />

                  {/* / New Media Broker / */}

                  <PrivateRoute
                    exact
                    path={routes.mediaBroker.aboutBroker}
                    component={AboutBroker}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaBroker.event}
                    component={EventMediaBroker}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaBroker.createNewEvent}
                    component={CreateEventBroker}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaBroker.room}
                    component={RoomBroker}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaBroker.createNewRoom}
                    component={CreateRoomBroker}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaBroker.user}
                    component={User}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaBroker.createNewUser}
                    component={CreateUserBroker}
                  />

                  {/* / fin New Media Broker/ */}

                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.about}
                    component={AboutMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.config}
                    component={ConfigMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.news}
                    component={NewsMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.createNew}
                    component={CreateNewMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.updateNew}
                    component={UpdateNewMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.investors}
                    component={InvestorsMediaInvestor}
                  />
                  {/* // */}
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.investor}
                    component={InvestorsHome}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.investorOportunity}
                    component={InvestorsOportunity}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.InvestorNovelties}
                    component={InvestorNovelties}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.InvestorMyNovelties}
                    component={InvestorMyNovelties}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.investorView}
                    component={SeeNewInvestors}
                  />
                  {/* // */}
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.createInvestor}
                    component={CreateInvestorMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.updateInvestor}
                    component={UpdateInvestorMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.entrepreneurships}
                    component={EntrepreneurshipMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.createEntrepreneurship}
                    component={CreateEntrepreneurMediaInvestor}
                  />
                  <PrivateRoute
                    exact
                    path={routes.mediaInvestor.updateEntrepreneurship}
                    component={UpdateEntrepreneurMediaInvestor}
                  />

                  {/* MediaSmart */}
                  <PrivateRoute exact path={routes.mediaSmart.clients} component={ClientsMediaSmart} />
                  <PrivateRoute exact path={routes.mediaSmart.createClient} component={CreateClientsMediaSmart} />
                  <PrivateRoute exact path={routes.mediaSmart.updateClient} component={UpdateClientsMediaSmart} />
                  <PrivateRoute exact path={routes.mediaSmart.users} component={UsersMediaSmart} />
                  <PrivateRoute exact path={routes.mediaSmart.createUser} component={CreateUsersMediaSmart} />
                  <PrivateRoute exact path={routes.mediaSmart.updateUser} component={UpdateUsersMediaSmart} />

                  {/* MediaHub */}
                  <PrivateRoute exact path={routes.mediaDocs.news} component={NewsMediaDocs} />
                  <PrivateRoute exact path={routes.mediaDocs.createNew} component={CreateNewMediaDocs} />
                  <PrivateRoute exact path={routes.mediaDocs.updateNew} component={UpdateNewMediaDocs} />
                  <PrivateRoute exact path={routes.mediaDocs.members} component={MembersMediaDocs} />
                  <PrivateRoute exact path={routes.mediaDocs.createMembers} component={CreateMemberMediaDocs}/>
                  <PrivateRoute exact path={routes.mediaDocs.updateMembers} component={UpdateMemberMediaDocs}/>
                  <PrivateRoute exact path={routes.mediaDocs.entrepreneurships} component={EntrepreneurshipMediaDocs} />
                  <PrivateRoute exact path={routes.mediaDocs.createEntrepreneurship} component={CreateEntrepreneurMediaDocs} />
                  <PrivateRoute exact path={routes.mediaDocs.updateEntrepreneurship} component={UpdateEntrepreneurMediaDocs}/>

                  {/* // */}
                  <PrivateRoute exact path={routes.mediaDocs.generalDoc} component={MembersGenerals} />
                  <PrivateRoute exact path={routes.mediaDocs.developmentDoc} component={MembersDevelopments} />
                  <PrivateRoute exact path={routes.mediaDocs.memberDoc} component={MembersDocs} />
                  <PrivateRoute exact path={routes.mediaDocs.viewDoc} component={SeeDoc} />
                  <Footer />
                </article>
              </div>
            </PrivateRoute>
            <Redirect to={routes.session.login} />
          </Switch>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
