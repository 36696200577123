import React, { useState, useEffect } from "react";
import Breadcrumb from "../../Breadcrumb";

import Wrapper_1 from "../../Wrapper_1";
import { routes } from "../../../../static_data/routes";

import { TAG_ADD_ACTION } from '../../../../redux/tagsDucks'
import {CATEGORY_ADD_ACTION,CATEGORIES_GET_ACTION} from '../../../../redux/categoryDucks'

import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";

export default function NewFilter() {

  const breadcrumbItems = [
    {
      text: "MediaTeam®",
      route: routes.base,
    },
    {
      text: "Filtros",
      route: routes.mediaTeam.filters,
    },
    {
      text: "Nuevo filtro",
      route: routes.mediaTeam.createFilter,
    },
  ];

  const history = useHistory()

  const dispatch = useDispatch();
  const response = useSelector(store => store.tags.response)
  const user_logged = useSelector(store => store.users.user_logged)
  const categories = useSelector(store => store.categories.categories)
 
  const [datos,setDatos] = useState({
    name:'',
    user_id:0
  });

  useEffect(() => {
    if(response.success){
      history.push("/app/media-team/filtros")
    }
  },[response])

  useEffect(() => {
    if(user_logged){
      setDatos({
        ...datos,
        user_id : user_logged.id, 
      })
    }
  },[user_logged])

  const handleCreateFilter = (e) => {
    e.preventDefault();
    dispatch(TAG_ADD_ACTION(datos));
  }

  const handleUpdateTag = (e) => {
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
      user_id : user_logged.id, 
      categories:getCategory("blog")
    })
  }

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  return (
    <Wrapper_1 breadcrumbItems={breadcrumbItems} title="Nuevo filtro">
      <form onSubmit={handleCreateFilter} className="content-mobile-padding w-100">
        <div className="d-flex flex-column align-items-start w-100">
          <label>Nombre</label>
          <input name="name" onChange={handleUpdateTag} type="text" className="common-input w-100 mxw-500 mb-2" />
          <button className="blue-button mt-4">Guardar</button>
        </div>
      </form>
    </Wrapper_1>
  );
}
