import React, { useState, useEffect } from "react";
import Wrapper_2 from "../../Wrapper_2";
import SearchInput from "../../../Globals/SearchInput";
import Table from "../../Table";

import { routes } from "../../../../static_data/routes";

import { useDispatch, useSelector } from "react-redux";
import { CATEGORIES_GET_ACTION} from '../../../../redux/categoryDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'

import DeleteModalCategory from "./DeleteModalCategory"

import { useHistory,useParams } from "react-router-dom";


// Mock data
const entrepreneurships = [
  {
    name: "MediaHaus 1",
    creator: "Hernán Obarrio",
    date: "01/01/2021",
  },
  {
    name: "MediaHaus 1",
    creator: "Hernán Obarrio",
    date: "01/01/2021",
  },
  {
    name: "MediaHaus 1",
    creator: "Hernán Obarrio",
    date: "01/01/2021",
  },
  {
    name: "MediaHaus 1",
    creator: "Hernán Obarrio",
    date: "01/01/2021",
  },
  {
    name: "MediaHaus 1",
    creator: "Hernán Obarrio",
    date: "01/01/2021",
  },
];

export default function Entrepreneurship() {
  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "MediaHub",
      route: routes.mediaInvestor.about,
    },
    {
      text: "Emprendmientos",
      route: routes.mediaDocs.entrepreneurships,
    },
  ];

  const [searcher, setSearcher] = useState("");

  return (
    <Wrapper_2
      title="Emprendimientos"
      breadcrumbItems={breadcrumbItems}
      rightLink={{
        route: routes.mediaDocs.createEntrepreneurship,
        text: "Nuevo emprendimiento",
        icon: "addIcon",
      }}
    >
      <div className="content-mobile-padding w-100p">
        <SearchInput setSearcher={setSearcher} placeholder="Buscar" className="w-50p mb-2r" />

        <div className="tables-wrapper">
          <EntrepreneurshipTable searcher={searcher} entrepreneurships={entrepreneurships} />
        </div>
      </div>
    </Wrapper_2>
  );
}

function EntrepreneurshipTable({ searcher, entrepreneurships }) {

  const dispatch = useDispatch()

  const loading = useSelector(store => store.tags.loading)
  const user_logged = useSelector(store => store.users.user_logged)
  const tags = useSelector(store => store.tags.tags)
  const categories = useSelector(store => store.categories.categories)

  const history = useHistory()

  useEffect(() => {
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
  },[])

  const getTime_text = (time) => {
    const spliter = time.split('T');

    const fecha = spliter[0].split('-');

    const hora_spliter = spliter[1].split('.');
    const hora = hora_spliter[0].split(':')

    // const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
    const date_time = fecha[2] + "/"+ fecha[1] + "/" + fecha[0] + " " + hora[0] + ":" + hora[1] + ":" + hora[2];
    return date_time;
  }


  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  return (
    <Table
      TitleButton={``}
      TableHeader={
        <tr className="text-nowrap">
          <th className="width-300">Nombre del emprendimiento</th>
          <th>Creador</th>
          <th>Fecha</th>
          <th>Acciones</th>
        </tr>
      }
      
      TableRows={tags?.filter(element => element?.categories?.find(element => element.id === getCategory("docs"))).map((cat, i) => (
        cat.name.includes(searcher) ?
        <tr key={i}>
          <td>{cat.name}</td>
          <td>
            <img
              src={process.env.REACT_APP_BASE_URL + (user_logged.profile_image ? user_logged.profile_image : "/storage/no-profile.jpg")}
              alt="creador del recurso"
              className="me-2"
              style={{borderRadius:"100%"}}
              width="35px"
              height="35px"
            />
            {user_logged.name}
          </td>
          <td>
            {getTime_text(cat.created_at)}
          </td>
          <td>
            <button className="button-no-styled ">
              <button onClick={() => history.push("etiquetas/actualizar-etiqueta/" + cat.id)} style={{border:"none",background:"transparent"}} ><i className="icon-edit-icon me-0 me-lg-3"></i></button>
              <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#category-"+cat.id}><i className="icon-delete"></i></button>
              <DeleteModalCategory category={{id:cat.id,name:cat.name}} />
            </button>
          </td>
        </tr>
        :''
      ))}
    />
  );
}
