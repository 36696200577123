import axios from 'axios'

// Constantes
const dataInicial = {
    posts:[],
    posts_private:[],
    investors_post:[],
    post:{},
    tags:[],
    loading:false,
    loading_absolute:false,
    response:{},
}

// Types
const LOADING_POSTS = 'LOADING_POSTS'
const LOADING = 'LOADING'
const LOADING_POSTS_ABSOLUTE = 'LOADING_POSTS_ABSOLUTE'
const GET_POSTS = 'GET_POSTS'

const GET_PRIVATE_POSTS = 'GET_PRIVATE_POSTS'
const GET_INVESTORS_POSTS = 'GET_INVESTORS_POSTS'
const DELETE_INVESTORS_POSTS = 'DELETE_INVESTORS_POSTS'

const GET_POST = 'GET_POST'
const ADD_POST = 'ADD_POST'
const ADD_FILE = 'ADD_FILE'
const ADD_FILES  = 'ADD_FILES'
const DELETE_FILE = 'DELETE_FILE'
const UPDATE_POST = 'UPDATE_POST'
const UPDATE_IMAGE_POST = 'UPDATE_IMAGE_POST'
const UPDATE_POSITION = 'UPDATE_POSITION'
const DELETE_POST = 'DELETE_POST'

const GET_POSTS_ERROR = 'GET_POSTS_ERROR'
const GET_FILE_ERROR = ' GET_FILE_ERROR'
const CLEAR_STATUS = 'CLEAR_STATUS'


const CLEAR_POST = 'CLEAR_POST'
const CLEAR_INVESTORS = 'CLEAR_INVESTORS'
const CLEAR_POST_PRIVATE = 'CLEAR_POST_PRIVATE'

const GET_POST_TYPES = 'GET_POST_TYPES'

// Reducer
export default function postReducer(state = dataInicial, action){
    switch(action.type){
        case CLEAR_STATUS:{ //Clear status
            return {
                ...state, response : {},
            }
        }
        case CLEAR_POST:{ //Clear post
            return {
                ...state, post : {},
            }
        }
        case CLEAR_POST_PRIVATE:{ //Clear post
            return {
                ...state, posts_private : [],
            }
        }
        case CLEAR_INVESTORS:{ //Clear post
            return {
                ...state, investors_post : [],
            }
        }
        case LOADING_POSTS:{ //Loading
            return {
                ...state, loading : true,
            }
        }
        case LOADING_POSTS_ABSOLUTE:{ //Loading
            return {
                ...state, loading_absolute : true,
            }
        }
        case GET_POSTS:{ //Get all post
            return {
                ...state, posts : action.payload.data.data.posts.data,
                loading:false,
                loading_absolute:false,
            }
        }
        case GET_PRIVATE_POSTS:{ //Get all post
            return {
                ...state, posts_private : action.payload,
                loading:false,
                loading_absolute:false,
            }
        }
        case GET_INVESTORS_POSTS:{ //Get all post
            return {
                ...state, investors_post : action.payload,
                loading:false,
                loading_absolute:false,
            }
        }
        case DELETE_INVESTORS_POSTS:{ //Get all post
            const postsUpdate = state.posts.find(post => post.id === action.payload)
            postsUpdate.groups_count = 0
            return {
                ...state,
                posts: [...state.posts.filter(post => post.id !== action.payload),postsUpdate], 
                investors_post : [],
                loading:false,
                loading_absolute:false,
            }
        }
        case GET_POST: {// Get details client
            return {
                ...state, post : action.payload,
                loading:false,
                loading_absolute:false,
            }
        }
        case ADD_POST:{ // Add post
            return {...state, posts: [...state.posts,action.payload.data.data.post],                
                response:{
                    status:action.payload.status,
                    statusText:"La novedad fue creada correctamente.",
                    success:true
                },
                loading:false,
                loading_absolute:false
            }
        }
        case ADD_FILE:{ // Add File
            return {...state, 
                post: {...state.post,
                    files: [...state.post.files,action.payload.data.data.file]},                
                response:{
                    status:action.payload.status,
                    statusText:"El archivo fue agregado correctamente.",
                    success:true,
                    notRealod:true,
                },
                loading:false,
                loading_absolute:false
            }
        }
        case ADD_FILES:{ // Add File
            return {...state, 
                loading:false,
                loading_absolute:false
            }
        }
        case DELETE_FILE:{ // Delete file
            return {...state, 
                post: {
                    ...state.post,
                    files: state.post.files.filter(file => file.id !== action.payload)
                },                
                response:{
                    status:200,
                    statusText:"El archivo fue eliminado correctamente.",
                    success:true,
                    notRealod:true,
                },
                loading:false,
                loading_absolute:false
            }
        }
        case UPDATE_POST:{ // Update 
            return {...state,
                posts: [...state.posts.filter(post => post.id !== action.payload.data.data.post.id),action.payload.data.data.post], 
                response:{
                    status:action.payload.status,
                    statusText:"La novedad fue actualizada correctamente.",
                    success:true,
                },
                loading:false,
                loading_absolute:false,
            }
        }
        case UPDATE_POSITION:{ // Update 
            return {...state,
                // posts: [...state.posts.filter(post => post.id !== action.payload.data.data.post.id),action.payload.data.data.post], 
                loading:false,
                loading_absolute:false,
            }
        }
        case UPDATE_IMAGE_POST:{ // Update 
            return {...state,
                posts: [...state.posts.filter(post => post.id !== action.payload.data.data.post.id),action.payload.data.data.post], 
                loading:false,
                loading_absolute:false,
                response:{
                    success:true,
                    statusText:"La imagen de portada fue actualizada correctamente.",
                    status:200,
                }
            }
            
        }
        case DELETE_POST:{ // Delete
            return {...state, posts: [...state.posts.filter((post) => post.id !== action.payload.data.data.post.id)],                
                response:{
                    status:action.payload.status,
                    statusText:"La novedad fue eliminada correctamente.",
                    success:true
                },
                loading:false
            }
        }
        case GET_POSTS_ERROR:{ // Set errors
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
                loading:false,
                loading_absolute:false
            }
        }
        case GET_FILE_ERROR:{ // Set errors
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
                loading:false,
                loading_absolute:false
            }
        }
        default:
            return state;
    }
}
// Actions
export const CLEAR_STATUS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_STATUS,payload: true})
}
export const CLEAR_POST_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_POST,payload: true})
}
export const POSTS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/posts/search?limit=100&clients=' + getState().users.user_logged.client.id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_POSTS,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const POST_GET_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/posts/'+ id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({
            type: GET_POST,
            payload: res.data.data.post
        })
    } catch (error) {
    dispatch({type: GET_POSTS_ERROR,payload:error.response})
    console.log(error)
    }
}
export const POST_ADD_ACTION = (data,files = []) => async (dispatch, getState) => {
    
    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})
    
    let max=0

    var dataSend = new FormData();
    dataSend.append("title", data.title);
    dataSend.append("user_id", data.user_id);
    dataSend.append("excerpt", data.excerpt);
    data.image_caption ? dataSend.append("image_caption", data.image_caption) : dataSend.append("image_caption","Sin especificar")
    dataSend.append("image", data.image);
    dataSend.append("content", data.content);
    dataSend.append("publish_date", data.publish_date ? data.publish_date.getFullYear() + "-" + (data.publish_date.getMonth() + 1) + "-" + data.publish_date.getDate() + " " + "00:00:00" : data.publish_date)
    for (const iterator of data.tags) {
        dataSend.append("tags[]", iterator);
    }
    dataSend.append("featured", data.featured);
    dataSend.append("active", getState().users.user_logged.level === '3' ? 0 : data.active);
    dataSend.append("categories[]", data.categories);

    getState().posts.posts.map((post) => ( // Saco máximo
        max = post.position > max ? post.position : max
    ))

    dataSend.append("position", max + 1000);

    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/posts',dataSend,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        if(files){
            if(files?.length > 0){
                dispatch(FILE_ADD_ACTION({"id":res.data.data.post.id,"file":files},true))
            }
        }
        if(data?.users?.length > 0){
            dispatch(GROUP_ADD_ACTION({"id":res.data.data.post.id,"users":data.users}))
        }
        dispatch({type: ADD_POST, payload: res})
    } catch (error) {
        let errors_texts = []
        console.log(error)
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const POST_UPDATE_ACTION = (data,id) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})
    let dataSend = {}
    data.title ? dataSend.title = data.title : dataSend.title = 'Sin especificar'
    data.excerpt ? dataSend.excerpt = data.excerpt : dataSend.excerpt = 'Sin especificar'
    data.image_caption ? dataSend.image_caption = data.image_caption : dataSend.image_caption = 'Sin especificar'
    data.content ? dataSend.content = data.content : datsSend.content = 'Sin especificar'
    dataSend.featured = data.featured
    dataSend.publish_date = data.publish_date.getFullYear() + "-" + (data.publish_date.getMonth() + 1) + "-" + data.publish_date.getDate() + " " + "00:00:00"
    dataSend.active = data.active
    // if(data.tags.length > 0){
    dataSend.tags = data.tags
    // }
    
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/posts/'+id,dataSend,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        if(data?.users?.length > 0){
            if(getState().posts.investors_post?.id > 0){
                dispatch(GROUP_UPDATE_ACTION({"id":getState().posts.investors_post?.id,"users":data?.users,"post_id":getState().posts.post?.id}))
            }
            else{
                dispatch(GROUP_ADD_ACTION({"id":id,"users":data?.users}))
            }
        }
        else{
            dispatch(GROUP_DELETE_ACTION(getState().posts.investors_post?.id,getState().posts.post.id))
        }
        dispatch({
            type: UPDATE_POST,
            payload: res,
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const POST_UPDATE_CATEGORIES_ACTION = (categories,id) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/posts/'+id,{categories},{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: UPDATE_POST,
            payload: res,
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }

}
export const POST_TOGGLE_ACTIVE_ACTION = (data,id) => async (dispatch, getState) => {
    // dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})
    let dataSend = {}
    dataSend.active = !data.active
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/posts/'+id,dataSend,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        if(data?.users?.length > 0){
            if(getState().posts.investors_post?.id > 0){
                dispatch(GROUP_UPDATE_ACTION({"id":getState().posts.investors_post?.id,"users":data?.users,"post_id":getState().posts.post?.id}))
            }
            else{
                dispatch(GROUP_ADD_ACTION({"id":id,"users":data?.users}))
            }
        }
        else{
            dispatch(GROUP_DELETE_ACTION(getState().posts.investors_post?.id,getState().posts.post.id))
        }
        dispatch({
            type: UPDATE_POST,
            payload: res,
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const POST_DELETE_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/posts/'+id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: DELETE_POST,
            payload: res
        })
    } catch (error) {
        dispatch({
            type: GET_POSTS_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrió un error inesperado."],
                success:false
            }
        })
        console.log(error)
    }
}
export const POST_DUPLICATE_ACTION = (post) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    try {
        POST_ADD_ACTION(post)
    } catch (error) {
        dispatch({
            type: GET_POSTS_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrió un error inesperado."],
                success:false
            }
        })
        console.log(error)
    }
}
export const POSTS_UPDATE_FEATURED_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    let response = {}
    response.featured = data
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/posts/'+id,response,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        console.log(res)
        dispatch({
            type: UPDATE_POST,
            payload: res,
        })
    } catch (error) {
        console.log(error)
        dispatch(
            {
                type: GET_POSTS_ERROR,
                payload:{
                    status:400,
                    statusText:'Ocurrió un eror',
                    success:false,
                }
            })
    }
}
export const POST_UPDATE_IMAGE_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})
    var dataSend = new FormData();
    dataSend.append("image", data);
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/posts/'+id+'/image',dataSend,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        dispatch({
            type: UPDATE_IMAGE_POST,
            payload: res
        })
    } catch (error) {
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:error.response.status,
                statusText:error.response.statusText,
                success:false
            }
        })
        console.log(error)
    }
}
export const POST_UPDATE_POSITION_ACTION = (id,data) => async (dispatch, getState) => {
    // dispatch({type: LOADING_POSTS,payload: true})
    let response = {}
    response.position = data
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/posts/'+ id , response,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        console.log(res)
        dispatch({
            type: UPDATE_POSITION,
            payload: res,
        })
    } catch (error) {
        // console.log(error)
        // dispatch(
        //     {
        //         type: GET_POSTS_ERROR,
        //         payload:{
        //             status:400,
        //             statusText:['Ocurrió un eror'],
        //             success:false,
        //         }
        //     })
    }
}

// Files
export const FILE_ADD_ACTION = (data,isArray = false) => async (dispatch, getState) => {

    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})

    if(isArray){
        for (const iterator of data.file) {
            var dataSend = new FormData();
            dataSend.append("post_id", data.id);
            dataSend.append("files[]", iterator);
            try {
                const res = await axios.post('https://dev.mediacore.com.ar/api/v1/files',dataSend,{
                    headers:{
                        "Authorization": 'Bearer ' + getState().users.user_logged.token,
                        "Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    },
                })
                dispatch({type: ADD_FILES, payload: res})
            } catch (error) {
                console.log(error)
                dispatch(
                    {type: GET_FILE_ERROR,
                    payload:{
                        status:"404",
                        statusText:["Ocurrió un error al subir el archivo."],
                        success:false
                    }
                })
            }
        }
    } 
    else{
        var dataSend = new FormData();
        dataSend.append("post_id", data.id);
        dataSend.append("files[]", data.file);
        try {
            const res = await axios.post('https://dev.mediacore.com.ar/api/v1/files',dataSend,{
                headers:{
                    "Authorization": 'Bearer ' + getState().users.user_logged.token,
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json"
                },
            })
            dispatch({type: ADD_FILE, payload: res})
        } catch (error) {
            console.log(Object.values(error?.response?.data)[0][0].includes("15360"))
            dispatch(
                {type: GET_FILE_ERROR,
                payload:{
                    status:"404",
                    statusText:Object.values(error?.response?.data).length > 0 ? (Object.values(error?.response?.data)[0][0].includes("15360") ? ['El archivo debe pesar menos de 15MB.'] : Object.values(error?.response?.data)[0]) :["Ocurrió un error al subir el archivo."],
                    success:false
                }
            })
        }
    }
    
}
export const FILE_DELETE_ACTION = (data) => async (dispatch, getState) => {
    
    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})
    
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/files/' + data ,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        dispatch({
            type: DELETE_FILE,
            payload: data
        })
    } catch (error) {
        console.log(error)
        // let errors_texts = []
        // for (const iterator of Object.values(error.response.data.errors)) {
        //     for (const text of iterator){
        //         errors_texts.push(text)
        //     }
        // }
        // dispatch(
        //     {type: GET_POSTS_ERROR,
        //     payload:{
        //         status:error.response.status,
        //         statusText:errors_texts,
        //         success:false
        //     }
        // })
    }
}

// Groups
export const GROUP_ADD_ACTION = (data) => async (dispatch, getState) => {

    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})

    var dataSend = new FormData();
    
    dataSend.append("user_id", getState().users.user_logged.id);
    dataSend.append("posts[]", data.id);
    for (const iterator of data.users) {
        dataSend.append("users[]", iterator);
    }
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/groups',dataSend,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
    } catch (error) {
        console.log(error)
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:"404",
                statusText:["Ocurrió un error al crear la novedad."],
                success:false
            }
        })
    }

}
export const GROUP_UPDATE_ACTION = (data) => async (dispatch, getState) => {

    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})

    var dataSend = {}

    dataSend.users = data.users
    dataSend.posts = [data.post_id]

    if(data.id === undefined){return null}

    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/groups/' + data.id ,dataSend,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                // "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
    } catch (error) {
        console.log(error)
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:"404",
                statusText:["Ocurrió un error al crear la novedad."],
                success:false
            }
        })
    }

}
export const GROUP_DELETE_ACTION = (idGroup,idPost) => async (dispatch, getState) => {

    dispatch({type: LOADING_POSTS,payload: true})
    dispatch({type: LOADING_POSTS_ABSOLUTE,payload: true})

    if(idGroup === undefined){return null}

    var dataSend = new FormData();
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/groups/'+ idGroup,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        dispatch({
            type: DELETE_INVESTORS_POSTS,
            payload: idPost
        })
    } catch (error) {
        console.log(error)
        dispatch(
            {type: GET_POSTS_ERROR,
            payload:{
                status:"404",
                statusText:["Ocurrió un error al crear la novedad."],
                success:false
            }
        })
    }

}
export const POSTS_USER_GET_ACTION = (user_id) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/groups/search?users=' + user_id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        let posts_by_user = []
        for (const iterator of res.data.data.groups.data) {
            console.log(iterator)
            if(iterator.posts[0]?.categories?.length > 0){
                posts_by_user.push(iterator.posts[0])
            }
        }
        dispatch({type: GET_PRIVATE_POSTS,payload: posts_by_user})
        // dispatch({type: GET_POSTS,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const USERS_BY_POST_GET_ACTION = (post_id) => async (dispatch, getState) => {
    dispatch({type: LOADING_POSTS,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/groups/search?posts=' + post_id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        // console.log(res.data.data.groups.data[res.data.data.groups.data.length - 1].users)
        // console.log(res.data.data.groups.total)
        if(res.data.data.groups.total > 0){
            dispatch({type: GET_INVESTORS_POSTS,payload:res.data.data.groups.data[res.data.data.groups.data.length - 1]})
        }
    } catch (error) {
        console.log(error)
    }
}
export const CLEAR_INVESTORS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_INVESTORS})
}
export const CLEAR_POST_PRIVATE_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_POST_PRIVATE})
}

