import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/svgs/brand-logo.svg";
import Wrapper_2 from "../../Wrapper_2";
import {useSelector} from 'react-redux'

import { routes } from "../../../../static_data/routes";

export default function CardNew({
    id,
    image,
    title,
    excerpt,
    publish_date,
    isFirst = false,
    route,
  }) {

  const {user_logged} = useSelector(store => store.users)

  const splitDate = (date) => {
    if(!date){return null}
    const splitter = date.split(" ")[0]
    const DDMMYYYY = splitter.split("-")
    return `${DDMMYYYY[2]}/${DDMMYYYY[1]}/${DDMMYYYY[0]}`
  }

  const getPublish_text = (time) => {
    if(time){
      const spliter = time.split(' ');
      const fecha = spliter[0].split('-');
      switch(fecha[1]){
        case '01':
          return `${fecha[2]} de Enero ${fecha[0]}`
        case '02':
          return `${fecha[2]} de Febrero ${fecha[0]}`
        case '03':
          return `${fecha[2]} de Marzo ${fecha[0]}`
        case '04':
          return `${fecha[2]} de Abril ${fecha[0]}`
        case '05':
          return `${fecha[2]} de Mayo ${fecha[0]}`
        case '06':
          return `${fecha[2]} de Junio ${fecha[0]}`
        case '07':
          return `${fecha[2]} de Julio ${fecha[0]}`
        case '08':
          return `${fecha[2]} de Agosto ${fecha[0]}`
        case '09':
          return `${fecha[2]} de Septiembre ${fecha[0]}`
        case '10':
          return `${fecha[2]} de Octubre ${fecha[0]}`
        case '11':
          return `${fecha[2]} de Noviembre ${fecha[0]}`
        case '12':
          return `${fecha[2]} de Diciembre ${fecha[0]}`
      }
    }
  }

  return (
    <div className="new-card position-relative justify-content-between">
      {/* <img className="img-card" src={image} alt={title} /> */}
      <img className="img-card" src={process.env.REACT_APP_BASE_URL + (image ? image : '')} alt={title} />
      <div>
        <h2 className="title-2 font-1  text-white mb-3">{title}</h2>
        <p className="font-1 text-white paragraph-1">
          {excerpt?.length < 120
            ? excerpt
            : excerpt?.slice(0, 120) + "..."}
        </p>
        <div className="button-date-container d-flex justify-content-between align-items-center">
          <div className="">
            <Link
              to={"/app/"+  user_logged?.client?.name.replaceAll(" ","-").toLowerCase() +"/inversor/novedad/" + id}
              className="blue-button see-more outline-button small-font text-blue-1 text-decoration-none"
            >
              Leer más
            </Link>
          </div>
          <div className="p-0 m-0"><span className="text-blue-1 font-1 xs-font date-tag">{publish_date?getPublish_text(publish_date):''}</span></div>
        </div>
      </div>
      {/* {isFirst && (
        <img
          className="brand-logo-in-banner"
          height="60px"
          src={Logo}
          alt="mediahaus logo"
        />
      )} */}
    </div>
  );
}
