import axios from 'axios'

// Constantes
const dataInicial = {
    categories:[],
    category:{},
    loading_category:false,
    loading_absolute:false,
    response:{},
}

// Types
const LOADING_CATEGORY = 'LOADING_CATEGORY'
const LOADING_CATEGORY_ABSOLUTE = 'LOADING_CATEGORY_ABSOLUTE'
const GET_CATEGORIES = 'GET_CATEGORIES'
const GET_CATEGORY = 'GET_CATEGORY'
const ADD_CATEGORY = 'ADD_CATEGORY'
const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
const DELETE_CATEGORY = 'DELETE_CATEGORY'

const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'
const CLEAR_STATUS = 'CLEAR_STATUS'

// Reducer
export default function categoryReducer(state = dataInicial, action){
    switch(action.type){
        case CLEAR_STATUS:{ //Clear status
            return {
                ...state, response : {},
            }
        }
        case LOADING_CATEGORY:{ //Clear status
            return {
                ...state, loading_category : true,
            }
        }
        case LOADING_CATEGORY_ABSOLUTE:{ //Clear status
            return {
                ...state, loading_absolute : true,
            }
        }
        case GET_CATEGORIES:{ //Get all categories
            return {
                ...state, categories : action.payload.data.data.categories.data,
                loading_category:false 
            }
        }
        case GET_CATEGORY: {// Get details category
            return {
                ...state, category : action.payload,
                loading:false 
            }
        }
        case ADD_CATEGORY:{ // Add categories
            return {...state, categories: [...state.categories,action.payload],                
                response:{
                    status:201,
                    statusText:"La etiqueta de emprendimientos ha sido creada correctamente.",
                    success:true
                },
                loading_category:false,
                loading:false,
                loading_absolute:false,
            }
        }
        case UPDATE_CATEGORY:{ // Update 
            return {...state,
                tags: [...state.categories.filter(category => category.id !== action.payload.data.data.category.id),action.payload.data.data.category], 
                response:{
                    status:action.payload.status,
                    statusText:"El emprendimiento fue actualizado correctamente.",
                    success:true,
                },
                loading:false,
                loading_absolute:false,
            }
        }
        case DELETE_CATEGORY:{ // Delete
            return {...state, categories: [...state.categories.filter((category) => category.id !== action.payload.data.data.category.id)],                
                response:{
                    status:action.payload.status,
                    statusText:"El filtro fue eliminado correctamente.",
                    success:true
                },
                loading:false,
                loading_absolute:false,
            }
        }
        case GET_CATEGORIES_ERROR:{ // Set errors
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
                loading:false,
                loading_absolute:false,
            }
        }
        default:
            return state;
    }
}
// Actions
export const CLEAR_STATUS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_STATUS,payload: true})
}
export const CATEGORIES_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_CATEGORY,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/categories',{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_CATEGORIES,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const CATEGORY_GET_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_CATEGORY,payload: true})
    try {
    const res = await axios.get('https://dev.mediacore.com.ar/api/v1/categories/'+ id + "",{
        headers:{
            'Authorization': 'Bearer ' + getState().users.user_logged.token
        },
    })
    console.log(res);
        dispatch({
        type: GET_CATEGORY,
        payload: res.data.data.category
    })
        } catch (error) {
    dispatch({type: GET_CATEGORIES_ERROR,payload:error.response})
    console.log(error)
    }
}
export const CATEGORY_ADD_ACTION = (data) => async (dispatch, getState) => {
    
    dispatch({type: LOADING_CATEGORY,payload: true})
    dispatch({type: LOADING_CATEGORY_ABSOLUTE,payload: true})
    
    var dataSend = new FormData();
    dataSend.append("name", data.name);
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/categories',dataSend,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        console.log(res.data.data)
        dispatch({
            type: ADD_CATEGORY,
            payload: res.data.data.category
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_CATEGORIES_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const CATEGORY_UPDATE_ACTION = (data,id) => async (dispatch, getState) => {
    dispatch({type: LOADING_CATEGORY,payload: true})
    dispatch({type: LOADING_CATEGORY_ABSOLUTE,payload: true})
    
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/categories/'+id,data,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: UPDATE_CATEGORY,
            payload: res,
        })
    } catch (error) {
        let errors_texts = []
        console.log(error.response)
        // for (const iterator of Object.values(error.response.data)) {
        //     for (const text of iterator){
        //         errors_texts.push(text)
        //     }
        // }
        dispatch(
            {type: GET_CATEGORIES_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrio un error al actualizar la etiqueta"],
                success:false
            }
        })
    }
}
export const CATEGORY_DELETE_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_CATEGORY,payload: true})
    dispatch({type: LOADING_CATEGORY_ABSOLUTE,payload: true})
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/categories/'+id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        console.log(res)
        dispatch({
            type: DELETE_CATEGORY,
            payload: res
        })
    } catch (error) {
        console.log(error.response)
        dispatch({
            type: GET_CATEGORIES_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrió un error inesperado."],
                success:false
            }
        })
        console.log(error)
    }
}
