import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ModalDelete from '../Content/MediaBlog/News/DeleteModalNew'
import ModalDuplicate from '../Content/MediaBlog/News/DuplicateModalNew'
import { CATEGORIES_GET_ACTION } from '../../redux/categoryDucks'
import {POST_UPDATE_ACTION} from '../../redux/blogsDucks'

import { useEffect } from "react";

import {useDispatch, useSelector} from 'react-redux'
import {POST_UPDATE_CATEGORIES_ACTION,POST_TOGGLE_ACTIVE_ACTION,POSTS_UPDATE_FEATURED_ACTION} from '../../redux/blogsDucks'


const TrWrapper = styled.tr`
  background: blue;
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: center;
      width: 200px;
    }
  }
`;

const Handle = styled.div`
  margin-right: 0px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => <Handle className="handle"><i className="icon-drag-icon"></i></Handle>);

const TableRow = ({ value,index,className,investors,members,team }) => {
    const dispatch = useDispatch()

    const user_logged = useSelector(store => store.users.user_logged)
    const categories = useSelector(store => store.categories.categories)

    const toggle_featured = (data) => {
      if(data === false || data === 0 || data === "0"){
        return "1";
      }
      return "0";
    }
    const getTime_Function = (time) => {
      const spliter = time.split('T');
  
      const fecha = spliter[0].split('-');
  
      const hora_spliter = spliter[1].split('.');
      const hora = hora_spliter[0].split(':')
  
      const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
      // const date_time = fecha[0] + "/"+ fecha[1] + "/" + fecha[2] + " " + hora[0] + ":" + hora[1] + ":" + hora[2];
  
      return date_time;
    }
    const getTime_text = (time) => {
      const spliter = time.split('T');
  
      const fecha = spliter[0].split('-');
  
      const hora_spliter = spliter[1].split('.');
      const hora = hora_spliter[0].split(':')
  
      // const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
      const date_time = fecha[2] + "/"+ fecha[1] + "/" + fecha[0] + " " + hora[0] + ":" + hora[1] + ":" + hora[2];
  
      return date_time;
  
    }
    const getCategory = (category) => {
      return categories?.find(item => item.name === category)?.id
    }
    const toggle_aprove_novelty = (novelty) => {
      let categories_response_id = []
      let categories = []
      if(novelty?.categories?.find(element => element.id === getCategory("blog"))){
        categories = novelty?.categories.filter(element => element.id !== getCategory("blog"))
      }
      else{
        categories = [...novelty?.categories,{id:getCategory("blog"),name:"blog"}]
      }
      categories_response_id = categories.map(element => element.id)

      return dispatch(POST_UPDATE_CATEGORIES_ACTION(categories_response_id,novelty.id))
      // return categories
    }


    // Validation functions for rendering (blog,investors,members,team)

    const aproveChecker = (value) => { // i check if the novelty(team) is aproved in mediaBlog
      return value.categories.find(element => element.id === getCategory('blog'))
    }

    const checkTeamInBlog = (value) => { // i check if the novelty have category "team" and if the view is mediaBlog
      return (!team && !investors && !members && value.categories.find(element => element.id === getCategory("team")))
    }

    const canActive = () => {
      return user_logged?.level === '9' || user_logged?.level === '10'
    } 

  
    useEffect(() => {
      if(categories.length === 0){
        dispatch(CATEGORIES_GET_ACTION())
      }
    },[])

  return (
    <TrWrapper className={className}>
      <td className={"align-middle" + (investors || members ? ' d-none' : '')}>
        <div className={"firstElement" }>
          <RowHandler />
          {index}
        </div>
      </td>
      <td className={"width-custom align-middle d-flex align-items-center "} >
          <div className={((checkTeamInBlog(value) || team) && "min-width-col-desktop")}>{value.title} </div>
          {team ? <div className={"ms-3 d-xxl-flex pill " + (!aproveChecker(value) ? 'disabled' : 'success-pill')}>{(aproveChecker(value) ? 'Aprobado en MediaBlog' : 'Pendiente para MediaBlog') }</div> : '' }
          {checkTeamInBlog(value) ? <div className={"ms-3 d-xxl-flex pill "}>{'MediaTeam'}</div> : '' }
      </td>
      <td className={"text-center check-custom align-middle " + (!canActive() && 'disabled ') + (investors || members ? ' d-none' : '')}>
          <input
              type="checkbox"
              className="checkbox d-none"
              name={"featured-checkbox " + value.id}
              id={"featured-checkbox " + value.id}
              checked={value.featured === "1" || value.featured === 1 || value.featured === true}
              onChange={() => {}}
              // disabled={!check_access("Posts scope for writing records",user_logged.scopes)}
              disabled={!canActive()}
              onClick={() => { dispatch(POSTS_UPDATE_FEATURED_ACTION(value.id,toggle_featured(value.featured)))} }
              />
          <label className="featured-checkbox" for={"featured-checkbox " + value.id}></label>
      </td>
      <td className="text-center width-prefix align-middle">{value.user.name}</td>
      <td className="text-center align-middle">{getTime_text(value.created_at)}</td>
      <td className="text-center align-middle">{getTime_text(value.updated_at)}</td>
      <td className="text-center align-middle">
        {/* <button disabled={!check_access("Posts scope for writing records",user_logged.scopes)} className={"button-no-styled "}> */}
        <button className={"button-no-styled "}>
            {
            // check_access("Posts scope for writing records",user_logged.scopes) ?
                <>
                    {!members && <Link to={"novedades/actualizar-novedad/" + value.id} ><i className="icon-edit-icon me-0 me-lg-3"></i></Link>}
                    {members && <Link to={"recursos/actualizar-comunicacion/" + value.id} ><i className="icon-edit-icon me-0 me-lg-3"></i></Link>}
                    <button className="me-2" style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#post-"+value.id}><i className="icon-delete"></i></button>
                      <div class="dropdown show d-inline-block">
                        <a class="" role="button" id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="icon-three-dots-icon"></i>
                        </a>

                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                          {canActive() && value.categories.find(element => element.id === getCategory("team")) && <a onClick={() => toggle_aprove_novelty(value)} class="dropdown-item">{value.categories.find(element => element.id === getCategory("blog")) ? 'Desaprobar' : 'Aprobar'}</a>}
                          {canActive() && <a onClick={() => dispatch(POST_TOGGLE_ACTIVE_ACTION(value,value.id))} class="dropdown-item">{value.active ? 'Desactivar' : 'Activar'}</a>}
                          <a class="dropdown-item">Duplicar</a>
                        </div>
                      </div>
                    <ModalDelete post={{id:value.id,title:value.title}} />
                    <ModalDuplicate post={value} />
                </>
            }
            
        </button>
      </td>
    </TrWrapper>
  );
};

export default TableRow;
