import React, { useState, useRef, useEffect } from "react";
import Wrapper_1 from "../Wrapper_1";
import { routes } from "../../../static_data/routes";
import { CLIENT_ADD_ACTION, CLIENTS_TYPE_GET_ACTION,MODULES_GET_ACTION } from "../../../redux/clientsDucks"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

import Loading from '../../Globals/Loading'
import toast from "react-hot-toast";

import { Link } from "react-router-dom";

export default function CreateUser() {

  const dispatch = useDispatch();

  const history = useHistory();

  const client_types = useSelector(store => store.clients.client_types)
  const response = useSelector(store => store.clients.response)
  const modules = useSelector(store => store.clients.modules)
  
  const [type, setType] = useState(1);
  const [active, setActive] = useState(false);
  const [datos, setDatos] = useState({
    name:'',
    description:'',
    email:'',
    phone:'',
    main_color:'#000000',
    client_type_id:4,
    logo:'',
    active:1,
    modules:['3'],
    ssl_active:1,
    ga_client_id:'',
    domain_name:'',
    domain_extension:'',
    domain_expiration_date:new Date(),
    tokko_apikey:'',
  })
  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();

  useEffect(() => {
      if(response.success){
        history.push(routes.mediaSmart.clients)
      }
  },[response])

  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    // console.log(picture_profile.current.files[0])
    setDatos({ ...datos, logo: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      console.log(e.target.result)
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.base,
    },
    {
      text: "Clientes",
      route: routes.mediaSmart.clients,
    },
    {
      text: "Nuevo cliente",
      route: routes.mediaSmart.createClient,
    },
  ];

  const handleCreateClient = (e) => {
    e.preventDefault();
    console.log(datos)
    if(datos.logo === ''){
      toast.error("Debes agregar un logo.");
    }
    else{
      dispatch(CLIENT_ADD_ACTION(datos));
    }
  }
  const handleUpdateClient = (e) => {
    if(e.target.name == 'modules'){
      if(datos.modules.find(element => element === e.target.value)){
          const newModules = datos.modules.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newModules, 
          })
          return null
      }
      setDatos({
          ...datos,
          [e.target.name] : [...datos.modules,e.target.value], 
      })
      return null
  }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
    })
  }
  const setExpirationDate = (date) => {
    setDatos({
      ...datos,
      domain_expiration_date:date
    })
  }


  useEffect(() => {
    dispatch(CLIENTS_TYPE_GET_ACTION());
    dispatch(MODULES_GET_ACTION());
  },[])

  return client_types && modules ? (
    <Wrapper_1 title="Nuevo cliente" breadcrumbItems={breadcrumbItems}>
      <form onSubmit={handleCreateClient} className="content-mobile-padding w-100p media-core">
        <div className="grid-3-col">
          <div className="d-flex flex-column ">
            <label className="font-1">Nombre*</label>
            <input className="common-input" name="name" onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Tipo*</label>
            <div className="d-flex gap-0">
              {client_types.filter(element => element.id > 3).map((element,index) => (
                <span
                  className={`font-1 small-font active-button-${
                    datos.client_type_id === element.id ? "blue" : "gray"
                  }`}
                  onClick={() => {
                    handleUpdateClient({target:{name:"client_type_id",value:element.id}});
                  }}
                >
                  {element.name}
                </span>
              ))}
            </div>
          </div>

        </div>
        <div className="grid-3-col mt-3">
          <div className="d-flex flex-column">
            <label className="font-1">Email*</label>
            <input className="common-input" name="email" onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column">
            <label className="font-1">Teléfono*</label>
            <input className="common-input" name="phone" onChange={handleUpdateClient} type="text" />
          </div>

        </div>
        <div className="grid-3-col mt-3">
        <div className="d-flex flex-column">
            <label className="font-1">Descripción*</label>
            <input className="common-input" name="description" onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column">
            <label className="font-1">Color primario*</label>
            <div className="position-relative w-100">
              <input type="text" className="common-input w-100" value={datos.main_color} onChange={(e)=>handleUpdateClient({target:{name:"main_color",value:e.target.value}})} />
              <input
                className="w-100s position-absolute me-2"
                style={{
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                }}
                value={datos.main_color}
                name="main_color"
                onChange={(e)=>handleUpdateClient({target:{name:"main_color",value:e.target.value}})}
                type="color"
              />
            </div>
          </div>   
        </div>
        <div className="grid-3-col mt-3">
          <div className="d-flex flex-column ">
              {/* <FileInput label="Logo" containerClassName="" className="py-3" /> */}
              <div class="content-img">
                <label for="addFile" className="font-1">Subir logo*</label>  
                  <div className={"drag uploader w-100 d-flex " + drag}
                    dragleave={onDragLeave}
                    dragenter={onDragEnter}
                    onDrop={onDrop}
                  >
                    <div className={`d-flex flex-column w-100`}>
                      <span for="addFile" className={`image-picker-button small-font `}>
                        <label style={{color:"#d6d6d6"}} for="addFile">
                          AGREGAR IMAGEN (JPG, PNG 1200x900)
                        </label>
                      </span>
                      <input
                        type="file"
                        name="addFile"
                        className="d-none"
                        ref={picture_profile}
                        onChange={changeFile}
                        id="addFile"
                      />
                    </div>
                    <img
                      className={"mt-0 me-0 ms-3 d-inline-block " + (renderImage ? '' : 'd-none')}
                      src={renderImage ? renderImage : ''}
                      width="150"
                    />{" "}
                  </div>
                </div>
          </div>
        </div>
        <div className="grid-3-col mt-5">
          <div className="d-flex flex-column justify-content-between" >
            <label className="font-1">Nombre del dominio (ej: dominio.com)</label>
            <input className="common-input mb-4" name="domain_name" onChange={handleUpdateClient} type="text" />
            <label className="font-1">Entidad registrante del dominio (Nic.ar, GoDaddy)</label>
            <input className="common-input" name="domain_extension" onChange={handleUpdateClient} type="text" />
          </div>
          <div className="d-flex flex-column justify-content-between" >
            <label className="font-1">Certificado SSL</label>
            <div className="mt-0 mb-4 d-flex">
            <span
              className={`font-1 small-font active-button-${
                (datos.ssl_active === 1 ? "black" : "gray")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"ssl_active",value:1}});
              }}
            >
              Si
            </span>
            <span
              className={`font-1 small-font active-button-${
                (datos.ssl_active !== 0 ? "gray" : "black")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"ssl_active",value:0}});
              }}
            >
              No
            </span>
          </div>
            <label className="font-1">Expiración del dominio</label>
            <div className="common-input calendary-picker">
              <DatePicker dateFormat="yyyy/MM/dd" selected={datos.domain_expiration_date} onChange={(date) => setExpirationDate(date)}  locale="es" />
            </div>
          </div>
        </div>
        <div className="grid-3-col mt-5">
          <div className="d-flex flex-column pt-3" style={{ maxWidth: "200px" }}>
            <h4 className="mb-3">Activación de módulos</h4>
            {modules.map((element, index) => (
              <div className={"d-flex justify-content-between mb-3 " + (element?.id?.toString() === "3" && 'disabled') }>
                <label>{element?.name?.toString() === "mediadata" ? 'MediaTracker' : element.name}®</label>
                <input
                  type="checkbox"
                  id={element.id}
                  name="modules"
                  defaultChecked={datos.modules.find(module => module.toString() === element.id.toString())}
                  value={element.id}
                  disabled={element?.id?.toString() === "3"}
                  onChange={handleUpdateClient}
                  className="checkbox-1 me-1"
                />
                <label className="label-module" htmlFor={element.id}>
                  <div></div>
                </label>
              </div>
            ))}
          </div>
          <div className={"d-flex flex-column pt-3 "}>
            <label className="font-1">Código de seguimiento MediaTracker® (view_id Google Analytics) </label>
            <input className={"common-input"} name="ga_client_id" onChange={handleUpdateClient} type="text" />
            <label className="font-1 mt-5">Api key de Tokko</label>
            <input className={"common-input"} name="tokko_apikey" onChange={handleUpdateClient} type="text" />
          </div>
        </div>
        <div className="new-ticket-inputs-2 mt-1 mt-md-5">
          <label className="font-1">Activo</label>
          <div className="mt-2 mb-4 d-flex">
            <span
              className={`font-1 small-font active-button-${
                (datos.active === 1 ? "black" : "gray")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"active",value:1}});
              }}
            >
              Si
            </span>
            <span
              className={`font-1 small-font active-button-${
                (datos.active !== 0 ? "gray" : "black")
              }`}
              onClick={() => {
                handleUpdateClient({target:{name:"active",value:0}});
              }}
            >
              No
            </span>
          </div>
        </div>
        <button className="blue-button mt-1r">Guardar</button>
        <Link to={routes.mediaSmart.clients}>
          <button  className={"cancell-button mt-1r ms-3 "}>Cancelar</button>
        </Link>
      </form>
    </Wrapper_1>
  ):<Loading />;
}