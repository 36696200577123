import React, { useState, useRef, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Breadcrumb from "../../Breadcrumb";
import Wrapper_1 from "../../Wrapper_1";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { POST_ADD_ACTION } from '../../../../redux/blogsDucks'
import { TAGS_GET_ACTION } from '../../../../redux/tagsDucks'
import { MEMBERS_GET_ACTION } from '../../../../redux/membersDucks'
import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'

import { routes } from "../../../../static_data/routes";

import DatePicker from "react-datepicker";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.css";

import SearchInput from "../../../Globals/SearchInput";
import H2 from "../../../Globals/H2";
import Wrapper_2 from "../../Wrapper_2";
import { toast } from "react-hot-toast";

const breadcrumbItems = [
  {
    text: "MediaCore®",
    route: routes.mediaDocs.about,
  },
  {
    text: "MediaHub®",
    route: routes.base,
  },
  {
    text: "Recursos",
    route: routes.mediaDocs.news,
  },
  {
    text: "Alta de un recurso",
    route: routes.mediaDocs.createNew,
  },
];

export default function CreateNew() {

  const drop = React.useRef(null);
  const [sizeInput,setSizeInput] = useState(255);

  const [inputDate, setInputDate] = useState(new Date());
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const fileRef = useRef();
  const [files,setFiles] = useState([]);

  const dispatch = useDispatch();
  const response = useSelector(store => store.posts.response)
  const tags = useSelector(store => store.tags.tags)
  const user_logged = useSelector(store => store.users.user_logged)
  const members = useSelector(store => store.members.members)
  const categories = useSelector(store => store.categories.categories)

  const [startDate, setStartDate] = useState(new Date());
  const [searcher,setSearcher] = useState('')

  const history = useHistory()

  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();

  const [datos, setDatos] = useState({
    title:'',
    user_id:1,
    excerpt:'',
    image_caption:'',
    image:'',
    content:'Contenido de prueba.',
    active:1,
    tags:[],
    users:[],
    featured:0,
    publish_date:new Date()
  })

  const [content, setContent] = useState('');
  
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const [dateTime, setDateTime] = useState(new Date());

  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    setDatos({ ...datos, image: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    console.log("HOLA")
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    console.log("HOLA")
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };
  const uploadCallbackFunction = async (file) => {
    const imgData = await uploadInlineImageForArticle(file);
    return Promise.resolve({ data: { 
      link: `${imgData}`
    }});
  }
   const uploadInlineImageForArticle = async (file) => {
    // const headers = await this.getAuthHeader();
    let formData = new FormData();
    formData.append('image', file);
    try {
      const {data} = await axios.post('https://dev.mediacore.com.ar/api/v1/images',formData,{
        headers:{
          'Authorization': 'Bearer ' + user_logged.token
        }
      })
      return data.data.uri;
    } catch (error) {
        console.log(error);
      return null;
    }
  }

  useEffect(() => {
    dispatch(MEMBERS_GET_ACTION())
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    setDatos({
      ...datos,
      user_id : user_logged.id, 
    })
  },[])

  useEffect(() => {
    if(response.success){
      history.push("/app/media-hub/recursos")
    }
  },[response])


  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }
  const handleCreateNew = (e) => {
    e.preventDefault();
    setDatos({
        ...datos,
        user_id : user_logged.id, 
        publish_date:startDate,
        categories:getCategory('docs')
      }) 
      if (sizeInput - datos.excerpt.length < 0){
        toast('El subtitulo debe tener un máximo de 255 caracteres.', { type: 'error' })
      }
      else{
        dispatch(POST_ADD_ACTION(datos,files));
      }
  }
  const handleUpdateNew = (e) => {
    if(e.target.name == 'tags'){
      if(datos.tags.find(element => element === e.target.value)){
          const newTags = datos.tags.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newTags, 
          })
          return null
      }
      else{
        setDatos({
          ...datos,
          [e.target.name] : [...datos.tags,e.target.value], 
        })
        return null
      }
    }
    else if (e.target.name == 'users'){
      if(datos.users.find(element => element.toString() === e.target.value.toString())){
          const newUsers = datos.users.filter(element => element.toString() !== e.target.value.toString())
          setDatos({
              ...datos,
              [e.target.name] : newUsers,
              tags:[] 
          })
          return null
      }
      else{
        setDatos({
          ...datos,
          [e.target.name] : [...datos.users,e.target.value], 
          tags:[]
        })
        return null
      }
    }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
      categories:getCategory('docs')
    })
  }
  const setPublish_date = (date) => {
    setDatos({
      ...datos,
      publish_date:date
    })
  }

  const handleChange = () => {
    setFiles([...files,fileRef.current.files[0]])
  }

  return (
    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Alta de un recurso">
      <form onSubmit={handleCreateNew} className="content-mobile-padding">
        <div className="grid-3-bigbreak">
          <div className="mi-create-new-step">
            <H2
              font="font-1"
              color="text-black-1"
              className="text-center"
              text={
                <>
                  <strong>PASO 1</strong> - Información principal
                </>
              }
            />
            <hr />
            <div className="d-flex flex-column ">
              <label className="font-1">Título</label>
              <input
                className="common-input"
                type="text"
                name="title"
                placeholder="Título"
                onChange={handleUpdateNew}
              />
            </div>
            <div className="d-flex flex-column mt-1r">
              <label className="font-1">Subtítulo</label>
                <input
                type="text"
                name="excerpt"
                placeholder="Subtítulo"
                onChange={handleUpdateNew}
                className={"common-input " + (sizeInput - datos.excerpt.length < 0 ? "border-danger" : "")}

              />
              <label className={"mt-2 " + ((sizeInput - datos.excerpt.length) > 100 && ' d-none ') + ((sizeInput - datos.excerpt.length) < 0 && ' text-danger')}>
                {(sizeInput - datos.excerpt.length) + " caracteres disponibles"}
              </label>
            </div>
            <div className="d-flex flex-column mt-1r">
              <label className="font-1">Fecha</label>
              <div className="d-flex input self-width align-items-center">
                <DatePicker dateFormat="dd/MM/yyyy" selected={datos.publish_date} onChange={(date) => setPublish_date(date)}  locale="es" />
                <i className="icon-calendar icon-regular icon-body"></i>
              </div>
            </div>
            
          </div>
          <div className="mi-create-new-step d-flex flex-column align-items-start ">
            <H2
              font="font-1"
              color="text-black-1"
              className="text-center w-100p mb-0"
              text={
                <>
                  <strong>PASO 2</strong> - Contenido del recurso
                </>
              }
            />
            <hr className="w-100 mt-1r mb-1r" />
            <div className="d-flex flex-column w-100 h-100">
              <div className="common-input py-3 h-100 d-flex flex-column height-minimal ">
              <Editor
                  editorState={editorState}
                  wrapperClassName="card"
                  editorClassName="card-body"
                  onEditorStateChange={newState => {
                      setEditorState(newState);
                      handleUpdateNew({target:{name:"content",value:(draftToHtml(convertToRaw(newState.getCurrentContent())))}});;
                  }}
                  toolbar={{
                      options: ['inline', 'blockType', 'list','link', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                      inline: { 
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                      blockType: {
                        inDropdown: false,
                        options: ['H1', 'H2', 'H3'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      image: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: true,
                        uploadCallback:uploadCallbackFunction,
                        previewImage: true,
                        inputAccept: 'image/jpeg,image/jpg,image/png',
                        defaultSize: {
                          height: 'auto',
                          width: '100%',
                        },
                      },
                  }}
              />
                <div className={`d-flex flex-column mt-4 position-relative`} id="drag-content">
                  {/* <label className="font-1">"Agregar Archivo"</label> */}
                  <label className={`image-picker-button small-font d-flex align-items-center justify-content-center`} htmlFor="addFiles" for="addFiles">
                    Subir archivos (DOC, DOCX, PDF, XLS, AI, PNG, JPG, GIF, MP4). Max 15mb
                  </label>
                  <input
                    type="file"
                    id="addFiles"
                    onDrop={(e) => {}}
                    onDragStart={(e) => {}}
                    onDragEnd={(e) => {}}
                    ref={fileRef}
                    onChange={handleChange}
                    // className="d-none"
                  />
                </div>
                <div className="mt-3 d-flex flex-wrap">
                  {files?.map((element,index) => (
                    <span className="me-3 mb-2 download-docs ">
                      <span>{element.name.split(".")[element.name.split(".")?.length - 1]}</span>
                      <div className="title">{element.name.split(".")[0]}</div>
                      <div className="cursor-pointer" onClick={() => setFiles(files.filter(file => file.name !== element.name))} >
                        <i className="icon-delete icon-body icon-big"></i> 
                      </div>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mi-create-new-step">
            <H2
              font="font-1"
              color="text-black-1"
              className="text-center"
              text={
                <>
                  <strong>PASO 3</strong> - Tipo de recurso
                </>
              }
            />
            <hr />
            <div className="w-100p">
              <div className="d-flex flex-column">
                <label className="font-1">
                  Recurso para todos los usuarios
                </label>
                <div className="grid-3-col mt-1r">
                  {
                    <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="checkbox me-1"
                      name="type"
                      value="oportunidad"
                      disabled={true}
                      checked={datos.tags.length === 0 && datos.users.length === 0 ? true : false}
                    />
                    <label className={`small-font  `}>
                      General
                    </label>
                  </div>
                  }
                </div>
              </div>
              <hr className="mb-1r" />

              <div className="d-flex flex-column">
                <label className="font-1">Recurso de un emprendimiento</label>
                <div className="grid-3-col-min-2-col w-100p mt-1r">
                  {tags.filter(element => element.categories.find(element => element.id === getCategory("docs"))).map((group, i) => (
                    <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      onClick={handleUpdateNew}
                      className="checkbox me-1"
                      name="tags"
                      value={group.id}
                      checked={datos.tags.find(element => element.toString() === group.id.toString()) ? true : false}
                      disabled={datos.users.length === 0 ? false : true}
                    />
                    <label className={`small-font  `}>
                      {group.name}
                    </label>
                  </div>
                  ))}
                </div>
              </div>
              <hr className="mb-1r" />

              <div className="d-flex flex-column">
                <label className="font-1">
                  Recurso exclusivo para un usuario
                </label>
                <SearchInput setSearcher={setSearcher} className="mt-1r" />
                <div className="mi-investor-checkboxes grid-2-col mt-1r">
                  {members.filter(element => element.client_id.toString() === user_logged.client.id.toString() && element.level === "2").map((investor, i) => (
                    investor.name.toLowerCase().includes(searcher.toLowerCase()) ?
                     <div className="d-flex align-items-center">
                     <input
                       type="checkbox"
                       onClick={handleUpdateNew}
                       className="checkbox me-1"
                       name="users"
                       checked={datos.users.find(post_investor => post_investor === investor.id)}
                       value={investor.id}
                     />
                     <label className={`small-font  `}>
                       {investor.name}
                     </label>
                    </div>:''
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mi-create-new-step mt-1r">
          <H2
            font="font-1"
            color="text-black-1"
            className="text-center"
            text={
              <>
                <strong>PASO 4</strong> - Finalizar alta del recurso
              </>
            }
          />
          <div className="w-50p m-auto">
            <hr />
            <div className="d-flex flex-row justify-content-center align-items-center gap-4">
              <button  onClick={ () => setDatos({...datos,active:"1"})} type="submit" className="blue-button px-4">Publicar</button>
              <button onClick={ () => setDatos({...datos,active:"0"})} type="submit" className="blue-button px-4">Borrador</button>
            </div>
          </div>
        </div>
      </form>
    </Wrapper_2>
  );
}