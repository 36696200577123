import axios from 'axios'

// Constantes
const dataInicial = {
    tags:[],
    tag:{},
    loading_tags:false,
    loading_absolute:false,
    response:{},
}

// Types
const LOADING_TAGS = 'LOADING_TAGS'
const LOADING_ABSOLUTE = 'LOADING_ABSOLUTE'
const GET_TAGS = 'GET_TAGS'
const GET_TAG = 'GET_TAG'
const ADD_TAG = 'ADD_TAG'
const UPDATE_TAG = 'UPDATE_TAG'
const DELETE_TAG = 'DELETE_TAG'

const GET_TAGS_ERROR = 'GET_TAGS_ERROR'
const CLEAR_STATUS = 'CLEAR_STATUS'

// Reducer
export default function postReducer(state = dataInicial, action){
    switch(action.type){
        case CLEAR_STATUS:{ //Clear status
            return {
                ...state, response : {},
            }
        }
        case LOADING_TAGS:{ //Clear status
            return {
                ...state, loading_tags : true,
            }
        }
        case LOADING_ABSOLUTE:{ //Clear status
            return {
                ...state, loading_absolute : true,
            }
        }
        
        case GET_TAGS:{ //Get all tags
            return {
                ...state, tags : action.payload.data.data.tags.data,
                loading_tags:false 
            }
        }
        case GET_TAG: {// Get details tag
            return {
                ...state, tag : action.payload,
                loading_tags:false 
            }
        }
        case ADD_TAG:{ // Add tag
            return {...state, tags: [...state.tags,action.payload],                
                response:{
                    status:201,
                    statusText:"El filtro fue creado correctamente.",
                    success:true
                },
                loading_tags:false,
                loading_absolute:false,
            }
        }
        case UPDATE_TAG:{ // Update 
            return {...state,
                tags: [...state.tags.filter(tag => tag.id !== action.payload.data.data.tag.id),action.payload.data.data.tag], 
                response:{
                    status:action.payload.status,
                    statusText:"El filtro fue actualizado correctamente.",
                    success:true,
                },
                loading_tags:false,
                loading_absolute:false,
            }
        }
        case DELETE_TAG:{ // Delete
            return {...state, tags: [...state.tags.filter((tag) => tag.id !== action.payload.data.data.tag.id)],                
                response:{
                    status:action.payload.status,
                    statusText:"El filtro fue eliminado correctamente.",
                    success:true
                },
                loading_tags:false,
                loading_absolute:false,
            }
        }
        case GET_TAGS_ERROR:{ // Set errors
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
                loading_tags:false,
                loading_absolute:false,
            }
        }
        default:
            return state;
    }
}
// Actions
export const CLEAR_STATUS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_STATUS,payload: true})
}
export const TAGS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_TAGS,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/tags/search?limit=120&clients=' + getState().users.user_logged.client.id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_TAGS,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const TAG_GET_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_TAGS,payload: true})
    try {
    const res = await axios.get('https://dev.mediacore.com.ar/api/v1/tags/'+ id + "",{
        headers:{
            'Authorization': 'Bearer ' + getState().users.user_logged.token
        },
    })
    console.log(res);
        dispatch({
        type: GET_TAG,
        payload: res.data.data.tag
    })
        } catch (error) {
    dispatch({type: GET_TAGS_ERROR,payload:error.response})
    console.log(error)
    }
}
export const TAG_ADD_ACTION = (data) => async (dispatch, getState) => {
    
    dispatch({type: LOADING_TAGS,payload: true})
    dispatch({type: LOADING_ABSOLUTE,payload: true})
    
    var dataSend = new FormData();
    dataSend.append("name", data.name);
    dataSend.append("user_id", data.user_id);
    dataSend.append("categories[]", data.categories)
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/tags',dataSend,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        let response = res.data.data.tag;
        response.user = getState().users.user_logged
        console.log(response)
        dispatch({
            type: ADD_TAG,
            payload: response
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_TAGS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const TAG_UPDATE_ACTION = (data,id) => async (dispatch, getState) => {
    dispatch({type: LOADING_TAGS,payload: true})
    dispatch({type: LOADING_ABSOLUTE,payload: true})
    delete data.image
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/tags/'+id,data,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        console.log(res)
        dispatch({
            type: UPDATE_TAG,
            payload: res,
        })
    } catch (error) {
        let errors_texts = []
        console.log(error.response)
        for (const iterator of Object.values(error.response.data)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_TAGS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const TAG_DELETE_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING_TAGS,payload: true})
    dispatch({type: LOADING_ABSOLUTE,payload: true})
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/tags/'+id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        console.log(res)
        dispatch({
            type: DELETE_TAG,
            payload: res
        })
    } catch (error) {
        console.log(error.response)
        dispatch({
            type: GET_TAGS_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrió un error inesperado."],
                success:false
            }
        })
        console.log(error)
    }
}
