import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, NavLink } from "react-router-dom";
import {CLIENTS_GET_ACTION,USER_LOGIN_ACTION,USER_GET_LOGIN_ACTION,USER_SET_REDIRECTION} from '../../redux/userDucks'
import {useParams} from 'react-router-dom'
import Footer from '../Footer/Footer';
import { routes } from "../../static_data/routes";
import eye from "../../assets/images/eye.png"
import eyeBlocked from "../../assets/images/eye-blocked.png"
import {USER_SEND_PASSWORD} from '../../redux/userDucks'
import toast from "react-hot-toast";


export default function Login() {

    const dispatch = useDispatch();
    const history = useHistory();
    const response = useSelector(store => store.users.response)

    const [inputPassword, setInputPassword] = useState("password");
    const [datos, setDatos] = useState({
        password:"",
        repassword:"",
    });
    
    function mostrarContraseña(){
        let value;
        if( inputPassword === "password"){
            value = "text";
        }else{
            value = "password";
        }
        setInputPassword(value)

    }

    const handleUpdateDatos = (e) => {
        setDatos({
          ...datos,
          [e.target.name] : e.target.value, 
        })
      }

    const queryParams = new URLSearchParams(window.location.search); //Obtengo params

    const token = queryParams.get('token'); //Token
    const email = queryParams.get('email'); //Mail

    const sendNewPass = (e) => {
        e.preventDefault();
        dispatch(USER_SEND_PASSWORD(token,email,datos.password,datos.repassword))
    }

    useEffect(() => {
        if(response.status === 422){
            // toast.error(response.statusText) //Hecho en toastResponse
        }
        if(response.status === 200){
            // toast.success(response.statusText) //Hecho en toastResponse
            history.push(routes.session.login)
        }
    } , [response])

    return (
        <div>
            <div className="recover ">
                <header className="header d-flex align-items-center">
                    <div className="brand-wrapper">
                        <NavLink className="brand text-decoration-none" to={routes.base}>
                        MediaCore®
                        </NavLink>
                    </div>                
                </header>
                
                <div className="container-card">
                    <div className="card-background">
                        <h1 className="md-font text-mediahaus-1 mb-2 mb-md-5">Cambiar contraseña</h1>
                        <form class=" w-100" onSubmit={sendNewPass}>
                            <label for="newPass" >Nueva contraseña</label>
                            <div className="position-relative input_password_eye">
                                <input onChange={handleUpdateDatos} name="password" id="newPass" type={inputPassword} required class="login-input common-input w-100 mxw-500 mb-2"/>
                                <img onClick={mostrarContraseña} className="eye position-absolute small"  id="boton-eye" src={ inputPassword === "password" ? eye : eyeBlocked }/>
                            </div>
                            
                            <label for="confirmPass" className="mt-2 mt-md-3">Repetir nueva contraseña</label>
                            <div className="position-relative">
                                <input onChange={handleUpdateDatos} name="repassword" id="confirmPass" required type={inputPassword} class="login-input common-input w-100 mxw-500 mb-2"/>
                                {/* <img onClick={mostrarContraseña} className="eye position-absolute " id="boton-eye" src={ inputPassword === "password" ? eye : eyeBlocked }/> */}
                            </div>
                            <button type="submit" class="blue-button login-button mt-2 mt-md-3 w-100">Confirmar contraseña</button>
                            
                        </form>
                        

                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}