import React, { useRef, useState , useEffect } from "react";
import Wrapper_1 from "../../Wrapper_1";
import InputDateTime from "../../../Globals/DateTimeInput";
import { routes } from "../../../../static_data/routes";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { POST_ADD_ACTION } from '../../../../redux/blogsDucks'
import { TAGS_GET_ACTION } from '../../../../redux/tagsDucks'
// import { INVESTORS_GET_ACTION } from '../../../../redux/investorsDucks'
import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export default function CreateEvent() {
  const descriptionRef = useRef();
  const [dateTime, setDateTime] = useState(new Date());

  const breadcrumbItems = [
    {
      text: "MediaBroker®",
      route: routes.mediaBroker.about,
    },
    {
      text: "Eventos",
      route: routes.mediaBroker.events,
    },
    {
      text: "Nuevo evento",
      route: routes.mediaBroker.createEvent,
    },
  ];

  const [inputDate, setInputDate] = useState(new Date());
  const [editorState, setEditorState] = useState(EditorState.createEmpty());


  const inputImage = useRef(null);

  const dispatch = useDispatch();
  const response = useSelector(store => store.posts.response)
  const tags = useSelector(store => store.tags.tags)
  const user_logged = useSelector(store => store.users.user_logged)
  // const investors = useSelector(store => store.investors.investors)
  const categories = useSelector(store => store.categories.categories)

  const [startDate, setStartDate] = useState(new Date());
  const [searcher,setSearcher] = useState('')

  const history = useHistory()

  const [datos, setDatos] = useState({
    title:'',
    user_id:1,
    excerpt:'',
    image_caption:'',
    image:'',
    content:'Contenido de prueba.',
    active:1,
    tags:[],
    users:[],
    featured:0,
    publish_date:new Date()
  })

  const [content, setContent] = useState('');
  
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();

  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    // console.log(picture_profile.current.files[0])
    setDatos({ ...datos, image: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };
  const uploadCallbackFunction = async (file) => {
    const imgData = await uploadInlineImageForArticle(file);
    return Promise.resolve({ data: { 
      link: `${imgData}`
    }});
  }
   const uploadInlineImageForArticle = async (file) => {
    // const headers = await this.getAuthHeader();
    let formData = new FormData();
    formData.append('image', file);
    try {
      const {data} = await axios.post('https://dev.mediacore.com.ar/api/v1/images',formData,{
        headers:{
          'Authorization': 'Bearer ' + user_logged.token
        }
      })
      return data.data.uri;
    } catch (error) {
        console.log(error);
      return null;
    }
  }

  const [active, setActive] = useState(false);

  
  useEffect(() => {
    // dispatch(INVESTORS_GET_ACTION())
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    setDatos({
      ...datos,
      user_id : user_logged.id, 
    })
  },[])

  useEffect(() => {
    if(response.success){
      history.push("/app/contenido/media-investor/novedades")
    }
  },[response])

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }
  const handleCreateNew = (e) => {
    e.preventDefault();
   setDatos({
      ...datos,
      user_id : user_logged.id, 
      publish_date:startDate,
      categories:getCategory('investor')
    }) 
    dispatch(POST_ADD_ACTION(datos));
  }
  const handleUpdateNew = (e) => {
    if(e.target.name == 'tags'){
      if(datos.tags.find(element => element === e.target.value)){
          const newTags = datos.tags.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newTags, 
          })
          return null
      }
      else{
        setDatos({
          ...datos,
          [e.target.name] : [...datos.tags,e.target.value], 
        })
        return null
      }
    }
    else if (e.target.name == 'users'){
      if(datos.users.find(element => element.toString() === e.target.value.toString())){
          const newUsers = datos.users.filter(element => element.toString() !== e.target.value.toString())
          setDatos({
              ...datos,
              [e.target.name] : newUsers, 
          })
          return null
      }
      else{
        setDatos({
          ...datos,
          [e.target.name] : [...datos.users,e.target.value], 
        })
        return null
      }
    }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
      categories:getCategory('investor')
    })
  }
  const setPublish_date = (date) => {
    setDatos({
      ...datos,
      publish_date:date
    })
  }
  return (
    <Wrapper_1 title="Nuevo evento" breadcrumbItems={breadcrumbItems}>
      <div className="content-mobile-padding w-100p">
        <div className="content-fuid">
          <div className="row mt-4">
            <div className="d-block mt-3 mt-md-0 col-12 col-md-4">
              <div>
                <label className="font-1 w-100">Sala</label>
              </div>
              <select className="common-input w-100" onChange={() => {}}>
                <option value="value1">Seleccionar</option>
              </select>
            </div>
            <div className="d-block col-12 col-md-4 mt-3 mt-md-0">
              <div>
                <label className="font-1 w-100">Nivel</label>
              </div>
              
              <select className="common-input w-100 " onChange={() => {}}>
                <option value="value1">Seleccionar</option>
              </select>
            </div>
            <div className="d-block col-12 col-md-4 mt-3 mt-md-0">
              <div>
                <label className="font-1 w-100">Referente</label>
              </div>
              <select className="common-input w-100" onChange={() => {}}>
                <option value="value1">Seleccionar</option>
              </select>
            </div>
            <div className="d-block col-12 mt-4">
              <div>
                <label className="font-1 w-100">Nombre del Evento/Capacitación</label>
              </div>
              <input
                className="common-input w-100"
                onChange={() => {}}
                type="text"
                name="event_name"
              />
            </div>
            <div className="col-12 mt-4">
              <label className="font-1">Descripción</label>
              <div className="create-event-description">
                <div className="col-12 col-md-7 div-editor">
                  <Editor
                    editorState={editorState}
                    wrapperClassName=""
                    editorClassName="card-body"
                    onEditorStateChange={newState => {
                        setEditorState(newState);
                        handleUpdateNew({target:{name:"content",value:(draftToHtml(convertToRaw(newState.getCurrentContent())))}});;
                    }}
                    toolbar={{
                        options: ['inline', 'blockType', 'list','link', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                        inline: { 
                          inDropdown: false,
                          options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: false },
                        blockType: {
                          inDropdown: false,
                          options: ['H1', 'H2', 'H3'],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                        },
                        image: {
                          className: undefined,
                          component: undefined,
                          popupClassName: undefined,
                          urlEnabled: true,
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback:uploadCallbackFunction,
                          previewImage: true,
                          inputAccept: 'image/jpeg,image/jpg,image/png',
                          defaultSize: {
                            height: 'auto',
                            width: '100%',
                          },
                        },
                    }}
                  />
                </div>
                <div className="col-12 col-md-5 div-archivo">
                  <button
                    className="common-input-focus"
                    onClick={() => {
                      descriptionRef.current.click();
                    }}
                  >
                    Agregar recursos
                  </button>
                  <input type="file" className="d-none" ref={descriptionRef} />
                </div>
              </div>
            </div>

            <div className="d-block col-12 col-lg-4 mt-4">
              <div><label className="font-1 w-100">Fecha y Hora del Evento/Capacitación</label></div>
              <div><InputDateTime dateTime={dateTime} setDateTime={setDateTime} /></div>
            </div>
          </div>
        </div>

        

        <div className="col-12 mt-5 ">
          <button className="blue-button me-3 col-12 col-md-3 col-lg-2">Crear y Activar</button>
          <button className="button-gray col-12 col-md-3 col-lg-2 mt-3 mt-lg-0">Guardar Borrador</button>
        </div>
      </div>
    </Wrapper_1>
  );
}
