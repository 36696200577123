import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "../../../assets/svgs/search-icon.svg";
import AddIcon from "../../../assets/svgs/add-icon.svg";
import EditIcon from "../../../assets/svgs/edit-icon.svg";
import DeleteIcon from "../../../assets/svgs/delete-icon.svg";
import Wrapper_2 from "../Wrapper_2";
import Table from "../Table";
import {CLIENTS_GET_ACTION} from "../../../redux/clientsDucks"
import {USERS_GET_ACTION,USER_DELETE_ACTION} from "../../../redux/userDucks"
import { useDispatch, useSelector } from "react-redux";

import ModalDelete from './Modal/DeleteModalUser'

import Loading from '../../Globals/Loading'

import { routes } from "../../../static_data/routes";

export default function News() {
  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.base,
    },
    {
      text: "Usuarios",
      route: routes.mediaSmart.users,
    },
  ];

  const dispatch = useDispatch();
  
  const clients = useSelector(store => store.clients.clients)
  const users = useSelector(store => store.users.users)
  const loading_clients = useSelector(store => store.clients.loading)
  const loading_users = useSelector(store => store.users.loading)
  const [searchName,setSearchName] = useState('')
  const [searchClient,setSearchClient] = useState('-1')
  const [searchRol,setSearchRol] = useState('-1')

  useEffect(() => {
    dispatch(CLIENTS_GET_ACTION());
    dispatch(USERS_GET_ACTION());
  },[])

  return !loading_clients && !loading_users ? (
    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Usuarios"
      rightLink={{
        text: "Nuevo usuario",
        icon: AddIcon,
        route: routes.mediaSmart.createUser,
      }}>
      <div className="content-mobile-padding w-100p">
        <div className="d-flex flex-wrap gap-3 mb-2r">
          <div className="input-search-period w-25p hover-border-blue-1">
            <input
              type="text"
              placeholder="Palabra clave"
              onChange={(e) => setSearchName(e.target.value)}
              className="focus-outline-none"
            />
            <img src={SearchIcon} alt="buscar periodo" width="15px" />
          </div>
          <select
            className="common-input input-search-period w-25p"
            onChange={(e) => {setSearchClient(e.target.value)}}
            
          >
            <option checked={searchClient.toString() === '-1'} value="-1">Cliente</option>
            {
              clients ? 
              clients.filter(element => element.id > 5).map((element,index) => (
                  <option value={element.id}>{element.name}</option>
              )):''
            }
          </select>
          <select
            className="common-input input-search-period w-25p"
            onChange={(e) => {setSearchRol(e.target.value)}}
          >
            <option value="-1">Rol</option>
            <option value={"1"}>Inversor</option>
            <option value={"10"}>Administrador</option>
            <option value={"9"}>Super Administrador</option>
          </select>
          {/* <button onClick={() => setSearchName("") + setSearchClient("-1") + setSearchRol("-1")} className="blue-button ms-2">Limpiar</button> */}
        </div>
          <div className="tables-wrapper">
              <ClientsTablet users={users.filter(element => element.id > 26)} searchName={searchName} searchClient={searchClient} searchRol={searchRol} />
          </div>
      </div>
    </Wrapper_2>
  ):<Loading />;
}

function ClientsTablet({ users,searchName,searchClient,searchRol}) {

  const getRol = (rol) => {
    switch (rol) {
      case "1":
        return "Inversor";
      case "10":
        return "Administrador";
      case "9":
        return "Super Administrador";
    }
  }

  return users ? (
    <Table
      TableHeader={
        <tr>
          <th className="width-custom-self-min">Usuario</th>
          <th className="text-start">Cliente</th>
          <th>Rol</th>
          <th>Email</th>
          <th>Acciones</th>
        </tr>
      }
      TableRows={users.map((user, i) => (
          user.name.toLowerCase().includes(searchName.toLowerCase()) && (user.client.id.toString() === searchClient.toString() || searchClient.toString() === "-1") && (user?.level?.toString() === searchRol?.toString() || searchRol.toString() === "-1") ?
          <tr key={i} className={"text-nowrap " + (!user.active ? 'disabled' : '')}>
            {console.log(user)}
          <td className="width-custom-self-min">
            <img src={(user.profile_image ? "https://dev.mediacore.com.ar" + user.profile_image : 'https://dev.mediacore.com.ar/storage/no-profile.jpg' )} alt="usuario" className="me-4 rounded-circle" height="35px" width="35px" style={{objectFit:"cover",objectPosition:"center"}} />
            {user.name}</td>
          <td className="text-start"> <img className="me-4 rounded-circle" src={"https://dev.mediacore.com.ar" + user.client.logo} width="32px" height="32px" alt="" />{user.client.name}</td>
          <td>
            {getRol(user.level)}
          </td>
          <td>
            {user.email}
          </td>
          {/* <td>{getDate(user.updated_at)}</td> */}
          <td>
            <button className="button-no-styled ">
              <Link to={"usuarios/actualizar-usuario/"+user.id} ><img src={EditIcon} alt="editar novedad" className="me-3" /></Link>
              <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#user-"+user.id}><img src={DeleteIcon}  alt="editar novedad" /></button>
              <ModalDelete user={{id:user.id,name:user.name}} />
            </button>
          </td>
        </tr>
        :
        ''
      ))}
    />
  ):<Loading />;
}
