  const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };
  
  const arrayMove = (item, array, from, to) => {
    let NewItem = []

    if(to !== 0 && to !== array.length-1){ // No lo envio ni al prinicipio ni al final
      if(to > from){ // Si el new es mayor que el old, es una baja, por lo tanto el item se mueve hacia abajo
        // setItem({ ...items[from], position : ((items[to].position)+1)}) // Le sumo uno al que estaba en ese lugar
        NewItem= ({ ...array[from], position : Math.round(array[to].position +array[to+1].position)/2}) 
      }
      else{ //Si el old es mayor que el new, es una subida, por lo tanto el item se mueve hacia arriba
        // setItem({ ...items[from], position : ((items[to].position)-1)}) //Le resto uno al que estaba en ese lugar
        NewItem = ({ ...array[from], position : Math.round(array[to].position + array[to-1].position)/2}) //Le resto uno al que estaba en ese lugar
      }
    }
    else if(to === 0){ // Lo envio al principio 
      // setItem({ ...items[from], position : (items[0].position)-1}) //Si lo envio al principio, entonces le resto uno al primero
      NewItem = ({ ...array[from], position : Math.round(array[0].position)+1}) //Si lo envio al principio, entonces le resto uno al primero
    }
    else if(to === array.length-1){ // Lo envio al final
      NewItem = ({ ...array[from], position : Math.round(array[array.length-1].position)-1}) //Si lo envio al principio, entonces le resto uno al primero
    }
    array[from] = NewItem
    array = array.slice();
    arrayMoveMutate(array, from, to);

    return array;
  };
  
  export default arrayMove;
  