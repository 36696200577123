import React, {useEffect, useState} from "react";
import CardNew from "./CardNew";

import Wrapper_2 from "../../Wrapper_2";
import img1 from "../../../../assets/images/temporal/cafe.jpeg";

import {useDispatch,useSelector} from 'react-redux'
import { CATEGORIES_GET_ACTION} from '../../../../redux/categoryDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { POSTS_GET_ACTION,CLEAR_POST_ACTION,CLEAR_POST_PRIVATE_ACTION} from '../../../../redux/blogsDucks'

import { routes } from "../../../../static_data/routes";

import Loading from '../../../Globals/Loading'

// Mock data
const news = [
  {
    title: "Novedades para Instagram.",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit diam nibh, feugiat curae in euismod auctor neque lacus ornare, sapien fusce morbi nec sociosqu arcu ultricies suspendisse.",
    date: "5 de Abril 2021",
    image: img1,
  },
  {
    title: "Ultimas tendencias en Marketing Digital.",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "07 Ene 2021",
    image: img1,
  },
  {
    title: "50% del tráfico de internet son videos",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Importancia de las Redes Sociales ",
    description:
      "Avanzamos un 60% con los trabajos de revoque exterior del edificio, abarcando todas las paredes de cada nivel, para el mismo utilizamos hormigón…",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Ultimas novedades para vos.",
    description:
      "Fin de mampostería, Pintura exterior, perfilería, y exteriores.",
    date: "07 Ene 2021",
    image: img1,
  },
  {
    title: "Ultimas tendencias en Marketing Digital.",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "07 Ene 2021",
    image: img1,
  },
  {
    title: "50% del tráfico de internet son videos",
    description:
      "Accumsan condimentum id lacus phasellus curae faucibus, augue libero vitae a urna dignissim metus, ornare vel imperdiet risus quam.",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Importancia de las Redes Sociales ",
    description:
      "Avanzamos un 60% con los trabajos de revoque exterior del edificio, abarcando todas las paredes de cada nivel, para el mismo utilizamos hormigón…",
    date: "24 Feb 2021",
    image: img1,
  },
  {
    title: "Ultimas novedades para vos.",
    description:
      "Fin de mampostería, Pintura exterior, perfilería, y exteriores.",
    date: "07 Ene 2021",
    image: img1,
  },
];

export default function News() {
  const breadcrumbItems = [
    {
      text: "Inversor",
      route: routes.mediaInvestor.inversor,
    },
    {
      text: "Mis inversiones",
      route: routes.mediaInvestor.inversor,
    },
  ];

  const dispatch = useDispatch()
  const tags = useSelector(store => store.tags.tags)
  const categories = useSelector(store => store.categories.categories)
  const posts = useSelector(store => store.posts.posts)
  const user_logged = useSelector(store => store.users.user_logged)

  const loading = useSelector(store => store.posts.loading)

  const [activeTags,setActiveTags] = useState([])

  useEffect(() => {
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    dispatch(POSTS_GET_ACTION())
    dispatch(CLEAR_POST_ACTION())
    dispatch(CLEAR_POST_PRIVATE_ACTION())
  },[])

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }

  const toggleActive = (tag) => {
    const finder = activeTags.find(element => element.id.toString() === tag.id.toString())
    console.log(finder)

    if(finder){
      setActiveTags(activeTags.filter(element => element.id.toString() !== tag.id.toString()))
    }
    else{
      setActiveTags([...activeTags,tag])
    }
  }

  const getBlogForTag = (blog,tags) => {
    if(activeTags.length === 0){
      for(let i = 0 ; i < blog.tags.length ; i++){
        for(let j = 0 ; j < user_logged.tags.length ; j++){
          if(blog.tags[i].id === user_logged.tags[j].id){
            return true
          }
        }
      }
    }
    for(let i = 0 ; i < blog.tags.length ; i++){
      for(let j = 0 ; j < tags.length ; j++){
        if(blog.tags[i].id === tags[j].id){
          //Validar si el tag ademas de estar activo, lo tiene el usuario.
          return true
        }
      }
    }
  }

  const getMyInvestments = (blogs) => {
    let myInvestments = []
    for (const blog of blogs) {
      if(blog.categories.find(element => element.id === getCategory("investor")) && getBlogForTag(blog,activeTags) && blog.active.toString() === "true"){
        myInvestments.push(blog)
      }
    }
    return myInvestments
  }

  return (
    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Mis inversiones">
      <div className="content-mobile-padding">
        <div className="div-search w-100 hover-border-blue-1">
          <div className="contenedor-tags">
            {activeTags.map(element => 
              <span className="span-tag span-active"> {element.name} <i onClick={() => toggleActive(element)}>x</i> </span>
            )}
          </div>
          <div className="div-icon-search">
           <i className="icon-search-icon icon-big icon-body"></i>
          </div>
        </div>
        <div className="div-tags mb-4 mt-4 ">
          {user_logged.tags.map((cat, i) => (
            activeTags.find(element => element.id === cat.id) ?
            '':
            <span className="span-tag" onClick={() => toggleActive(cat)}> {cat.name}</span>
        ))}
        </div>
        <hr className="mt-0 mb-2r"/>
        {loading ? <Loading /> :
          getMyInvestments(posts).length === 0 ? 
            <h3>No hay novedades disponibles.</h3>
            :
            <div className="ma-news">
              <div className="ma-news-grid">
                {getMyInvestments(posts).map((new_, i) => (
                  
                  <CardNew key={i} {...new_} isFirst={i === 0} />
                ))}
              </div>
            </div>
        }
      </div>

    </Wrapper_2>
  );
}
