import React, { useState, useEffect, useRef } from "react";
import Wrapper_2 from "../../Wrapper_2";
import TableOrder from '../../TableOrder'

import { routes } from "../../../../static_data/routes";
import { useDispatch, useSelector } from "react-redux";
import { USERS_GET_ACTION } from '../../../../redux/userDucks'
import { INVESTORS_GET_ACTION } from '../../../../redux/investorsDucks'
import { POSTS_GET_ACTION, POSTS_UPDATE_FEATURED_ACTION,CLEAR_POST_ACTION,CLEAR_INVESTORS_ACTION } from '../../../../redux/blogsDucks'
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'

import ModalDelete from './DeleteModalNew'
import {check_access} from '../../../../functions/accessFunctions'

import Loading from '../../../Globals/Loading'
// import { dispatch } from "react-hot-toast/dist/core/store";


// Mock data


export default function News() {

  const dispatch = useDispatch();
  const [searchName,setSearchName] = useState('')
  const posts = useSelector(store => store.posts.posts)
  const loading = useSelector(store => store.posts.loading)
  const categories = useSelector(store => store.categories.categories)

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }
  useEffect(() => {
    dispatch(CLEAR_POST_ACTION())
    dispatch(POSTS_GET_ACTION())
    dispatch(USERS_GET_ACTION())
    dispatch(INVESTORS_GET_ACTION())
    dispatch(CLEAR_POST_ACTION())
    dispatch(CLEAR_INVESTORS_ACTION())
    dispatch(CATEGORIES_GET_ACTION())

  },[])

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "MediaInvestor®",
      route: routes.mediaInvestor.about,
    },
    {
      text: "Novedades",
      route: routes.mediaInvestor.news,
    },
  ];

  const filterNoActive = (news) => {
    const filtered = news.filter((new_) => (new_.active === false || new_.active.toString()  === '0' ) && new_.categories.find(element => element.id === getCategory("investor")))
    return filtered
  }

  const filterNews = (news,bool_active) => {
    const filtered = news.filter((new_) => new_.active.toString() === bool_active.toString() && new_.categories.find(element => element.id === getCategory("investor")) && new_.tags.length > 0 && new_.groups_count === 0)
    return filtered
  }

  const filterOportunities = (news,bool_active) => {
    const filtered = news.filter((new_) => new_.active.toString() === bool_active.toString() && new_.categories.find(element => element.id === getCategory("investor")) && new_.tags.length === 0 && new_.groups_count === 0)
    return filtered
  }
  const filterPrivate = (news,bool_active) => {
    const filtered = news.filter((new_) => new_.active.toString() === bool_active.toString() && new_.categories.find(element => element.id === getCategory("investor")) && new_.groups_count > 0)
    return filtered
  }

  return posts ? (
    <Wrapper_2
      breadcrumbItems={breadcrumbItems}
      title="Novedades"
      rightLink={{
        text: "Nueva Novedad",
        icon: "addIcon",
        route: routes.mediaInvestor.createNew,
      }}
      check="Posts scope for writing records"
    >
      <div className="content-mobile-padding w-100p ">
        <div className="input-search-period w-50p mb-2r hover-border-blue-1">
          <input type="text" placeholder="Palabra clave" onChange={(e) => setSearchName(e.target.value)} className="focus-outline-none" />
          {/* <img src={SearchIcon} alt="buscar periodo" width="15px" /> */}
          <i className="icon-search-icon"></i>
        </div>
        {!loading ? 
          <>
            <div className={"tables-wrapper mt-5" + (filterOportunities(posts,true).length > 0 ? '' : ' d-none')}>
              <h3>Novedad exclusiva para todos los inversores - Prelanzamientos</h3>
              <NewsTable news={filterOportunities(posts,true)} searchName={searchName} />
            </div>
            <div className={"tables-wrapper mt-5" + (filterNews(posts,true).length > 0 ? '' : ' d-none')}>
              <h3>Novedad para grupo inversor - Mis inversiones</h3>
              <NewsTable news={filterNews(posts,true)} searchName={searchName} />
            </div>
            <div className={"tables-wrapper mt-5" + (filterPrivate(posts,true).length > 0 ? '' : ' d-none')}>
              <h3>Novedad exclusiva para un inversor - Mis novedades</h3>
              <NewsTable news={filterPrivate(posts,true)} searchName={searchName} />
            </div>
            <div className={"tables-wrapper mt-5" + (filterNoActive(posts).length > 0 ? '' : ' d-none')}>
              <h3>No Activas</h3>
              <NewsTable news={filterNoActive(posts)} searchName={searchName} />
            </div>
          </>
        :<Loading /> }
        {/* */}
      </div>
    </Wrapper_2>
  ):'';
}

function NewsTable({ news, isFinished = false, searchName = '' }) {

  const [newFilter,setNewFilter] = useState()

  useEffect(() => {
    setNewFilter(news)
  })


  return (
    <>
      <TableOrder 
      tableHeader = {['Título de la novedad','Creador','Fecha de creación','Última modificación','Acciones']}
      tableRow = {news ? news.filter((new_) => new_.title.toLowerCase().includes(searchName.toLowerCase())) : ''}
      searchName = {searchName} 
      investors = {true}
      />
    </>
  );
}
