import React from "react";
import Breadcrumb from "../Breadcrumb";

import { routes } from "../../../static_data/routes";

export default function Welcome() {
  const breadcrumb_items = [
    {
      text: "MediaCore®",
      route: routes.welcome.welcome
    },
    {
      text: "Bienvenidos",
      route: routes.welcome.welcome
    }
  ];
  return (
    <div className="content">
      <div>
        <Breadcrumb items={breadcrumb_items} />
      </div>
      <div className="welcome-wrapper d-flex align-items-center justify-content-center h-100" >
        <h1 className="" style={{color:"#c3c3c3",fontSize:"2rem"}}>Bienvenidos a <strong> MediaCore® </strong></h1>
      </div>
    </div>
  );
}
