import React, { useState, useEffect, useRef } from "react";
import Wrapper_2 from "../../Wrapper_2";
import Table from "../../Table";

import { routes } from "../../../../static_data/routes";

import { useDispatch, useSelector } from "react-redux";
import { TAGS_GET_ACTION} from '../../../../redux/tagsDucks'
import { CATEGORIES_GET_ACTION} from '../../../../redux/categoryDucks'
import ModalDelete from './DeleteModalFilter'

import Loading from '../../../Globals/Loading'
import { Link } from "react-router-dom";
import {check_access} from '../../../../functions/accessFunctions'


// Mock data
const filters = [
  {
    title: "Media Haus 1",
    creator: "Hernán Obarrio",
    date: "18/02/2021",
  },
  {
    title: "Media Haus 2",
    creator: "Hernán Obarrio",
    date: "18/02/2021",
  },
  {
    title: "Media Haus 3",
    creator: "Hernán Obarrio",
    date: "18/02/2021",
  },
  {
    title: "Media Haus 4",
    creator: "Hernán Obarrio",
    date: "18/02/2021",
  },
  {
    title: "Media Haus 5",
    creator: "Hernán Obarrio",
    date: "18/02/2021",
  },
  {
    title: "Media Haus 6",
    creator: "Hernán Obarrio",
    date: "18/02/2021",
  },
  {
    title: "Media Haus 7",
    creator: "Hernán Obarrio",
    date: "18/02/2021",
  },
];

export default function News() {
  const breadcrumbItems = [
    {
      text: "MediaTeam®",
      route: routes.base,
    },
    {
      text: "Filtros",
      route: routes.mediaTeam.filters,
    },
  ];

  const dispatch = useDispatch();

  const loading = useSelector(store => store.tags.loading)
  const tags = useSelector(store => store.tags.tags)


  useEffect(() => {
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
  },[])
  


  return (
    <Wrapper_2
      breadcrumbItems={breadcrumbItems}
      title="Filtros"
      rightLink={{
        text: "Nuevo Filtro", 
        icon: "",
        route: routes.mediaTeam.createFilter,
      }}
    >
      {loading && !tags ? 
        <Loading />  
        :
        <div className="content-mobile-padding w-100">
          <div className="tables-wrapper">
            <FiltersTable filters={tags}/>
          </div>
        </div>
      }

    </Wrapper_2>
  );
}

function FiltersTable({ filters }) {

  
  const getTime_Function = (time) => {
    const spliter = time.split('T');
    const fecha = spliter[0].split('-');
    const hora_spliter = spliter[1].split('.');
    const hora = hora_spliter[0].split(':')
    const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
    return date_time;
  }
  
  const user_logged = useSelector(store => store.users.user_logged)
  const categories = useSelector(store => store.categories.categories)

  const getCategory = (category) => {
    return categories.find(item => item.name === category)?.id
  }
  
  
  return (
    <Table
      TableHeader={
        <tr className="text-nowrap">
          <th className="width-100">Filtros</th>
          <th>Creador</th>
          <th>Fecha</th>
          <th>Acciones</th>
        </tr>
      }
      TitleButton={
        <>
          {"Activos"}{" "}
          <span className="text-gray-4"></span>
        </>
      }
      hasPreHeader={true}
      TableRows={filters?.filter(element => element?.categories?.find(element => element.id === getCategory("blog"))).map((filter, i) => (
        <tr key={i} className="text-nowrap">
          <td>{filter.name}</td>
          <td>
            <img
              src={process.env.REACT_APP_BASE_URL + (filter.user.profile_image ? filter.user.profile_image : "/storage/no-profile.jpg")}
              alt="creador de la novedad"
              className="me-2"
              width="35px"
              height={"35px"}
              style={{objectFit: "cover", borderRadius: "100%"}}
            />
            {filter.user.name}
          </td>
          <td>{getTime_Function(filter.updated_at).getDay() + "/" + getTime_Function(filter.updated_at).getUTCMonth() + "/" + getTime_Function(filter.updated_at).getFullYear() + ", " + getTime_Function(filter.updated_at).getHours() + ":" + getTime_Function(filter.updated_at).getUTCMinutes() + ":" + getTime_Function(filter.updated_at).getSeconds()} </td>
          <td>
            <button className="button-no-styled ">
              {/* <img src={EditIcon} alt="editar novedad" className="me-3" /> */}
              {
                check_access("Tags scope for writing records",user_logged.scopes) ?
                <>
                  <Link to={'filtros/actualizar-filtro/' + filter.id}><button style={{border:"none",background:"transparent"}} ><i className="icon-edit-icon"></i></button></Link>
                  <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#tag-"+filter.id}><i className="icon-delete"></i></button>
                  <ModalDelete tag={{id:filter.id,name:filter.name}} />
                </>
                :
                <>
                  <div className="" style={{opacity:"0.3"}}>
                  <i className="icon-edit-icon"></i>
                  <i className="icon-delete"></i>
                  </div>
                </>
              }
            </button>
          </td>
        </tr>
      ))}
    />
  );
}
