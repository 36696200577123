import React, { useState } from "react";
import Wrapper_1 from "../../Wrapper_1";

import { routes } from "../../../../static_data/routes";

export default function CreateRoom() {
  const [modality, setModality] = useState(false);
  const [roomActive, setRommActive] = useState(false);
  const breadcrumbItems = [
    {
      text: "MediaBroker®",
      route: routes.mediaBroker.about,
    },
    {
      text: "Salas",
      route: routes.mediaBroker.rooms,
    },
    {
      text: "Nueva sala",
      route: routes.mediaBroker.createRoom,
    },
  ];
  return (
    <Wrapper_1 title="Nueva sala" breadcrumbItems={breadcrumbItems}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <div className="d-flex flex-column ">
              <label className="font-1">Nombre</label>
              <input className="common-input" onChange={() => {}} type="text" />
            </div>
       
          </div>
          <div className="col-12 col-md-3 mt-3 mt-md-0">
            <label className="font-1">Modalidad</label>
            <div className="mt-2 d-flex">
              
              <span
                className={`font-1 small-font active-button-${
                  modality ? "gray" : "black"
                }`}
                onClick={() => {
                  setModality((m) => false);
                }}
              >
                Virtual
              </span>

              <span
                className={`font-1 small-font active-button-${
                  modality ? "black" : "gray"
                }`}
                onClick={() => {
                  setModality((m) => true);
                }}
              >
                Presencial
              </span>
            </div>
          </div>
          <div className={`d-block col-12 col-md-3 mt-3 mt-md-0 ${modality ? "d-block" : "d-none"} `}>
            <div>
              <label className="font-1 w-100">N° de Sala</label>
            </div>
            
            <select className="common-input w-100 " onChange={() => {}}>
              <option value="value1">Seleccionar</option>
            </select>
          </div>
          
          <div className={`col-12 col-md-9 mt-3 ${modality ? "d-block" : "d-none"} `}>
            <div className="d-flex flex-column ">
              <label className="font-1">Dirección</label>
              <input className="common-input" onChange={() => {}} type="text" />
            </div>
          </div>

          <div className=" col-12  mt-3 ">
            <div className="d-flex flex-column ">
              <label className="font-1">Información Extra</label>
              <textarea className="common-input h-6r" />
            </div>  
          </div>

          <div className="d-block">
            <div className="d-block col-12 col-lg-4 mt-3 ">
              <label className="font-1">¿Sala Activa?</label>
              <div className="mt-2 mb-4 d-flex">
                
                <span
                  className={`font-1 small-font active-button-${
                    roomActive ? "gray" : "black"
                  }`}
                  onClick={() => {
                    setRommActive((m) => false);
                  }}
                >
                  SI
                </span>

                <span
                  className={`font-1 small-font active-button-${
                    roomActive ? "black" : "gray"
                  }`}
                  onClick={() => {
                    setRommActive((m) => true);
                  }}
                >
                  NO
                </span>
              </div>
            </div>   
            
            <div className="d-block col-12 col-lg-2 mt-5">
              <button className="blue-button w-100">Guardar</button>
            </div>  
          
             
          </div>
          
        </div>
      </div>
    </Wrapper_1>
  );
}
