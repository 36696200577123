import React, { useEffect, useState } from "react";
import Wrapper_2 from "../../Wrapper_2";
import SearchInput from "../../../Globals/SearchInput";
import Table from "../../Table";

import { routes } from "../../../../static_data/routes";

import {useDispatch, useSelector} from "react-redux";
import {ADVISERS_GET_ACTION} from "../../../../redux/advisersDucks";
import DeleteModalEditor from './DeleteModalEditor'
import Loading from '../../../Globals/Loading'

import { useHistory } from "react-router-dom";

export default function Investors() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ADVISERS_GET_ACTION());
  },[])

  const advisers = useSelector(store => store.advisers.advisers)
  const loading_advisers = useSelector(store => store.advisers.loading_advisers)

  const user_logged = useSelector(store => store.users.user_logged)

  const [searcher,setSearcher] = useState("")

  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.mediaTeam.about,
    },
    {
      text: "MediaTeam®",
      route: routes.mediaTeam.about,
    },
    {
      text: "Asesores",
      route: routes.mediaTeam.editors,
    },
  ];

  return (
    <Wrapper_2
      title="Asesores"
      breadcrumbItems={breadcrumbItems}
      rightLink={{
        route: routes.mediaTeam.createEditor,
        text: "Nuevo Asesor",
        icon: "addIcon",
      }}
    >
      <div className="content-mobile-padding w-100p">
        <SearchInput setSearcher={setSearcher} placeholder="Buscar" className="w-50p mb-2r" />
        <div className="tables-wrapper">
          {loading_advisers ? 
          <Loading /> 
          :
          <EditorsTable searcher={searcher} advisers={advisers.filter(element => element.client_id.toString() === user_logged.client.id.toString())} />
          }
        </div>
      </div>
    </Wrapper_2>
  );
}

function EditorsTable({ searcher,advisers }) {

  const user_logged = useSelector(store => store.users.user_logged)

  const history = useHistory()

  const getTime_text = (time) => {
    const spliter = time.split('T');

    const fecha = spliter[0].split('-');

    const hora_spliter = spliter[1].split('.');
    const hora = hora_spliter[0].split(':')

    // const date_time = new Date(fecha[0],fecha[1],fecha[2],hora[0],hora[1],hora[2]);
    const date_time = fecha[2] + "/"+ fecha[1] + "/" + fecha[0] + " " + hora[0] + ":" + hora[1] + ":" + hora[2];
    return date_time;
  }

  return (
    <Table
      TitleButton={``}
      TableHeader={
        <tr>
          <th className="width-100">Usuario</th>
          <th>Email</th>
          <th>Registrado</th>
          <th>Acciones</th>
        </tr>
      }
      TableRows={advisers.sort((a,b) => b.active - a.active).map((adviser, i) => (
        adviser.name.includes(searcher) || adviser.email.includes(searcher) ?
        <tr className={(adviser.active === false || adviser.active === "false" ? 'opacity-self' : '')} key={i}>
          <td className={"d-lg-flex align-items-center width-custom-self "}>
            <img
              src={process.env.REACT_APP_BASE_URL + (adviser.profile_image ? adviser.profile_image : "/storage/no-profile.jpg")}
              alt="creador de la novedad"
              className="me-2 d-block"
              width="35px"
              style={{ borderRadius: "50%" }}
            />
            {adviser.name}
          </td>
          <td>{adviser.email}</td>
          <td>{getTime_text(adviser.created_at)}</td>
          <td>
            <button className="button-no-styled ">
              <button onClick={() => history.push("asesores/actualizar-asesor/" + adviser.id)} style={{border:"none",background:"transparent"}} ><i className="icon-edit-icon me-0 me-lg-3"></i></button>
              <button style={{border:"none",background:"transparent"}} data-bs-toggle="modal" data-bs-target={"#adviser-"+adviser.id}><i className="icon-delete"></i></button>
                <DeleteModalEditor adviser={{id:adviser.id,name:adviser.name}} />
              </button>
          </td>
        </tr>
        :''
      ))}
    />
  );
}
