import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import {useLocation} from "react-router-dom";


export default function Deployable({
  text,
  rightIcon,
  module_id,
  subButtons = [],
  deployed = false,
  activeButton = false,
  isSmart = false,
  onClick = () => {},
}) {
  const [deploy, setDeploy] = useState(deployed);

  const user_logged = useSelector(store => store.users.user_logged)
  
  function handleClicik() {
    if (user_logged.client.modules.find(user => user.id === module_id)){
    setDeploy((d) => !d);
    }
  }

  const check_lock = () => {
    if(module_id.toString() === '999'){return false}
    if (user_logged.client.modules.find(user => user.id === module_id)){
      return false;
    }
    return true;
  }



  return (
    <>
      <button className={`sidebar-button` + (isSmart ? ' smart-button' : '') + (activeButton ? ' active' : '')} onClick={handleClicik}>
        <div>
          <span>{text}</span>
          {/* {text == "MediaInvestor®" ? <span className="pill-new"> NUEVO </span> : ""} */}
          {/* <span className="pill-new">{text == "MediaInvestor®" ? ("NUEVO") : "" }</span> */}
        </div>
        {/* {module_id ? check_lock(module_id) && <img src={LockIcon} className="icon-locker" alt="right icon button" />:''} */}
        {module_id ? check_lock(module_id) && <i className={"icon-" + rightIcon}></i>:''}
        {/* {module_id ?!  check_lock(module_id) && <img src={arrowDown} className={"icon-down-arrow ms-lg-3 d-md-block " + (deploy ? "rotate-180" : '')} alt="avatar" /> :''} */}
        {module_id ?! check_lock(module_id) && <i className={"ms-lg-3 d-md-block icon-arrow-down" + (deploy ? " rotate-180" : '')}></i> :''}
      </button>

      {subButtons.length !== 0 && (
        <div className={`w-100 ${deploy ? "h-animation-open" : "h-animation-close"}`}>
          {subButtons.map((btn, i) => (
            <SidebarButton key={i} {...btn} onClick={onClick} />
          ))}
        </div>
      )}
    </>
  );
}

export const SidebarButton = ({
  text,
  leftIcon,
  rightIcon,
  onClick,
  route = "/",
  }) => {

  const router = useHistory();
  const location = useLocation()

  function handleClick() {
    onClick();
    router.push(route);
  }

  const getCurrentView = (name) => {
    return location?.pathname.includes(name)
  }

  return (
    <NavLink
      to={route}
      className={`sidebar-button deployable m-0 ` + (getCurrentView(route) ? 'active' : '')}
      activeClassName=""
      onClick={handleClick}
    >
      <div>
        {leftIcon && <img src={leftIcon} alt="left icon button" width="18px" />}
        <span className="ms-lg-3">{text}</span>
      </div>
      {/* {rightIcon && <img src={rightIcon} width="18px" alt="right icon button" />} */}
      {rightIcon && <i className={"iconos-sidebar icon-" + rightIcon}></i>}
    </NavLink>
  );
};
