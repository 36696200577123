import React from "react";
import Breadcrumb from "./Breadcrumb";
import { Link } from "react-router-dom";
import {check_access} from '../../functions/accessFunctions'
import { useSelector } from "react-redux";

export default function Wrapper_2({
  title,
  subTitle,
  breadcrumbItems,
  rightLink,
  rightMessage,
  check,
  children,
}) {

  const user_logged = useSelector(store => store.users.user_logged)

  return (
    <div className="content">
      <div>
        <Breadcrumb items={breadcrumbItems} />
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h1 className="text-blue-1 m-0">{title}</h1>
          {rightLink && check_access(check,user_logged.scopes) ? (
            <Link
              to={rightLink.route}
              className="text-decoration-none text-gray-9 hover-text-blue-1 d-flex justify-content-center align-items-center gap-2"
            >
              
              <i className="icon-addIcon bg-client"></i>
              <p className="font-1 p-0 m-0" style={{lineHeight:1}}>{rightLink.text}</p>
            </Link>
          ):
          <>
            {/* <div style={{opacity: ".3"}} className="text-decoration-none text-gray-9 hover-text-blue-1 d-flex justify-content-center align-items-center gap-2">
              <img src={rightLink.icon} alt={rightLink.text} />
              <i className="icon-add-icon"></i>
              <p className="font-1 p-0 m-0">{rightLink ? rightLink.text : 'None'}</p>
            </div> */}
          </>
          
          }
          {rightMessage && (
            <span className="font-1">
              <img src={rightMessage.icon} alt={rightMessage.text} />
              <strong> {rightMessage.text}</strong>
            </span>
          )}
        </div>
        { subTitle ? <h3 className=" mt-2">{subTitle}</h3> : ""}
      </div>

      <div className="container-fluid container2">{children}</div>
    </div>
  );
}
