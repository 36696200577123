import axios from 'axios'

// Constantes
const dataInicial = {
    investors:[],
    investor:{},
    developments:[],
    development:{},
    tags:[],
    loading_investors:false,
    loading_absolute:false,
    response:{},
}

// Types
const LOADING_INVESTORS = 'LOADING_INVESTORS'
const LOADING = 'LOADING'
const LOADING_INVESTORS_ABSOLUTE = 'LOADING_INVESTORS_ABSOLUTE'
const GET_INVESTORS = 'GET_INVESTORS'
const GET_INVESTOR = 'GET_INVESTOR'
const ADD_INVESTOR = 'ADD_INVESTOR'
const UPDATE_INVESTOR = 'UPDATE_INVESTOR'
const UPDATE_IMAGE_INVESTOR = 'UPDATE_IMAGE_INVESTOR'
const UPDATE_POSITION = 'UPDATE_POSITION'
const DELETE_INVESTOR = 'DELETE_INVESTOR'

const GET_INVESTORS_ERROR = 'GET_INVESTORS_ERROR'
const CLEAR_STATUS = 'CLEAR_STATUS'

const CLEAR_INVESTOR = 'CLEAR_INVESTOR'
const GET_INVESTOR_TYPES = 'GET_INVESTOR_TYPES'

const INVESTOR_UPDATE_BACKGROUND = 'INVESTOR_UPDATE_BACKGROUND'
const INVESTOR_UPDATE_LOGO = 'INVESTOR_UPDATE_LOGO'
// Reducer
export default function investorReducer(state = dataInicial, action){
    switch(action.type){
        case CLEAR_STATUS:{ //Clear status
            return {
                ...state, response : {},
            }
        }
        case CLEAR_INVESTOR:{ //Clear investor
            return {
                ...state, investor : {},
            }
        }
        case LOADING_INVESTORS:{ //Loading
            return {
                ...state, loading_investors : true,
            }
        }
        case LOADING_INVESTORS_ABSOLUTE:{ //Loading
            return {
                ...state, loading_absolute : true,
            }
        }
        case GET_INVESTORS:{ //Get all investor
            return {
                ...state, investors : action.payload.data.data.users.data,
                loading_investors:false 
            }
        }
        case GET_INVESTOR: {// Get details client
            return {
                ...state, investor : action.payload,
                loading_investors:false 
            }
        }
        case ADD_INVESTOR:{ // Add investor
            return {...state, investors: [...state.investors,action.payload.data.data.user],                
                response:{
                    status:action.payload.status,
                    statusText:"El inversor fue creado correctamente.",
                    success:true
                },
                loading_investors:false,
                loading_absolute:false,
            }
        }
        case UPDATE_INVESTOR:{ // Update 
            return {...state,
                investors: [...state.investors.filter(investor => investor.id !== action.payload.data.data.user.id),action.payload.data.data.user], 
                response:{
                    status:action.payload.status,
                    statusText:"El inversor fue actualizado correctamente.",
                    success:true,
                },
                loading_investors:false,
                loading_absolute:false,
            }
        }
        case UPDATE_POSITION:{ // Update 
            return {...state,
                investors: [...state.investors.filter(investor => investor.id !== action.payload.data.data.investor.id),action.payload.data.data.investor], 
                loading_investors:false,
                loading_absolute:false,
            }
        }
        case UPDATE_IMAGE_INVESTOR:{ // Update 
            return {...state,
                investors: [...state.investors.filter(investor => investor.id !== action.payload.data.data.investor.id),action.payload.data.data.investor], 
                loading_investors:false,
                loading_absolute:false,
                response:{
                    success:true,
                    statusText:"La imagen de portada fue actualizada correctamente.",
                    status:200,
                }
            }
            
        }
        case INVESTOR_UPDATE_BACKGROUND:{ // Update 
            return {...state,
                loading_absolute:false,
                response:{
                    success:true,
                    statusText:"La imagen de fondo fue actualizada correctamente.",
                    status:200,
                }
            }
            
        }
        case INVESTOR_UPDATE_LOGO:{ // Update 
            return {...state,
                loading_absolute:false,
                response:{
                    success:true,
                    statusText:"El logo fue actualizado correctamente.",
                    status:200,
                }
            }
            
        }
        case DELETE_INVESTOR:{ // Delete
            return {...state, investors: [...state.investors.filter((investor) => investor.id !== action.payload.data.data.user.id)],                
                response:{
                    status:action.payload.status,
                    statusText:"El inversor fue eliminado correctamente.",
                    success:true
                },
                loading_investors:false
            }
        }
        case GET_INVESTORS_ERROR:{ // Set errors
            return {...state,
                response:{
                    status:action.payload.status,
                    statusText:action.payload.statusText,
                    success:false,
                },
                loading_investors:false,
                loading_absolute:false
            }
        }
        default:
            return state;
    }
}
// Actions
export const CLEAR_STATUS_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_STATUS,payload: true})
}
export const CLEAR_INVESTOR_ACTION = () => async (dispatch, getState) => {
    dispatch({type: CLEAR_INVESTOR,payload: true})
}
export const INVESTORS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING_INVESTORS,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/users?limit=1000',{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_INVESTORS,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const INVESTOR_GET_ACTION = (id) => async (dispatch, getState) => {
dispatch({type: LOADING_INVESTORS,payload: true})
try {
const res = await axios.get('https://dev.mediacore.com.ar/api/v1/users/'+ id,{
    headers:{
        'Authorization': 'Bearer ' + getState().users.user_logged.token
    },
})
dispatch({
    type: GET_INVESTOR,
    payload: res.data.data.user
})
} catch (error) {
dispatch({type: GET_INVESTORS_ERROR,payload:error.response})
console.log(error)
}
}
export const DEVELOPMENTS_GET_ACTION = () => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.get('https://dev.mediacore.com.ar/api/v1/investors/search?limit=100&clients=' + getState().users.user_logged.client.id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            }
        })
        dispatch({type: GET_INVESTORS,payload: res})
    } catch (error) {
        console.log(error)
    }
}
export const DEVELOPMENT_GET_ACTION = (id) => async (dispatch, getState) => {
dispatch({type: LOADING,payload: true})
try {
const res = await axios.get('https://dev.mediacore.com.ar/api/v1/investors/'+ id,{
    headers:{
        'Authorization': 'Bearer ' + getState().users.user_logged.token
    },
})
dispatch({
    type: GET_INVESTOR,
    payload: res.data.data.investor
})
} catch (error) {
dispatch({type: GET_INVESTORS_ERROR,payload:error.response})
console.log(error)
}
}
export const INVESTOR_ADD_ACTION = (data) => async (dispatch, getState) => {
    
    dispatch({type: LOADING_INVESTORS,payload: true})
    dispatch({type: LOADING_INVESTORS_ABSOLUTE,payload: true})

    var dataSend = new FormData();
    dataSend.append("name", data.name);
    dataSend.append("nickname", data.name + "-" + getState().users.user_logged.client.id);
    dataSend.append("email", data.email);
    dataSend.append("password", data.password);
    dataSend.append("level", 1);
    dataSend.append("info", data.info);
    dataSend.append("phone", data.phone);
    for (const iterator of data.tags) {
        dataSend.append("tags[]", iterator);
    }
    dataSend.append("active", data.active ? 1 : 0);
    dataSend.append("client_id", getState().users.user_logged.client.id);

    //Scopes
    dataSend.append("scopes[]", "posts.read");
    dataSend.append("scopes[]", "categories.read");
    dataSend.append("scopes[]", "tags.read");
    dataSend.append("scopes[]", "groups.read");
    dataSend.append("scopes[]", "posts.list");
    dataSend.append("scopes[]", "categories.list");
    dataSend.append("scopes[]", "tags.list");
    dataSend.append("scopes[]", "groups.list");
    
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/users',dataSend,{
            headers:{
                "Authorization": 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        console.log(res)
        dispatch({
            type: ADD_INVESTOR,
            payload: res
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_INVESTORS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
        console.log(error)
    }
}
export const INVESTOR_UPDATE_ACTION = (data,id) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    dispatch({type: LOADING_INVESTORS_ABSOLUTE,payload: true})
    var dataSend = {};
    data.name !== getState().investors.investor.name ? dataSend.name = data.name : '';
    data.email !== getState().investors.investor.email ? dataSend.email = data.email : '';
    data.password !== getState().investors.investor.password && data.password !== '' ? dataSend.password = data.password : '';
    data.info !== getState().investors.investor.info ? dataSend.info = data.info : '';
    data.phone !== getState().investors.investor.phone ? dataSend.phone = data.phone : '';
    data.active !== getState().investors.investor.active ? dataSend.active = data.active : '';
    dataSend.tags = data.tags;
    //Scopes
    dataSend.scopes = [
        "posts.read",
        "categories.read",
        "tags.read",
        "groups.read",
        "posts.list",
        "categories.list",
        "tags.list",
        // "ajuste.list"
    ];

    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/users/'+id,dataSend,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
            },
        })
        console.log(res)
        dispatch({
            type: UPDATE_INVESTOR,
            payload: res,
        })
    } catch (error) {
        let errors_texts = []
        for (const iterator of Object.values(error.response.data.errors)) {
            for (const text of iterator){
                errors_texts.push(text)
            }
        }
        dispatch(
            {type: GET_INVESTORS_ERROR,
            payload:{
                status:error.response.status,
                statusText:errors_texts,
                success:false
            }
        })
    }
}
export const INVESTOR_DELETE_ACTION = (id) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    try {
        const res = await axios.delete('https://dev.mediacore.com.ar/api/v1/users/'+id,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: DELETE_INVESTOR,
            payload: res
        })
    } catch (error) {
        dispatch({
            type: GET_INVESTORS_ERROR,
            payload:{
                status:400,
                statusText:["Ocurrió un error inesperado."],
                success:false
            }
        })
        console.log(error)
    }
}
export const INVESTORS_UPDATE_FEATURED_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING,payload: true})
    let response = {}
    response.featured = data
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/investors/'+id,response,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        console.log(res)
        dispatch({
            type: UPDATE_INVESTOR,
            payload: res,
        })
    } catch (error) {
        console.log(error)
        dispatch(
            {
                type: GET_INVESTORS_ERROR,
                payload:{
                    status:400,
                    statusText:'Ocurrió un eror',
                    success:false,
                }
            })
    }
}
export const INVESTOR_UPDATE_BACKGROUND_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING_INVESTORS_ABSOLUTE,payload: true})
    var formData = new FormData();
    formData.append("investors_background", data);
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/clients/'+id+'/images',formData,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        dispatch({
            type: INVESTOR_UPDATE_BACKGROUND,
            payload: res
        })
        console.log(res)
    } catch (error) {
        console.log(error.response.data.investors_background)
        const errorRes = error?.response?.data?.investors_background[0] === 'El archivo investors background no debe pesar más de 3072 kilobytes.' ? ['El archivo es muy pesado'] : ['Ocurrió un error inesperado']
        dispatch(
            {type: GET_INVESTORS_ERROR,
            payload:{
                status:400,
                statusText:errorRes,
                success:false
            }
        })
    }
}
export const INVESTOR_UPDATE_LOGO_ACTION = (id,data) => async (dispatch, getState) => {
    dispatch({type: LOADING_INVESTORS_ABSOLUTE,payload: true})
    var formData = new FormData();
    formData.append("investors_logo", data);
    try {
        const res = await axios.post('https://dev.mediacore.com.ar/api/v1/clients/'+id+'/images',formData,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token,
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
        })
        dispatch({
            type: INVESTOR_UPDATE_LOGO,
            payload: res
        })
    } catch (error) {
        console.log(error)
        dispatch(
            {type: GET_INVESTORS_ERROR,
            payload:{
                status:400,
                statusText:["Hay un error en la subida."],
                success:false
            }
        })
    }
}
export const INVESTOR_UPDATE_POSITION_ACTION = (id,data) => async (dispatch, getState) => {
    // dispatch({type: LOADING,payload: true})
    let response = {}
    response.position = data
    try {
        const res = await axios.put('https://dev.mediacore.com.ar/api/v1/investors/'+id, response,{
            headers:{
                'Authorization': 'Bearer ' + getState().users.user_logged.token
            },
        })
        dispatch({
            type: UPDATE_POSITION,
            payload: res,
        })
    } catch (error) {
        console.log(error)
        dispatch(
            {
                type: GET_INVESTORS_ERROR,
                payload:{
                    status:400,
                    statusText:'Ocurrió un eror',
                    success:false,
                }
            })
    }
}
