import React, { useState, useRef, useEffect } from "react";
import Wrapper_1 from "../Wrapper_1";
import { routes } from "../../../static_data/routes";
import { CLIENTS_GET_ACTION, CLIENTS_TYPE_GET_ACTION,MODULES_GET_ACTION } from "../../../redux/clientsDucks"
import { USER_UPDATE_ACTION,USER_GET_ACTION} from "../../../redux/userDucks"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Loading from '../../Globals/Loading'

import { Link } from "react-router-dom";

const Rol = [
  {
    id:"9",
    name:"Super Administrador",
  },
  {
    id:"10",
    name:"Administrador",
  },
  {
    id:"1",
    name:"Inversor",
  },
]

export default function CreateUser() {

  const dispatch = useDispatch();

  const history = useHistory();

  const {id} = useParams()
  const client_types = useSelector(store => store.clients.client_types)
  const response = useSelector(store => store.users.response)
  const modules = useSelector(store => store.clients.modules)
  const clients = useSelector(store => store.clients.clients)
  const user = useSelector(store => store.users.user)

  const [datos, setDatos] = useState({
    name:'',
    nickname:'',
    email:'',
    password:'',
    level:'',
    phone:'',
    client_id:'',
    scopes:[]
  })


  useEffect(() => {
    if(user){
      setDatos({...datos,
        name:user?.name,
        nickname:user?.nickname,
        email:user?.email,
        level:user?.level,
        phone:user?.phone,
        client_id:user?.client?.id,
        scopes:getScopesFromLevel(user?.level)
      })
    }
  },[user])


  useEffect(() => {
      if(response.success){
        history.push(routes.mediaSmart.users)
      }
  },[response])
  const breadcrumbItems = [
    {
      text: "MediaCore®",
      route: routes.base,
    },
    {
      text: "Usuarios",
      route: routes.mediaSmart.users,
    },
    {
      text: "Nuevo usuario",
      route: routes.mediaSmart.createUser,
    },
  ];

  const getScopesFromLevel = (level) => {
    var scopes = []
    switch(level){
      case '1':{
        scopes = ["groups.list","groups.read","posts.read","categories.read","tags.read","posts.list","categories.list","tags.list"];
        break;
      }
      case '10':{
        // scopes = ["posts.read","categories.read","tags.read","posts.list","categories.list","tags.list","posts.create","categories.create","tags.create","posts.write","categories.write","tags.","posts.delete","categories.delete","tags.delete"];
        // scopes = ["posts","categories","tags","users"];
        scopes = ["posts","categories","tags","users","clients","files","groups"]; // SOLO HASTA QUE ESTE /CLIENTS/ME
        break;
      } 
      case '9':{
        scopes = ["*"];
        break;
      }
    }
    return scopes
  }

  const handleCreateUser = (e) => {
    e.preventDefault();
    dispatch(USER_UPDATE_ACTION(id,datos));
  }
  const handleUpdateUser = (e) => {
    if(e.target.name === 'level'){
      console.log(getScopesFromLevel(e.target.value))
      setDatos({
        ...datos,
        scopes:getScopesFromLevel(e.target.value),
        [e.target.name] : e.target.value, 
      })
    }
    else{
      setDatos({
        ...datos,
        [e.target.name] : e.target.value, 
      })
    }
  }

  useEffect(() => {
    dispatch(CLIENTS_TYPE_GET_ACTION());
    dispatch(CLIENTS_GET_ACTION());
    dispatch(USER_GET_ACTION(id))
    dispatch(MODULES_GET_ACTION());
  },[])

  console.log(datos)

  return client_types && modules && user.name ? (
    <Wrapper_1 title="Nuevo usuario" breadcrumbItems={breadcrumbItems}>
      <form onSubmit={handleCreateUser} className="content-mobile-padding w-100p media-core">
        <div className="grid-3-col">
          <div className="d-flex flex-column ">
            <label className="font-1">Nombre y apellido</label>
            <input className="common-input" autoComplete="on" name="name" value={datos?.name} onChange={handleUpdateUser} type="text" />
          </div>
          <div className="d-flex flex-column ">
            <label className="font-1">Nombre de usuario</label>
            <div className="d-flex gap-0">
              <input className="w-100 common-input" autoComplete="none" value={datos?.name?.toLowerCase()?.replaceAll(" ",".")} name="nickname" onChange={handleUpdateUser} type="text" />
            </div>
          </div>
        </div>
        <div className="grid-3-col mt-3">
          <div className="d-flex flex-column">
            <label className="font-1">Email</label>
            <input className="common-input" value={datos.email} autoComplete="none" name="email" onChange={handleUpdateUser} type="text" />
          </div>
          <div className="d-flex flex-column">
            <label className="font-1">Contraseña</label>
            <input className="common-input" value={datos.password} autoComplete="none" name="password" onChange={handleUpdateUser} type="password" />
          </div>
        </div>
        <div className="grid-3-col mt-3">
          <div className="d-flex flex-column">
            <label className="font-1">Teléfono</label>
            <input className="common-input" value={datos.phone} autoComplete="none" name="phone" onChange={handleUpdateUser} type="phone" />
          </div>
          <div className="d-flex flex-column">
            <label className="font-1 mb-1">Rol</label>
            <div className="d-flex gap-0">
              {datos.level &&
                Rol?.map((element,index) => (
                  <span className={`font-1 small-font active-button-${datos?.level.toString() === element?.id?.toString() ? "blue" : "gray"}`}
                  onClick={() => handleUpdateUser({target:{name:"level",value:element?.id}})}>
                  {element?.name}
                  </span>
                ))
              }
            </div>
          </div>
        </div>
        <div className="grid-3-col mt-3 mb-4">
          <div className="d-flex flex-column">
            <label className="font-1">Cliente</label>
            <select className="common-input" value={datos.client_id} onChange={handleUpdateUser} name="client_id">
              <option value="">{"Selecciona un cliente"}</option>
              {clients.map((element) =>(
                 element.id > 5 &&
                  <option value={element.id}>{element.name}</option>
                ) )}
            </select>
          </div>
        </div>
        <button className="blue-button mt-1r">Guardar</button>
        <Link to={routes.mediaSmart.users}>
          <button  className={"cancell-button mt-1r ms-3 "}>Cancelar</button>
        </Link>
      </form>
    </Wrapper_1>
  ):<Loading />;
}