import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {useParams} from 'react-router-dom'


import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { POST_UPDATE_ACTION,POST_GET_ACTION,POST_UPDATE_IMAGE_ACTION,FILE_ADD_ACTION,FILE_DELETE_ACTION,USERS_BY_POST_GET_ACTION } from '../../../../redux/blogsDucks'
import { TAGS_GET_ACTION } from '../../../../redux/tagsDucks'
import { INVESTORS_GET_ACTION } from '../../../../redux/investorsDucks'

import { CATEGORIES_GET_ACTION } from '../../../../redux/categoryDucks'

import { routes } from "../../../../static_data/routes";

import DatePicker from "react-datepicker";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.css";

import es from 'date-fns/locale/es';
import FileInput from "../../../Globals/FileInput";
import InputDateTime from "../../../Globals/DateTimeInput";
import SearchInput from "../../../Globals/SearchInput";
import H2 from "../../../Globals/H2";
import Wrapper_2 from "../../Wrapper_2";
import { toast } from "react-hot-toast";
  

const breadcrumbItems = [
  {
    text: "MediaCore®",
    route: routes.mediaInvestor.about,
  },
  {
    text: "MediaInvestor®",
    route: routes.mediaInvestor.about,
  },
  {
    text: "Novedades",
    route: routes.mediaInvestor.news,
  },
  {
    text: "Actualizar novedad",
    route: routes.mediaInvestor.createNew,
  },
];

export default function CreateNew() {

  const [inputDate, setInputDate] = useState(new Date());
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const {id} = useParams()

  const inputImage = useRef(null);

  const dispatch = useDispatch();
  const response = useSelector(store => store.posts.response)
  const tags = useSelector(store => store.tags.tags)
  const post = useSelector(store => store.posts.post)
  const users = useSelector(store => store.users.users)
  const user_logged = useSelector(store => store.users.user_logged)
  const investors = useSelector(store => store.investors.investors)
  const {investors_post} = useSelector(store => store.posts)
  const categories = useSelector(store => store.categories.categories)

  const [startDate, setStartDate] = useState(new Date());
  const [searcher,setSearcher] = useState('')

  const [active, setActive] = useState(false);

  const history = useHistory()

  const [datos, setDatos] = useState({
    title:'',
    user_id:1,
    excerpt:'',
    image_caption:'',
    image:'',
    content:'Contenido de prueba.',
    active:1,
    tags:[],
    users:[],
    featured:0,
    publish_date:new Date()
  })

  const [content, setContent] = useState('');
  
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const [dateTime, setDateTime] = useState(new Date());

  const fileRef = useRef();
  const picture_profile = useRef();
  const [imgToUpload, setImgToUpload] = useState();
  const [drag, setDrag] = useState(false);
  const [renderImage,setRenderImage] = useState();

  const [sizeInput,setSizeInput] = useState(255);


  const changeFile = () => {
    const image = picture_profile.current.files[0];
    setImgToUpload(image);
    // console.log(picture_profile.current.files[0])
    setDatos({ ...datos, image: picture_profile.current.files[0] });
    loadImage(image);
  };
  const onDragEnter = (event) => {
    event.preventDefault();
    setDrag(true);
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files;
    picture_profile.current.files = file;
  };
  const loadImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setRenderImage(e.target.result)
    };
    reader.readAsDataURL(file);
  };
  const uploadCallbackFunction = async (file) => {
    const imgData = await uploadInlineImageForArticle(file);
    return Promise.resolve({ data: { 
      link: `${imgData}`
    }});
  }
   const uploadInlineImageForArticle = async (file) => {
    // const headers = await this.getAuthHeader();
    let formData = new FormData();
    formData.append('image', file);
    try {
      const {data} = await axios.post('https://dev.mediacore.com.ar/api/v1/images',formData,{
        headers:{
          'Authorization': 'Bearer ' + user_logged.token
        }
      })
      return data.data.uri;
    } catch (error) {
        console.log(error);
      return null;
    }
  }

  const handleChange = () => {
    dispatch(FILE_ADD_ACTION({"id":datos.id,"file":fileRef.current.files[0]}))
  }

  useEffect(() => {
    dispatch(POST_GET_ACTION(id))
    dispatch(TAGS_GET_ACTION())
    dispatch(CATEGORIES_GET_ACTION())
    dispatch(INVESTORS_GET_ACTION())
    dispatch(USERS_BY_POST_GET_ACTION(id))
  },[])

  useEffect(() => {
    if(post.title){
        let curTags = [];
        let curUsers = [];
        for (const iterator of post.tags) {
          curTags.push(iterator.id.toString())
        }
        if(investors_post?.users){
          for (const iterator of investors_post?.users) {
              curUsers.push(iterator.id)
            }
        }
        if(!datos.title){
          setDatos({
            id:post.id,
            title:post.title,
            user_id:post.user_id,
            excerpt:post.excerpt,
            image_caption:post.image_caption,
            image:post.image,
            content:post.content,
            tags:curTags,
            active:post.active,
            featured:post.featured,
            files:post.files,
            publish_date:new Date(post.publish_date),
            users:curUsers
          })
          const blocksFromHtml = htmlToDraft(post.content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setEditorState(EditorState.createWithContent(contentState))
        }
        else{
          setDatos({
            ...datos,
            files:post.files,
          })
        }
        
    }
  },[post,investors_post])

  useEffect(() => {
    if(post){
      if(datos.image !== post.image && datos.image !== ''){
        dispatch(POST_UPDATE_IMAGE_ACTION(post.id,datos.image))
        
      }
    }
  },[datos.image])

  useEffect(() => {
    if(response.success && !response.notRealod){
      history.push("/app/media-investor/novedades")
    }
  },[response])

  const getCategory = (category) => {
    return categories?.find(item => item.name === category)?.id
  }
  const handleCreateNew = (e) => {
    e.preventDefault();
   setDatos({
      ...datos,
      user_id : user_logged.id, 
      publish_date:startDate,
      categories:getCategory('investor')
    }) 
    if (!datos.image){
      toast('La portada es obligatoria.', { type: 'error' })
    }
    else if (sizeInput - datos.excerpt.length < 0){
      toast('El subtitulo debe tener un máximo de 255 caracteres.', { type: 'error' })
    }
    else if (sizeInput - datos.image_caption.length < 0){
      toast('El pie de imagen debe tener un máximo de 255 caracteres.', { type: 'error' })
    }
    else{
      dispatch(POST_UPDATE_ACTION(datos,post.id));
    }

  }
  const handleUpdateNew = (e) => {
    if(e.target.name == 'tags'){
      if(datos.tags.find(element => element === e.target.value)){
          const newTags = datos.tags.filter(element => element !== e.target.value)
          setDatos({
              ...datos,
              [e.target.name] : newTags, 
          })
          return null
      }
      else{
        setDatos({
          ...datos,
          [e.target.name] : [...datos.tags,e.target.value], 
        })
        return null
      }
    }
    else if (e.target.name == 'users'){
      if(datos.users.find(element => element.toString() === e.target.value.toString())){
          const newUsers = datos.users.filter(element => element.toString() !== e.target.value.toString())
          console.log(newUsers)
          setDatos({
              ...datos,
              [e.target.name] : newUsers, 
          })
          return null
      }
      else{
        setDatos({
          ...datos,
          [e.target.name] : [...datos.users,e.target.value], 
        })
        return null
      }
    }
    setDatos({
      ...datos,
      [e.target.name] : e.target.value, 
      categories:getCategory('investor')
    })
  }
  const setPublish_date = (date) => {
    setDatos({
      ...datos,
      publish_date:date
    })
  }


  return (
    <Wrapper_2 breadcrumbItems={breadcrumbItems} title="Actualizar novedad">
      <form onSubmit={handleCreateNew} className="content-mobile-padding">
        <div className="grid-3-bigbreak">
          <div className="mi-create-new-step">
            <H2
              font="font-1"
              color="text-black-1"
              className="text-center"
              text={
                <>
                  <strong>PASO 1</strong> - Información principal
                </>
              }
            />
            <hr />
            <div className="d-flex flex-column ">
              <label className="font-1">Título</label>
              <input
                className="common-input"
                type="text"
                name="title"
                value={datos.title}
                placeholder="Título"
                onChange={handleUpdateNew}
              />
            </div>
            <div className="d-flex flex-column mt-1r">
              <label className="font-1">Subtítulo</label>
                <input
                type="text"
                name="excerpt"
                value={datos.excerpt}
                placeholder="Subtítulo"
                onChange={handleUpdateNew}
                className={"common-input " + (sizeInput - datos.excerpt.length < 0 ? "border-danger" : "")}
              />
              <label className={"mt-2 " + ((sizeInput - datos.excerpt.length) > 100 && ' d-none ') + ((sizeInput - datos.excerpt.length) < 0 && ' text-danger')}>
                {(sizeInput - datos.excerpt.length) + " caracteres disponibles"}
              </label>
            </div>

            <div class="content-img mt-3">
              <label for="addFile" className="font-1">Subir portada</label>  
                <div className={"drag mb-4 uploader w-100 d-flex align-items-center " + drag}
                  dragleave={onDragLeave}
                  dragenter={onDragEnter}
                  onDrop={onDrop}
                 >
                  <div className={`d-flex flex-column w-100`}>
                    <span for="addFile" className={`image-picker-button small-font `}>
                      <label  for="addFile">
                        AGREGAR IMAGEN (JPG, PNG 1200x900)
                      </label>
                    </span>
                    <input
                      type="file"
                      name="addFile"
                      className="d-none"
                      ref={picture_profile}
                      onChange={changeFile}
                      id="addFile"
                    />
                  </div>
                  <img
                    className={"mx-0 mt-0 ms-2 " +( renderImage ? renderImage : datos.image ? datos.image : ' d-none')}
                    src={renderImage ? renderImage : datos.image ? "https://dev.mediacore.com.ar" + datos.image : ''}
                    width="150"
                  />{" "}
                </div>
              </div>

            <div className="d-flex flex-column mt-1r">
              <label className="font-1">Pié de portada</label>
              <input
                type="text"
                name="image_caption"
                className={"common-input " + (sizeInput - datos.image_caption.length < 0 ? "border-danger" : "")}
                value={datos.image_caption}
                onChange={handleUpdateNew}
                placeholder="Pié de portada"
              />
            <label className={"mt-2 " + ((sizeInput - datos.image_caption.length) > 100 && ' d-none ') + ((sizeInput - datos.image_caption.length) < 0 && ' text-danger')}>
              {(sizeInput - datos.image_caption.length) + " caracteres disponibles"}
            </label>
            </div>

            <div className="d-flex flex-column mt-1r">
              <label className="font-1">Fecha</label>
              <div className="d-flex input self-width align-items-center">
                <DatePicker dateFormat="dd/MM/yyyy" selected={datos.publish_date} onChange={(date) => setPublish_date(date)}  locale="es" />
                <i className="icon-calendar icon-regular icon-body"></i>
              </div>
            </div>
          </div>
          <div className="mi-create-new-step d-flex flex-column align-items-start ">
            <H2
              font="font-1"
              color="text-black-1"
              className="text-center w-100p mb-0"
              text={
                <>
                  <strong>PASO 2</strong> - Contenido de la novedad
                </>
              }
            />
            <hr className="w-100 mt-1r mb-1r" />

            <div className="d-flex flex-column w-100 h-100">
              <div className="common-input py-3 h-100 d-flex flex-column">
              <Editor
                  editorState={editorState}
                  wrapperClassName="card"
                  editorClassName="card-body"
                  onEditorStateChange={newState => {
                      setEditorState(newState);
                      handleUpdateNew({target:{name:"content",value:(draftToHtml(convertToRaw(newState.getCurrentContent())))}});;
                  }}
                  toolbar={{
                      options: ['inline', 'blockType', 'list','link', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                      inline: { 
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                      blockType: {
                        inDropdown: false,
                        options: ['H1', 'H2', 'H3'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      image: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: true,
                        uploadCallback:uploadCallbackFunction,
                        previewImage: true,
                        inputAccept: 'image/jpeg,image/jpg,image/png',
                        defaultSize: {
                          height: 'auto',
                          width: '100%',
                        },
                      },
                  }}
              />
                <div className={`d-flex flex-column mt-4`}>
                  {/* <label className="font-1">"Agregar Archivo"</label> */}
                  <label className={`image-picker-button small-font d-flex align-items-center justify-content-center`} htmlFor="addFiles" for="addFiles">
                    Subir archivos (DOC, DOCX, PDF, XLS, XLSX)
                  </label>
                  <input
                    type="file"
                    id="addFiles"
                    ref={fileRef}
                    onChange={handleChange}
                    className="d-none"
                  />
                </div>
                <div className="mt-3 d-flex flex-wrap">
                  {datos?.files?.map((element,index) => (
                    <span className="files-content d-flex align-items-center justify-content-between">
                      <a href={element.uri} target="_blank">{element.path.split("_")[1]}</a>
                      <div className="cursor-pointer" onClick={() => dispatch(FILE_DELETE_ACTION(element.id))}>
                      <i className="icon-delete icon-regular icon-body"></i>
                      </div>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mi-create-new-step">
            <H2
              font="font-1"
              color="text-black-1"
              className="text-center"
              text={
                <>
                  <strong>PASO 3</strong> - Tipo de novedad
                </>
              }
            />
            <hr />
            <div className="w-100p">

              <div className="d-flex flex-column">
                <label className="font-1">
                  Novedad exclusiva para todos los inversores
                </label>
                <div className="grid-3-col mt-1r">
                  {
                    <div className="d-flex">
                    <input
                      type="checkbox"
                      className="checkbox me-1"
                      name="type"
                      value="oportunidad"
                      disabled={true}
                      checked={datos.tags.length === 0 && datos.users.length === 0 ? true : false}
                    />
                    <label className={`small-font  `}>
                      Prelanzamiento
                    </label>
                  </div>
                  }
                </div>
              </div>
              <hr className="mb-1r" />

              <div className="d-flex flex-column">
                <label className="font-1">Novedad para grupo inversor</label>
                <div className="grid-3-col-min-2-col w-100p mt-1r">
                  {tags?.filter(element => element?.categories?.find(element => element.id === getCategory("investor"))).map((group, i) => (
                    <div className="d-flex">
                    <input
                      type="checkbox"
                      // checked={datos.tags.find(element => element === group.id.toString())}
                      onClick={handleUpdateNew}
                      className="checkbox me-1"
                      name="tags"
                      value={group.id}
                      checked={datos.tags.find(element => element.toString() === group.id.toString()) ? true : false}
                      disabled={datos.users.length === 0 ? false : true}
                    />
                    <label className={`small-font  `}>
                      {group.name}
                    </label>
                  </div>
                  ))}
                </div>
              </div>
              <hr className="mb-1r" />

              <div className="d-flex flex-column">
                <label className="font-1">
                  Novedad exclusiva para un inversor
                </label>
                <SearchInput setSearcher={setSearcher} className="mt-1r" />
                <div className="mi-investor-checkboxes grid-2-col mt-1r">
                  {investors.filter(element => element.client_id.toString() === user_logged.client.id.toString() && element.level === "1").map((investor, i) => (
                    investor.name.includes(searcher) ?
                     <div className="d-flex">
                     <input
                       type="checkbox"
                       onClick={handleUpdateNew}
                       className="checkbox me-1"
                       name="users"
                       checked={datos.users.find(post_investor => post_investor === investor.id)}
                       value={investor.id}
                     />
                     <label className={`small-font  `}>
                       {investor.name}
                     </label>
                    </div>:''
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mi-create-new-step mt-1r">
          <H2
            font="font-1"
            color="text-black-1"
            className="text-center"
            text={
              <>
                <strong>PASO 4</strong> - Finalizar alta de novedad
              </>
            }
          />
          <div className="w-50p m-auto">
            <hr />
            <div className="d-flex flex-row justify-content-center align-items-center gap-4">
              <button  onClick={ () => setDatos({...datos,active:"1"})} type="submit" className="blue-button px-4">Guardar</button>
              <button onClick={ () => setDatos({...datos,active:"0"})} type="submit" className="blue-button px-4">Borrador</button>
            </div>
          </div>
        </div>
      </form>
    </Wrapper_2>
  );
}

function Checkbox({ is = false, text }) {
  const [checked, setChecked] = useState(is);
  return (
    <div className="d-flex align-items-center">
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
        className="checkbox me-1"
      />
      <label className={`small-font  ${checked ? "font-0" : "font-1"}`}>
        {text.name}
      </label>
    </div>
  );
}
