import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import LogoIcon from '../../assets/svgs/brand-logo.svg'
import {CLIENTS_GET_ACTION,USER_LOGIN_ACTION,USER_GET_LOGIN_ACTION,USER_SET_REDIRECTION} from '../../redux/userDucks'
import Loading from '../Globals/Loading'
import {useParams} from 'react-router-dom'
import { routes } from "../../static_data/routes";

import eye from "../../assets/images/eye.png"
import eyeBlocked from "../../assets/images/eye-blocked.png"
   

export default function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const loading = useSelector(store => store.users.loading_user)
  const clients = useSelector(store => store.users.clients)
  const user_logged = useSelector(store => store.users.user_logged)
  const redirection = useSelector(store => store.users.userRedirection)
  const dispatch = useDispatch();
  const history = useHistory();
  const {client} = useParams()

  const [queryForge,setQueryForge] = useState()

  const [inputPassword, setInputPassword] = useState("password");

  const [searchClient,setSearchClient] = useState()

  const [waiting,setWaiting] = useState(true);

  function handleChange(e) {
    setData((data_) => {
      return { ...data_, [e.target.name]: e.target.value };
    });
  }

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(USER_LOGIN_ACTION(data))
  }

  const getModulesAndRedirection = (modules) =>  {
    if(modules.find(module => module.name.toLowerCase() === 'mediadata')){return(routes.mediaData.analytics);}
    if(modules.find(module => module.name.toLowerCase() === 'mediablog')){return(routes.mediaBlog.news);}
  }

  function mostrarContraseña(){
    let value;
    if( inputPassword === "password"){
        value = "text";
    }else{
        value = "password";
    }
    setInputPassword(value)

}

  useEffect(() =>{
    if(user_logged){
      if(user_logged.token){      
        history.push(location.pathname)
        if(user_logged.level.toString() === '1' ) {return history.push((routes.mediaInvestor.investor).replaceAll(":cliente",user_logged.client.name.toLowerCase().replaceAll(" ","-")))}
        if(user_logged.level.toString() === '2' ) {return history.push((routes.mediaDocs.generalDoc).replaceAll(":cliente",user_logged.client.name.toLowerCase().replaceAll(" ","-")))}
        if(user_logged.level.toString() === '3' ) {return history.push((routes.mediaBlog.news).replaceAll(":cliente",user_logged.client.name.toLowerCase().replaceAll(" ","-")))}
        if(user_logged.level.toString() === '4' ) {return history.push((routes.mediaTeam.news).replaceAll(":cliente",user_logged.client.name.toLowerCase().replaceAll(" ","-")))}
        if(user_logged.client.modules.length === 0){return history.push(routes.welcome.welcome)}
        if(redirection){
          if(redirection === "/app" || redirection === "/app/"){
            dispatch(USER_SET_REDIRECTION(getModulesAndRedirection(user_logged.client.modules)))
          }
          else{
            history.push(redirection)
          }
        }
        else{
          dispatch(USER_SET_REDIRECTION(getModulesAndRedirection(user_logged.client.modules)))
        }
      }
    }
    if(localStorage.getItem("token_id")){
      dispatch(USER_GET_LOGIN_ACTION());
      setTimeout(function(){ setWaiting(false) }, 8000);
    }
    else{
      setWaiting(false)
    }
  },[user_logged])

  useEffect(() => {
    // dispatch(CLIENTS_GET_ACTION());
    const date = new Date()
    setQueryForge(date)
  },[])

  useEffect(() => {
    if(clients){
      const searcher = clients.find(element => element.name.toLowerCase().replace(" ","-") === client)
      if(searcher){
        setSearchClient(searcher)
      }
    }
  },[clients])

  useEffect(() => {
    if(searchClient){
      root.style.setProperty('--login-color', searchClient.investors_primary_color);
      root.style.setProperty('--login-bg', searchClient.investors_background_color);
    }
  },[searchClient])

  //function get current year
  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  }

  return (!loading && searchClient) || client === undefined ?  ( // Validación para que espoere a que cargue los colores del cliente en caso que los haya
    <div className="login-container">
      {loading || waiting ? <Loading isAbsolute={true} /> : ''}
      <div className="login-section">
        <div className="login-section-logo" style={{opacity:0}}>
          <Link to="/app">
            {searchClient ? 
              searchClient.investors_background ?
              // <div className="login-image" style={{backgroundImage: `url(${process.env.REACT_APP_BASE_URL + searchClient.investors_background})`}}></div>
                <img src={process.env.REACT_APP_BASE_URL + searchClient.investors_logo + "?" + queryForge.valueOf()} className="investor_logo" alt="brand losgo" />
              :
                <img src={LogoIcon} alt="brand logo" />
              :
                <img src={LogoIcon} alt="brand logo" />
            }
          </Link>
        </div>

        <form onSubmit={handleLogin} className="login-form" action="">
          <div className="mb-lg-5 mb-3">
            <Link to="/app">
              {searchClient ? 
                searchClient.investors_background ?
                // <div className="login-image" style={{backgroundImage: `url(${process.env.REACT_APP_BASE_URL + searchClient.investors_background})`}}></div>
                  <img src={process.env.REACT_APP_BASE_URL + searchClient.investors_logo + "?" + queryForge.valueOf()} className="investor_logo" alt="brand logo" />
                :
                  <img src={LogoIcon} alt="brand logo" />
                :
                <img src={LogoIcon} alt="brand logo" />
              }
            </Link>
          </div>
          <h1 className=" font-1 mb-4">
            Acceso privado <Link className="text-decoration-none text-mediahaus-1" to="/app">{searchClient ? searchClient.name : "MediaCore®"}</Link>
          </h1>
          <div className="d-flex flex-column gap-2">
            <label>Email</label>
            <input
              name="email"
              value={data.email}
              onChange={handleChange}
              required
              className="login-input"
              type="text"
              placeholder="Usuario"
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <label>Contraseña</label>
            <div className="position-relative input_password_eye">
              <input id="newPass"
                name="password"
                value={data.password}
                onChange={handleChange}
                required
                placeholder="Contraseña" 
                type={inputPassword} 
                className="login-input w-100 mxw-500 mb-2"/>
              <img 
                onClick={mostrarContraseña}
                className="eye position-absolute "
                id="boton-eye" 
                src={ inputPassword === "password" ? eye : eyeBlocked }
              />
            </div>
            {/* <input
              name="password"
              value={data.password}
              onChange={handleChange}
              required
              className="login-input"
              type="password"
              placeholder="Contraseña"
            /> */}
          </div>
          <button className="button-mediahaus-1 mt-4 p-2" type="submit">
            Ingresar
          </button>
          <Link to={routes.session.recover}> Olvidé mi contraseña</Link>
        </form>

        <p className="font-1 small-font text-gray-2">
          Plataforma <span className="text-mediahaus-1">MediaCore<span style={{fontSize:".5rem", verticalAlign:"text-top"}}>® </span></span> by <a className="link-1" href="https://www.mediahaus.com.ar" target="_blank">MediaHaus<span style={{fontSize:".5rem", verticalAlign:"text-top"}}>® </span></a> {getCurrentYear()}. <br className="d-block d-lg-none" /> Todos los
          derechos reservados.
        </p>
      </div>


      {searchClient ? 
        searchClient.investors_background ?
        <div className="login-image" style={{backgroundImage: `url(${process.env.REACT_APP_BASE_URL + searchClient.investors_background + "?" + queryForge.valueOf()})`}}></div>
        :
        <div className="login-image"></div>
        :
        <div className="login-image"></div>
      }
    </div>
    
  ):'';
}
