import {ADVISER_DELETE_ACTION} from "../../../../redux/advisersDucks"
import { useDispatch, useSelector } from "react-redux";

const DeleteModalAdviser = (props) => {
    const dispatch = useDispatch()
    return (
    <>
        <div className="modal fade " id={"adviser-" + props.adviser.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Eliminación del asesor</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    ¿Desea eliminar el asesor <b>"{props.adviser.name}"</b>  de forma permanente? 
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" onClick={() => {dispatch(ADVISER_DELETE_ACTION(props.adviser.id))}} className="btn btn-danger" data-bs-dismiss="modal">Eliminar</button>
                </div>
                </div>
            </div>
        </div>
    </>)
}

export default DeleteModalAdviser